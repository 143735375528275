import {AuthContext} from "../AuthContext";
import React, {useState, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import jwt_decode from "jwt-decode";
import io from 'socket.io-client';

const useLocalStorage = (key, initialValue) => {
    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : initialValue;
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};

const SOCKET_URL = process.env.NODE_ENV === 'development' ? 'http://0.0.0.0:8002' : 'wss://log.linkingapimid.com';

const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    const [token, setToken] = useLocalStorage("logisticToken", null);
    const [user, setUser] = useLocalStorage("logisticUser", null);
    const [userRole, setUserRole] = useLocalStorage("logisticUserRole", null); // Persist userRole in localStorage
    const [socket, setSocket] = useState(null);
    const [socketInitialized, setSocketInitialized] = useState(false);
    const [userLocation, setUserLocation] = useLocalStorage("logisticUserLocation", null);  // New state for user location

    const authConfig = useMemo(() => ({headers: {Authorization: `Bearer ${token}`}}), [token]);

    const isTokenValid = (token) => {
        if (!token) return false;

        try {
            const decodedToken = jwt_decode(token);
            const currentTime = Date.now() / 1000; // Current time in seconds
            return decodedToken.exp > currentTime;
        } catch (error) {
            return false;
        }
    };

    useEffect(() => {
        if (token) {
            if (!isTokenValid(token)) {
                handleLogout();
                navigate("/login");
            }
        }
        // eslint-disable-next-line
    }, [token, navigate]);

    useEffect(() => {
        if (token && isTokenValid(token)) {
            const newSocket = io(SOCKET_URL, {
                query: {token},
                transports: ['websocket'],
                reconnectionAttempts: 5,
                reconnectionDelay: 1000,
            });

            newSocket.on('connect', () => {
                console.log('Connected to the server');
                setSocketInitialized(true);
            });

            newSocket.on('disconnect', () => {
                console.log('Disconnected from the server');
                setSocketInitialized(false);
            });

            newSocket.on('reconnect_attempt', (attempt) => {
                console.log(`Reconnect attempt ${attempt}`);
            });

            newSocket.on('reconnect_failed', () => {
                console.log('Failed to reconnect to the server');
            });

            newSocket.on('connect_error', (error) => {
                console.error('Connection error:', error);
            });

            newSocket.on('logout', (data) => {
                console.log(data.message); // Optional: log the message
                handleLogout();
            });

            setSocket(newSocket);

            return () => {
                newSocket.close();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const handleLogout = () => {
        if (socket) {
            socket.disconnect();  // Properly close the socket connection
            setSocket(null);
        }
        setToken(null);
        setUser(null);
        setUserRole(null);
        setUserLocation(null);  // Clear user location on logout

        localStorage.clear(); // Clear all localStorage data

        navigate("/login"); // Redirect to the login page
    };

    const doLogin = (authData) => {
        setToken(authData.token);
        setUser(authData.user);

        const decodedToken = authData.token ? jwt_decode(authData.token) : null;
        const userRole = decodedToken ? decodedToken.userRole : null;
        setUserRole(userRole);

        if (userRole === 1) { // SuperUser
            navigate("/dashboard");
        } else if (userRole === 2) { // Dispatcher
            navigate("/dashboard");
        } else if (userRole === 3) { // Driver
            navigate("/driver-schedule");
        } else if (userRole === 4) { // Receiver
            navigate("/receiver");
        } else if (userRole === 5) { // Driver Charter
            navigate("/airbill");
        }
    };

    return (
        <AuthContext.Provider
            value={{
                doLogin, handleLogout, token, user, setToken, userRole, authConfig, socket, socketInitialized,
                userLocation, setUserLocation  // Pass userLocation and setUserLocation
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export {AuthProvider};
