import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Space, message, Spin, Button, List, Divider, Input, Row, Col, Tag} from 'antd';
import {ArrowRightOutlined, ClearOutlined} from '@ant-design/icons';
import api from "../../../restAPI";
import InfiniteScroll from 'react-infinite-scroll-component';

const ScheduleList = ({token}) => {
    const [schedules, setSchedules] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [totalWithSchedule, setTotalWithSchedule] = useState(0);
    const [clientId, setClientId] = useState('');
    const [clientName, setClientName] = useState('');
    const [clientAddress, setClientAddress] = useState('');
    const [routeCode, setRouteCode] = useState('');
    const [hasMore, setHasMore] = useState(true);

    const fetchSchedules = async (reset = false) => {
        if (loading) return;

        setLoading(true);
        try {
            const response = await api.get('/client-schedule/distinct-client-list/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    clientId: clientId || undefined,
                    clientName: clientName || undefined,
                    clientAddress: clientAddress || undefined,
                    routeCode: routeCode || undefined,
                    page,
                    limit: 10,
                }
            });
            const newSchedules = response.data.clients;

            setSchedules(reset ? newSchedules : [...schedules, ...newSchedules]);
            setTotal(response.data.totalClients);
            setTotalWithSchedule(response.data.totalClientsWithSchedule);
            setHasMore(page * 10 < response.data.totalClients);
        } catch (error) {
            message.error('Failed to load schedules from the server');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSchedules(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId, clientName, clientAddress, routeCode]);

    const handleClientIdSearch = (e) => {
        setClientId(e.target.value);
        setPage(1);
    };

    const handleRouteCodeSearch = (e) => {
        setRouteCode(e.target.value);
        setPage(1);
    };

    const handleClientNameSearch = (e) => {
        setClientName(e.target.value);
        setPage(1);
    };

    const handleClientAddressSearch = (e) => {
        setClientAddress(e.target.value);
        setPage(1);
    };

    const loadMore = () => {
        if (loading || !hasMore) return;
        setPage(prevPage => {
            const newPage = prevPage + 1;
            fetchSchedules();
            return newPage;
        });
    };

    return (
        <div style={{padding: '20px', background: '#f0f2f5', display: 'flex', flexDirection: 'column'}}>
            <Row gutter={[16, 16]} style={{marginBottom: 20}}>
                <Col span={4}>
                    <Input
                        placeholder="Search by route code"
                        value={routeCode}
                        onChange={handleRouteCodeSearch}
                        onPressEnter={() => fetchSchedules(true)}
                    />
                </Col>
                <Col span={4}>
                    <Input
                        placeholder="Search by client ID"
                        value={clientId}
                        onChange={handleClientIdSearch}
                        onPressEnter={() => fetchSchedules(true)}
                    />
                </Col>
                <Col span={8}>
                    <Input
                        placeholder="Search by client name"
                        value={clientName}
                        onChange={handleClientNameSearch}
                        onPressEnter={() => fetchSchedules(true)}
                    />
                </Col>
                <Col span={8}>
                    <Input
                        placeholder="Search by client address"
                        value={clientAddress}
                        onChange={handleClientAddressSearch}
                        onPressEnter={() => fetchSchedules(true)}
                    />
                </Col>

            </Row>

            {/*<Divider/>*/}

            <Row align="middle">
                <Col>
                    <Tag bordered={false}>Total Count: <strong>{total}</strong></Tag>
                </Col>

                <Col style={{marginLeft: '20px'}}>
                    <Tag bordered={false}>Total Clients with Schedule: <strong>{totalWithSchedule}</strong></Tag>
                </Col>

                <Col style={{marginLeft: '20px'}}>
                    <Button
                        disabled={!clientId && !clientName && !clientAddress}
                        type="text"
                        danger={true}
                        icon={<ClearOutlined/>}
                        onClick={() => {
                            setClientId('');
                            setClientName('');
                            setClientAddress('');
                        }}
                    >
                        Reset Filters
                    </Button>
                </Col>

            </Row>

            <Divider/>

            <InfiniteScroll
                dataLength={schedules.length}
                next={loadMore}
                hasMore={hasMore}
                loader={<Spin size="large"/>}
                endMessage={<Divider plain>End of schedules</Divider>}
                style={{overflow: 'hidden'}}
            >
                <List
                    itemLayout="vertical"
                    dataSource={schedules}
                    renderItem={schedule => (
                        <List.Item
                            key={schedule.id}
                            actions={[
                                <Link to={`/client-schedule-detail/${schedule.id}`}>
                                    <Button type="primary" icon={<ArrowRightOutlined/>}>View Details</Button>
                                </Link>
                            ]}
                        >
                            <List.Item.Meta
                                title={
                                    <Space>
                                        <Link to={`/client-schedule-detail/${schedule.id}`}>
                                            {schedule.name}
                                        </Link>

                                        <Tag bordered={false} color={schedule.has_schedule ? 'green' : 'red'}>
                                            {schedule.has_schedule ? 'Has Schedule' : 'No Schedule'}
                                        </Tag>
                                    </Space>
                                }
                                description={
                                    <Row>
                                        <Col span={24}>
                                            {`${schedule.street}, ${schedule.city}, ${schedule.state}, ${schedule.zip}`}
                                        </Col>

                                        {schedule?.schedules.length > 0 && <Col span={24}>
                                            Routes:&nbsp;&nbsp;
                                            {[
                                                ...new Set(schedule?.schedules.map(schedule => schedule.route_code))
                                            ].map(route_code =>
                                                <Tag bordered={false} key={route_code}>
                                                    <strong>{route_code}</strong>
                                                </Tag>
                                            )}
                                        </Col>}
                                    </Row>
                                }
                            />
                        </List.Item>
                    )}
                    style={{textAlign: 'start', backgroundColor: '#ffffff', padding: '20px', borderRadius: '8px'}}
                />
            </InfiniteScroll>
        </div>
    );
};

export default ScheduleList;
