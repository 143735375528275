import { Modal, Form, Input, Button, Spin } from 'antd';
import React, { useState, useEffect } from "react";

const EditDriverModal = ({ isVisible, onCancel, driver, onUpdate }) => {
  const [localDriver, setLocalDriver] = useState(driver);
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setLocalDriver(driver);
    form.setFieldsValue(driver);
  }, [driver, form]);

  const handleUpdate = async () => {
    setIsSaving(true);  
    try {
      onUpdate(localDriver);
    } catch (error) {
    } finally {
      setIsSaving(false);
    }
  };
  
  const handleSubmit = () => {
    handleUpdate();
    onCancel(); 
  };

  return (
    <Modal 
      title="Edit Driver" 
      open={isVisible}
      onCancel={onCancel}
      onOk={handleUpdate}
      okText="Edit"
      confirmLoading={isSaving}
    >
    <Spin spinning={isSaving}>
      {localDriver && (
        <Form form={form} onFinish={handleSubmit}>
            <Form.Item label="First Name" name="first_name">
              <Input onChange={(e) => setLocalDriver({...localDriver, first_name: e.target.value})} />
            </Form.Item>
            <Form.Item label="Last Name" name="last_name">
              <Input onChange={(e) => setLocalDriver({...localDriver, last_name: e.target.value})} />
            </Form.Item>
            <Form.Item label="Phone" name="phone">
              <Input onChange={(e) => setLocalDriver({...localDriver, phone: e.target.value})} />
            </Form.Item>
            <Form.Item label="State" name="state">
              <Input onChange={(e) => setLocalDriver({...localDriver, state: e.target.value})} />
            </Form.Item>
            <Form.Item style={{ display: 'none' }}>
                <Button type="primary" htmlType="submit"></Button>
            </Form.Item>
        </Form>
      )}
    </Spin>
    </Modal>
  );
}

export default EditDriverModal;
