import React, {useState, useContext, useEffect, useRef} from 'react';
import {Row, Col, Spin, Typography, Button, Tag, Divider} from 'antd';
import {useReactToPrint} from 'react-to-print';
import {FilePdfOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import api from '../../../restAPI';
import {AuthContext} from '../../../components/AuthContext';
import Filters from './Filters';
import ScheduleTable from './ScheduleTable';
import {NavLink} from "react-router-dom";
import styled from 'styled-components';

const TableContainer = styled.div`
    font-family: Arial, sans-serif;
    font-size: 9px !important;
    color: black;

    .ant-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 9px;
        font-size: 9px !important;
        border-radius: 0 !important;
    }

    .ant-table th, .ant-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    .ant-table th {
        background-color: #f2f2f2;
    }
`;

const PageBreak = styled.div`
    page-break-after: always;
`;

const FullPageSpinner = styled(Spin)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
`;

const PredictSchedule = () => {
    const [data, setData] = useState([]);
    const {token} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [drivers, setDrivers] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [allRoutes, setAllRoutes] = useState([]);
    const componentRef = useRef();

    const fetchDrivers = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await api.get('/driver', config);
            setDrivers(response.data.driver);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchRoutes = async () => {
        try {
            const config = {
                headers: {Authorization: `Bearer ${token}`},
                params: {limit: 2000}
            };
            const response = await api.get('/routes/', config);
            setAllRoutes(response.data.routes);
            setRoutes(response.data.routes);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const params = new URLSearchParams();
            if (selectedDriver) {
                params.append('driverID', selectedDriver.id);
            }
            if (selectedRoute) {
                params.append('routeCode', selectedRoute);
            }
            params.append('date', selectedDate.format('YYYY-MM-DD'));
            const response = await api.get(`/orders/predicted-stops/?${params.toString()}`, config);
            setData(response.data.items);

            if (selectedDriver) {
                const driverRoutes = response.data.items.map(item => item.route_code);
                setRoutes(allRoutes.filter(route => driverRoutes.includes(route.code)));
            } else {
                setRoutes(allRoutes);
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const loadInitialData = async () => {
            setIsLoading(true);
            await Promise.all([fetchDrivers(), fetchRoutes()]);
            setIsLoading(false);
        };

        loadInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, selectedDriver, selectedRoute, selectedDate, allRoutes]);

    const handleDriverChange = (driverId) => {
        const driver = driverId ? drivers.find(d => d.id === driverId) : null;
        setSelectedDriver(driver);
        setSelectedRoute(null);

        if (driver) {
            const driverRoutes = data.filter(item => item.driver_id === driverId).map(item => item.route_code);
            setRoutes(allRoutes.filter(route => driverRoutes.includes(route.code)));
        } else {
            setRoutes(allRoutes);
        }
    };

    const handleRouteChange = (routeCode) => {
        setSelectedRoute(routeCode);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Driver Schedule',
        pageStyle: `
        @page {
            size: Letter landscape;  
            margin: 10mm;
        }
        @media print {
            body {
                -webkit-print-color-adjust: exact;
                margin: 0;
            }
            .print-container {
                font-size: 11px;
                color: black;
                margin: 10mm; 
            }
            .print-container .ant-table {
                border-collapse: collapse !important;
            }
            .print-container .ant-table th, .print-container .ant-table td {
                padding: 4px !important;
                border: 1px solid #ddd !important;
            }
            .print-container .ant-table th {
                background-color: #f2f2f2 !important;
            }
            .print-container .title {
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 20px;
            }
            .print-container .total-stops,
            .print-container .date-of-print {
                margin-top: 0;
                font-size: 12px;
            }
        }
        `,
    });

    const totalStops = data.length;
    const currentDate = dayjs().format('MM/DD/YYYY hh:mm A');

    const filteredData = selectedRoute ? data.filter(item => item.route_code === selectedRoute) : data;
    const routeCodes = [...new Set(filteredData.map(item => item.route_code))];

    const tables = routeCodes.map((routeCode, index) => {
        const dataForRoute = filteredData.filter(item => item.route_code === routeCode);

        return (
            <div key={index}>
                <Typography.Title level={5} align={'left'}>
                    {(selectedDriver?.first_name || 'No driver selected')} {(selectedDriver?.last_name || '')} -
                    {/*Schedule for Route: <Tag>{routeCode}</Tag> {`${dayjs(selectedDate).format('MM/DD/YYYY')}`}*/}
                    Schedule for Route: <Tag>{routeCode}</Tag> {`${dayjs(selectedDate).format('dddd, MM/DD/YYYY')}`}
                </Typography.Title>
                <Row>
                    <Col span={6}>
                        <Typography.Paragraph align={'left'} className="total-stops">
                            <strong>Driver phone:</strong> {selectedDriver?.phone || 'N/A'}
                        </Typography.Paragraph>
                    </Col>
                    <Col span={4}>
                        <Typography.Paragraph align={'left'} className="total-stops">
                            <strong>Total Stops:</strong> {dataForRoute.length}
                        </Typography.Paragraph>
                    </Col>
                    <Col span={6}>
                        <Typography.Paragraph align={'left'} className="date-of-print">
                            <strong>Date of Print:</strong> {currentDate}
                        </Typography.Paragraph>
                    </Col>
                    <Col span={6}>
                        <Typography.Paragraph align={'left'} className="date-of-print">
                            <strong>Received time:</strong> ___________________
                        </Typography.Paragraph>
                    </Col>
                </Row>
                <ScheduleTable data={dataForRoute} routeCode={routeCode}/>
                {index !== routeCodes.length - 1 && <PageBreak/>}
            </div>
        );
    });

    return (
        <div>
            {isLoading && <FullPageSpinner size="large" tip="Loading..."/>}
            <Row justify="center" align="middle">
                <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                    <Row justify={'center'}>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Typography.Title level={2} className="mainLabel">
                                Predict Schedule
                            </Typography.Title>
                            <Typography.Paragraph type="secondary">
                                Select a driver and a date to view their schedule.
                                <br/>FYI this page shows the schedule for the driver what is predicted by the system
                                based
                                on the <NavLink to={'/client-schedule'}>client's schedule.</NavLink>
                            </Typography.Paragraph>
                            <Filters
                                drivers={drivers}
                                routes={routes}
                                selectedDriver={selectedDriver}
                                selectedRoute={selectedRoute}
                                selectedDate={selectedDate}
                                handleDriverChange={handleDriverChange}
                                handleRouteChange={handleRouteChange}
                                setSelectedDate={setSelectedDate}
                            />
                            {data.length > 0 && (
                                <Button
                                    type="primary"
                                    onClick={handlePrint}
                                    icon={<FilePdfOutlined style={{margin: '0px 6px 0px -6px'}}/>}
                                >
                                    Print PDF
                                </Button>
                            )}
                        </Col>

                        {(selectedDriver || selectedRoute || data.length > 0) && (
                            <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{marginTop: '20px'}}>
                                <Spin spinning={isLoading} tip="Loading..." size="large">
                                    <TableContainer ref={componentRef} className="print-container">
                                        {tables}
                                        {tables.length > 1 && (
                                            <>
                                                <Divider/>
                                                <Typography.Title level={5} align={'left'} className="total-stops">
                                                    <Tag>Total stops
                                                        for {dayjs(selectedDate).format('MM/DD/YYYY')}:</Tag> {totalStops}
                                                </Typography.Title>
                                                <Typography.Title level={5} align={'left'} className="date-of-print">
                                                    <Tag>Driver:</Tag> {(selectedDriver?.first_name || 'No driver selected')} {(selectedDriver?.last_name || '')}
                                                </Typography.Title>
                                            </>
                                        )}
                                    </TableContainer>
                                </Spin>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default PredictSchedule;
