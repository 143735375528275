// import React, {useState, useContext, useCallback} from "react";
// import {Row, Col, Typography, Spin, Input, Card, Tag, message, Select} from 'antd';
// import api from "../../restAPI";
// import {AuthContext} from "../../components/AuthContext";
// import OrderDetailsModal from "./OrderDetailsModal";
//
// const {Title} = Typography;
// const {Search} = Input;
// const {Option} = Select;
//
// const Receiver = () => {
//     const [data, setData] = useState([]);
//     const {token} = useContext(AuthContext);
//     const [isLoading, setIsLoading] = useState(false);
//     const [isModalVisible, setIsModalVisible] = useState(false);
//     const [currentItem, setCurrentItem] = useState(null);
//     const [selectedQuantity, setSelectedQuantity] = useState(null);
//     const [searchType, setSearchType] = useState('client');
//     const [selectedNotes, setSelectedNotes] = useState('');
//
//     const fetchData = useCallback(async (id) => {
//         try {
//             setIsLoading(true);
//             const config = {
//                 headers: {
//                     Authorization: `Bearer ${token}`
//                 }
//             };
//             let response;
//             if (searchType === 'driver') {
//                 response = await api.get(`/orders/todays-driver-orders/${id}/`, config);
//             } else {
//                 response = await api.get(`/orders/todays-client-orders/${id}/`, config);
//             }
//             setData(response.data.items);
//         } catch (error) {
//             console.log(error);
//         } finally {
//             setIsLoading(false);
//         }
//     }, [token, searchType]);
//
//     const sendOrderConfirmation = async (orderId, quantity, notes) => {
//         try {
//             const config = {
//                 headers: {
//                     Authorization: `Bearer ${token}`
//                 }
//             };
//             const payload = {
//                 orderId: orderId,
//                 quantity: quantity,
//                 notes: notes
//             };
//             const response = await api.post('/orders/receiveOrder/', payload, config);
//             if (response.data.status === 'success') {
//                 message.success('Order updated successfully!');
//             } else {
//                 message.error('Failed to update order');
//             }
//         } catch (error) {
//             console.log(error);
//             message.error('Failed to update order');
//         }
//     };
//
//     const onSearch = (value) => {
//         if (value.trim() === '') {
//             message.error('Please enter an ID!');
//             return;
//         }
//
//         if (!/^\d+$/.test(value.trim())) {
//             message.error('Please enter a valid number!');
//             return;
//         }
//
//         fetchData(value);
//     };
//
//     const showModal = (item) => {
//         setCurrentItem(item);
//         setSelectedQuantity(item.quantity);
//         setSelectedNotes('');
//         setIsModalVisible(true);
//     };
//
//     const handleKeyPress = (event) => {
//         const keyCode = event.keyCode || event.which;
//         const keyValue = String.fromCharCode(keyCode);
//         if (!/\d/.test(keyValue)) event.preventDefault();
//     }
//
//     const handleConfirm = async () => {
//         await sendOrderConfirmation(currentItem.id, selectedQuantity, selectedNotes);
//
//         setIsModalVisible(false);
//         const updatedData = data.map((item) => {
//             if (item.id === currentItem.id) {
//                 return {...item, quantity: selectedQuantity, notes: selectedNotes};
//             }
//             return item;
//         });
//         setData(updatedData);
//     };
//
//     const handleCancel = () => {
//         setIsModalVisible(false);
//     };
//
//     return (
//         <div>
//             <Spin spinning={isLoading} tip="Loading..." size='large'>
//                 <Row justify="center">
//                     <Col xl={22} lg={22} md={22} sm={22} xs={22}>
//
//                         <Row justify="center">
//                             <Col xl={24} lg={24} md={24} sm={24} xs={24}>
//                                 <Title level={2} className="mainLabel">
//                                     {`Search by ${searchType === 'client' ? 'Client' : 'Driver'} ID`}
//                                 </Title>
//                             </Col>
//                         </Row>
//
//                         <Row justify="center">
//                             <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
//                                 <Select defaultValue="client" style={{width: 100}} onChange={setSearchType}>
//                                     <Option value="client">Client ID</Option>
//                                     <Option value="driver">Driver ID</Option>
//                                 </Select>
//                                 <Search
//                                     placeholder="Enter ID here"
//                                     allowClear
//                                     onSearch={onSearch}
//                                     onKeyPress={handleKeyPress}
//                                     style={{width: '180px', margin: '0 0 20px 20px'}}
//                                 />
//                             </Col>
//                         </Row>
//
//                         <Row gutter={[16, 16]} justify="center" align="middle"
//                              style={{maxHeight: '70vh', overflow: 'auto'}}>
//                             <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
//                                 {data.map((item, index) => (
//                                     <Card key={index} style={{width: '400px', margin: '10px auto'}}
//                                           onClick={() => showModal(item)} bordered>
//                                         <Row justify="start" style={{marginBottom: '10px'}}>
//                                             <Tag color="#f50">Client
//                                                 ID:{item.clientDetails.id}</Tag> {item.clientDetails.name}
//                                         </Row>
//                                         <Row>
//                                             <Tag color="#108ee9">Driver
//                                                 ID:{item.driverDetails.id}</Tag> {item.driverDetails.first_name} {item.driverDetails.last_name}
//                                         </Row>
//                                     </Card>
//                                 ))}
//                             </Col>
//                         </Row>
//                     </Col>
//                 </Row>
//
//                 <OrderDetailsModal
//                     isModalVisible={isModalVisible}
//                     handleConfirm={handleConfirm}
//                     handleCancel={handleCancel}
//                     currentItem={currentItem}
//                     setSelectedQuantity={setSelectedQuantity}
//                     selectedQuantity={selectedQuantity}
//                     setSelectedNotes={setSelectedNotes}
//                     selectedNotes={selectedNotes}
//                 />
//             </Spin>
//         </div>
//     );
// }
//
// export default Receiver;
import React from 'react';
import ReceiverDashboard from "./ReceiverDashboard";

const Receiver = () => {
    return (
        <ReceiverDashboard/>
    );
}

export default Receiver;