import React from 'react';
import {PlusOutlined, EditOutlined, EyeOutlined, RetweetOutlined} from '@ant-design/icons';
import {Button, Form, Space, Tooltip} from 'antd';
import styled from 'styled-components';

const StyledButtonGroup = styled(Space)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 10px 0 10px;
    padding: 14px 20px 0 20px;
    background: #f0f2f5;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const StyledButton = styled(Button)``;

const RoundButton = styled(Button)`
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.19);
        transform: scale(1.1);
    }

    &:active {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 3px 10px rgba(0, 0, 0, 0.19);
        transform: scale(0.95);
    }
`;


const ButtonGroup = ({editMode, editModeHandler, handleSettingClick, refreshOrdersDependedOnClientScheduleByClientId}) => (
    <StyledButtonGroup size={'small'}>
        <Form.Item>
            <Tooltip title="Create a new schedule" placement="bottom">
                <StyledButton
                    icon={<PlusOutlined/>}
                    size={'small'}
                    type="primary"
                    onClick={handleSettingClick}
                >
                    Create a new schedule
                </StyledButton>
            </Tooltip>
        </Form.Item>

        <Form.Item>
            <Tooltip title="Create a new schedule" placement="bottom">
                <StyledButton
                    onClick={refreshOrdersDependedOnClientScheduleByClientId}
                    icon={<RetweetOutlined/>}
                >
                    Refresh schedule
                </StyledButton>
            </Tooltip>
        </Form.Item>

        <Form.Item name="editMode" valuePropName="checked">
            <RoundButton
                type="primary"
                icon={editMode ? <EyeOutlined/> : <EditOutlined/>}
                onClick={() => editModeHandler(!editMode)}
            />
        </Form.Item>
    </StyledButtonGroup>
);

export default ButtonGroup;
