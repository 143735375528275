import React, { useState } from 'react';
import { Card, Button, Image, notification } from 'antd';
import api from '../../../restAPI';

const PickedUpWithIssues = ({ selectedRowData, token }) => {
    const [loading, setLoading] = useState(false); 
    const [isSuccessful, setIsSuccessful] = useState(false);

    const updateOrderStatus = async (orderId, endpoint, status, successMessage) => {
      setLoading(true); 
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
        await api.put(endpoint, { order_id: orderId, status }, config);
        notification.success({
          message: 'Success',
          description: successMessage,
        });
        setIsSuccessful(true); 
      } catch (error) {
        console.error(error);
        notification.error({
          message: 'Error',
          description: `Failed to change order status to ${status}!`,
        });
      } finally {
        setLoading(false); 
      }
    };
  
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Image
          width={100}
          src={`https://winaero.com/blog/wp-content/uploads/2019/11/Photos-new-icon.png`}
          preview={{
            src: `https://logistic-bucket.nyc3.cdn.digitaloceanspaces.com/uploads/${selectedRowData.id}.webp`,
          }}
        />
        <Card
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderColor: 'red',
          }}
        >
            <Button 
              type="primary" 
              style={{ marginRight: '10px', backgroundColor: '#228B22' }}
              onClick={() => updateOrderStatus(selectedRowData.id, `/orders/update-status-to-created/${selectedRowData.id}/`, 'CREATED', 'Order status changed to CREATED successfully!')}
              loading={loading} 
              disabled={loading || isSuccessful} 
            >
              Created
            </Button>
            <Button 
              type="primary" 
              style={{ marginRight: '10px', backgroundColor: 'navy' }}
              onClick={() => updateOrderStatus(selectedRowData.id, `/orders/update-status-to-confirmed/${selectedRowData.id}/`, 'CONFIRMED', 'Order status changed to CONFIRMED successfully!')}
              loading={loading} 
              disabled={loading || isSuccessful} 
            >
              Confirm
            </Button>
            <Button type="primary" danger disabled={loading || isSuccessful}>Big Issue</Button>
        </Card>
      </div>
    );
  };
  
  export default PickedUpWithIssues;
