import React from 'react';
import {Modal, Typography, Button, Descriptions} from 'antd';
import UploadComponent from "../../Dispatcher/All Stops/UploadComponent";


const {Title, Paragraph} = Typography;

const DetailsModal = ({isModalVisible, selectedRowData, handleModalClose, token}) => {
    return (
        <Modal
            title={<Title level={3}>Order Details</Title>}
            open={isModalVisible}
            onCancel={handleModalClose}
            closable={false}
            maskClosable={false}
            width={800}
            footer={[
                <Button key="close" onClick={handleModalClose}>
                    Close
                </Button>
            ]}
        >
            {selectedRowData ? (
                <Descriptions bordered column={1} size="small">
                    <Descriptions.Item label="ID">{selectedRowData.id}</Descriptions.Item>
                    <Descriptions.Item label="Confirmation ID">{selectedRowData.confirmation_id}</Descriptions.Item>
                    <Descriptions.Item label="Type">{selectedRowData.type}</Descriptions.Item>
                    <Descriptions.Item label="Status">{selectedRowData.status}</Descriptions.Item>

                    <Descriptions.Item label="Route Details">
                        <Descriptions bordered column={1} size="small">
                            <Descriptions.Item
                                label="Route Code">{selectedRowData.routeDetails.code}</Descriptions.Item>
                        </Descriptions>
                    </Descriptions.Item>

                    <Descriptions.Item label="Driver Details">
                        <Descriptions bordered column={1} size="small">
                            <Descriptions.Item label="Driver ID">{selectedRowData.driverDetails.id}</Descriptions.Item>
                            <Descriptions.Item
                                label="Driver Name">{selectedRowData.driverDetails.first_name} {selectedRowData.driverDetails.last_name}</Descriptions.Item>
                            <Descriptions.Item
                                label="Driver Phone">{selectedRowData.driverDetails.phone}</Descriptions.Item>

                        </Descriptions>
                    </Descriptions.Item>

                    <Descriptions.Item label="Client Details">
                        <Descriptions bordered column={1} size="small">
                            <Descriptions.Item label="Client ID">{selectedRowData.clientDetails.id}</Descriptions.Item>
                            <Descriptions.Item
                                label="Client Name">{selectedRowData.clientDetails.name}</Descriptions.Item>
                            <Descriptions.Item
                                label="Client Email">{selectedRowData.clientDetails.email || 'N/A'}</Descriptions.Item>
                            <Descriptions.Item
                                label="Client Address">{selectedRowData.clientDetails.street}, {selectedRowData.clientDetails.zip}, {selectedRowData.clientDetails.state}</Descriptions.Item>
                            <Descriptions.Item
                                label="Client Phone">{selectedRowData.clientDetails.phone}</Descriptions.Item>
                            <Descriptions.Item
                                label="Sales Group">{selectedRowData.clientDetails.sales_group}</Descriptions.Item>
                        </Descriptions>
                    </Descriptions.Item>

                    <Descriptions.Item label="Photos">
                        <UploadComponent
                            token={token}
                            currentOrderId={selectedRowData.id}
                            displayOnly={true}
                        />
                    </Descriptions.Item>
                </Descriptions>
            ) : (
                <Paragraph>Loading data...</Paragraph>
            )}
        </Modal>
    );
}

export default DetailsModal;
