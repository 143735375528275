import { Table } from "antd";
const LogisticUpdates = () => {
    let dataSource = [
        {
            key: '0',
            date: '2024-02-16',
            page: '/general',
            event: 'Authorization bags fixed (now redirects to the login page if session expired',
        },
        {
            key: '1',
            date: '2024-02-16',
            page: '/all-stops',
            event: 'Pagination fixed',
        },
        {
            key: '2',
            date: '2024-02-16',
            page: '/all-stops',
            event: 'Filters fixed',
        },
        {
            key: '3',
            date: '2024-02-16',
            page: '/users',
            event: 'Users table reworked',
        },
        {
            key: '4',
            date: '2024-02-16',
            page: '/management/users',
            event: 'Users page created. The old user page was suited for drivers only',
        },
        {
            key: '5',
            date: '2024-02-16',
            page: '/management/users',
            event: 'Setting up new users passwords added',
        },
    ];

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '150px'
        },
        {
            title: 'Page',
            dataIndex: 'page',
            key: 'page',
            width: '300px'
        },
        {
            title: 'Event',
            dataIndex: 'event',
            key: 'event',
        },
    ];

    dataSource = dataSource.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });

    return (
        <Table dataSource={dataSource} columns={columns} size="small" pagination={{ defaultPageSize: 1000, showSizeChanger: true, pageSizeOptions: ['10', '50','100', '1000']}}        />
    )
}

export default LogisticUpdates