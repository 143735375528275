import React, {useState, useContext, useEffect} from 'react';
import {Modal, Row, Col, Typography, Divider, Tag, Form, Select, Button, Image, Spin, Card} from 'antd';

import {
    ArrowDownOutlined, CarryOutOutlined, CloseCircleOutlined,
    FileImageOutlined
} from "@ant-design/icons";
import PickedUpWithIssues from './PickedUpWithIssues';
import {orderStatuses} from '../../../services/orderStatuses';
import {AuthContext} from '../../../components/AuthContext';
import restAPI from "../../../restAPI";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const {Text} = Typography;
const {Option} = Select;

const OrderDetails = ({selectedRowData}) => (
    <Card bordered={false} style={{width: '100%'}}>
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <Text strong>Client:</Text> {selectedRowData?.client_id}
            </Col>
            <Col span={12}>
                <Text strong>Order type:</Text> {selectedRowData?.type}
            </Col>
            <Col span={24}>
                <Text strong>Client address:</Text>&nbsp;
                {`${selectedRowData.clientDetails.street}, ${selectedRowData.clientDetails.zip}`}
            </Col>
            <Col span={24}>
                <Text strong>Confirmation ID:</Text> {selectedRowData.confirmation_id}
            </Col>

            {selectedRowData?.type === 'ONCALLROUTINE' && (
                <>
                    <Col span={24}>
                        <Divider orientation="center" style={{fontSize: 14}}>Routine Order Details</Divider>
                    </Col>
                    <Col span={12}>
                        <Text strong>Date:</Text> {dayjs(selectedRowData.order_details.date).format('MM/DD/YYYY')}
                    </Col>
                    <Col span={12}>
                        <Text
                            strong>Time:</Text> {dayjs(selectedRowData.order_details.time, 'HH:mm:ss').format('hh:mm A')}
                    </Col>
                </>
            )}

            {selectedRowData?.type === 'ONCALLSTAT' && (
                <>
                    <Col span={24}>
                        <Divider orientation="center" style={{fontSize: 14}}>Stat Order Details</Divider>
                    </Col>
                    <Col span={12}>
                        <Text strong>Date:</Text> {dayjs(selectedRowData.order_details.date).format('MMMM DD, YYYY')}
                    </Col>
                    <Col span={12}>
                        <Text
                            strong>Time:</Text> {dayjs(selectedRowData.order_details.time, 'HH:mm:ss').format('hh:mm A')}
                    </Col>
                    <Col span={12}>
                        <Text strong>Results By:</Text> {selectedRowData.order_details.resultsBy}
                    </Col>
                    <Col span={24}>
                        <Divider orientation="left">Stat Tests</Divider>
                        {selectedRowData.order_details.statTest.map((test, index) => (
                            <Tag key={index} color="blue">{test}</Tag>
                        ))}
                    </Col>
                </>
            )}
        </Row>
    </Card>
);

const DetailsModal = ({isModalVisible, selectedRowData, handleModalClose, isSubmitting, status, assignRoute}) => {
    const {token} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);

    const [searchInput, setSearchInput] = useState('');

    const handleSearch = (value) => {
        setSearchInput(value);
    };

    const handleClear = () => {
        setSearchInput('');
    };

    const filteredDrivers = drivers.filter(driver =>
        driver.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
        driver.last_name.toLowerCase().includes(searchInput.toLowerCase())
    );


    const getDrivers = async () => {
        try {
            setLoading(true);
            const response = await restAPI.get('/driver/drivers-list/', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setDrivers(response.data.drivers);
        } catch (err) {
            console.log("Error while fetching drivers", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDrivers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowData]);

    const handleDriverChange = (value) => {
        setSelectedDriver(value);
    };

    return (
        <Modal
            closable={false}
            maskClosable={false}
            title={<Typography.Title level={4} align={'center'}>Order Details</Typography.Title>}
            open={isModalVisible}
            onCancel={handleModalClose}
            footer={null}
        >
            <Spin spinning={loading}>
                {selectedRowData && (
                    <Row gutter={[16, 16]}>
                        <Col span={24}>

                            <OrderDetails selectedRowData={selectedRowData}/>


                            {(selectedRowData.status === orderStatuses.PICKED_UP || selectedRowData.status === orderStatuses.PICKED_UP_WITH_ISSUES || selectedRowData.status === orderStatuses.HANDED_OVER || selectedRowData.status === orderStatuses.DROPPED_DOWN || selectedRowData.status === orderStatuses.CONFIRMED) && (
                                <div style={{marginTop: '20px'}}>
                                    <Divider plain
                                             style={{color: 'rgba(0, 0, 0, 0.4)'}}><FileImageOutlined/> Photo</Divider>
                                    {selectedRowData.status === orderStatuses.PICKED_UP_WITH_ISSUES ? (
                                        <PickedUpWithIssues selectedRowData={selectedRowData} token={token}/>
                                    ) : (
                                        <Image
                                            width={100}
                                            src={`https://winaero.com/blog/wp-content/uploads/2019/11/Photos-new-icon.png`}
                                            preview={{
                                                src: `https://logistic-bucket.nyc3.cdn.digitaloceanspaces.com/uploads/${selectedRowData.id}.webp`,
                                            }}
                                        />
                                    )}
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <Text strong>Driver Comments:</Text> {selectedRowData.comments}
                                    </Col>
                                </div>
                            )}

                            {selectedRowData.status === orderStatuses.NOT_ASSIGNED && (
                                <>
                                    <Divider plain style={{color: 'rgba(0, 0, 0, 0.4)'}}>
                                        <ArrowDownOutlined/> Assign driver
                                        <Typography.Paragraph type="secondary" style={{fontSize: 11}}>
                                            You can type the driver's name in the search bar
                                        </Typography.Paragraph>
                                    </Divider>

                                    <Row justify="center" align="middle">
                                        <Form.Item
                                            style={{width: '100%'}}
                                            name="driver"
                                            rules={[{required: true, message: 'Please select a driver!'}]}
                                        >
                                            <Select
                                                key={selectedRowData.id}
                                                showSearch
                                                placeholder="Select a driver"
                                                value={selectedDriver}
                                                onChange={handleDriverChange}
                                                onSearch={handleSearch}
                                                filterOption={false}
                                                allowClear={true}
                                                onClear={handleClear}
                                            >
                                                {Array.isArray(filteredDrivers) && filteredDrivers.length > 0 ? (
                                                    filteredDrivers
                                                        .sort((a, b) => a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase()))
                                                        .map((driver) => (
                                                            <Option key={driver.id} value={driver.id}>
                                                                {driver.first_name || 'N/A'} {driver.last_name || 'N/A'}
                                                            </Option>
                                                        ))
                                                ) : (
                                                    <Option disabled>
                                                        No drivers available
                                                    </Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Row>


                                    <Row justify="space-between">
                                        <Col>
                                            <Button
                                                type={'text'}
                                                icon={<CloseCircleOutlined/>}
                                                onClick={handleModalClose}
                                            >
                                                Close window
                                            </Button>
                                        </Col>

                                        <Col>
                                            <Button
                                                icon={<CarryOutOutlined/>}
                                                type="primary"
                                                onClick={() => assignRoute(selectedRowData.id, selectedDriver)} // selectedRowData.id - order ID
                                                disabled={!selectedDriver || isSubmitting}
                                            >
                                                Assign Driver
                                            </Button>
                                        </Col>
                                    </Row>

                                </>
                            )}
                        </Col>
                    </Row>
                )}
            </Spin>
        </Modal>
    );
};

export default DetailsModal;
