import React, { useState, useContext } from "react";
import { Row, Col, Spin, Input, Form, Button, message } from 'antd';
import jwt_decode from "jwt-decode";
import api from "../../../restAPI";
import { AuthContext } from "../../../components/AuthContext";

const MyAccount = () => {
  const { token } = useContext(AuthContext);
  const userID = token ? jwt_decode(token).userId : null;
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      await api.put(`/users/update-password/${userID}`, { newPassword: values.password }, config);
      message.success('Password updated successfully');
    } catch (error) {
      console.log('Failed:', error);
      message.error('Failed to update password');
    }  finally {
      setIsLoading(false); 
  }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Spin spinning={isLoading} tip="Loading..." size='large'>
      <Row justify="center">
        <Col xl={22} lg={22} md={22} sm={22} xs={22}>
          <Row justify="center" gutter={[16, 16]} style={{marginTop:'40px'}}>
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Change password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </Col>
      </Row>
    </Spin>
  )
}

export default MyAccount;
