import React, { useState, useContext, useEffect, useRef } from "react";
import api from "../../../restAPI";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../../components/AuthContext";
import { Row, Col, Spin, Input, Button, Table, notification, Popconfirm } from 'antd';
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';

const FedexReceive = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useContext(AuthContext);
  const userID = token ? jwt_decode(token).userId : null;
  const [tableData, setTableData] = useState([{ key: '1', client_id: '', tracking_number: '', quantity: '', notes: '' }]);
  const [totalRows, setTotalRows] = useState(1);
  const firstInputRef = useRef(null);
  
  const isRowEmpty = (row) => {
    return !row.client_id && !row.tracking_number && !row.quantity && !row.notes;
  };

  const updateTotalRows = () => {
    setTotalRows(tableData.length);
  };

  const columns = [
    { 
      title: `№ (${totalRows})`, 
      align: "center", 
      width: "90px", 
      dataIndex: "key", 
      key: "key",
      render: (text, record, index) => index + 1
    },
    {
      title: "Account",
      align: "center",
      width: "300px",
      dataIndex: "client_id",
      key: "client_id",
      render: (text, record, index) => (
        <Input
          type="number"
          style={{textAlign:'center'}}
          placeholder="XXXXXX"
          value={text}
          ref={index === 0 ? firstInputRef : null}
          onChange={(e) => handleInputChange(e, 'client_id', record.key)}
        />
      )
    },
    { 
      title: "Tracking number", 
      align: "center", 
      width: "300px", 
      dataIndex: "tracking_number", 
      key: "tracking_number", 
      render: (text, record) => (
        <Input 
          value={text} 
          style={{textAlign:'center'}}
          placeholder="XXX-XXX-XXX"
          onChange={(e) => handleInputChange(e, 'tracking_number', record.key)} 
        /> 
      )
    },
    {
      title: "Quantity",
      align: "center",
      width: "250px",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <Input
          type="number" 
          value={text}
          style={{textAlign:'center'}}
          placeholder="XXX"
          onChange={(e) => handleInputChange(e, 'quantity', record.key)}
        />
      )
    },
    { 
      title: "Comments", 
      align: "center", 
      dataIndex: "notes", 
      key: "notes", 
      render: (text, record) => (
        <Input 
          value={text} 
          style={{textAlign:'center'}}
          placeholder="Enter your notes here"
          onChange={(e) => handleInputChange(e, 'notes', record.key)} 
        />
      ) 
    },
    {
      title: "Del", 
      align: "center", 
      width: "100px", 
      key: "delete",
      render: (text, record) => (
        isRowEmpty(record) ? (
          <CloseCircleOutlined
            onClick={() => deleteRow(record.key)}
            style={{ fontSize: "28px", color:'red' }}
          />
        ) : (
          <Popconfirm
            title="Are you sure you want to delete this row?"
            onConfirm={() => deleteRow(record.key)}
            okText="Yes"
            cancelText="No"
            icon={<CloseCircleOutlined style={{ color: 'red' }} />}
          >
            <CloseCircleOutlined style={{ fontSize: "28px", color:'red' }} />
          </Popconfirm>
        )
      )
    },
  ];
  
  const handleInputChange = (e, field, key) => {
    let value = e.target.value;
    
    if ((field === 'client_id' || field === 'quantity') && value !== '') {
        value = parseInt(value, 10);
        if (isNaN(value)) {
            value = '';
        }
    }

    const newData = tableData.map(row => (row.key === key ? { ...row, [field]: value } : row));
    setTableData(newData);
    updateTotalRows();
};

  const renderFooter = () => (
    <Button
      style={{ width: '100%', color:'#204E57' }}
      type="dashed"
      onClick={addRow}
      disabled={isLoading}
      icon={<PlusOutlined />}
    >
      Add row
    </Button>
  );

  const addRow = () => {
    setTableData([...tableData, { key: `${tableData.length + 1}`, client_id: '', tracking_number: '', quantity: '', notes: '' }]);
    updateTotalRows();
  };

  const deleteRow = (key) => {
    const newData = tableData.filter(row => row.key !== key);
    const renumberedData = newData.map((row, index) => ({ ...row, key: `${index + 1}` }));
    setTableData(renumberedData);
    updateTotalRows();
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const nonEmptyRows = tableData.filter(row => !isRowEmpty(row));
      const rowsToSend = nonEmptyRows.map(({ key, ...rest }) => rest);
  
      if (rowsToSend.length > 0) {
        const response = await api.post(`/fedex-orders/create-fedex-orders/${userID}`, { data: rowsToSend }, config);
        if (response.status === 200 && response.data.status === 'success') {
          notification.success({ message: 'Data sent successfully!' });
          setTableData([{ key: '1', client_id: '', tracking_number: '', quantity: '', notes: '' }]);
          updateTotalRows();
        } else {
          notification.error({ message: response.data.message });
        }
      } else {
        notification.error({ message: 'No data to send!' });
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        notification.error({ message: error.response.data.message });
      } else {
        notification.error({ message: 'Failed to send data!' });
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setTotalRows(tableData.length);
  }, [tableData]);

  return (
    <div>
      <Spin spinning={isLoading} tip="Loading..." size='large'>
        <Row justify="center">
          <Col xl={23} lg={23} md={23} sm={23} xs={23}>
            <Row justify="center">
              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <Table dataSource={tableData} columns={columns} size="small" pagination={false} scroll={{ y: 500 }} footer={renderFooter} />
              </Col>
            </Row>

            <Row gutter={[16, 16]} justify="center" align="middle" style={{ maxHeight: '70vh', overflow: 'auto' }}>
              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <Button style={{ width: '150px', marginTop:'20px' }} type="primary" onClick={handleSubmit} disabled={isLoading}>Submit</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default FedexReceive;
