import { React } from "react";
import { Spin } from "antd";

const Loading = ({ children, loading }) => {
  return (
    <Spin spinning={loading} tip="loading">
      {children}
    </Spin>
  );
};

export default Loading