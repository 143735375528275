import React, {useEffect, useState, useCallback, useContext} from "react";
import {ArrowLeftOutlined} from '@ant-design/icons';
import {Spin, message, Button, Row, Col, Typography} from 'antd';
import {useNavigate, useLocation} from 'react-router-dom';
import api from "../../../restAPI";
import jwt_decode from "jwt-decode";
import {AuthContext} from "../../../components/AuthContext";
import {orderStatusesForDriver} from "../../../services/orderStatuses";
import getStatusColor from "../../../services/statusColor";
import {OpenMapModal} from "./OpenMapModal";
import MyModalComponent from "./MyModalComponent";
import SpinnerOverlay from './SpinnerOverlay';
import OrderList from './OrderList';
import ActionButtons from './ActionButtons';
import useLocationFetcher from './useLocationFetcher';
import RouteCard from './RouteCard';

const DriverOnMyWayTab = () => {
    const {token} = useContext(AuthContext);
    const driverID = token ? jwt_decode(token).driverId : null;
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMapCenter, setModalMapCenter] = useState({lat: 0, lng: 0});
    const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
    const [currentOrderData, setCurrentOrderData] = useState(null);
    // eslint-disable-next-line
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [createdOrders, setCreatedOrders] = useState([]);
    const [boxIsEmpty, setBoxIsEmpty] = useState(false);
    const [icePackInsideBox, setIcePackInsideBox] = useState(false);
    const [customNotes, setCustomNotes] = useState('');
    const [error, setError] = useState(null);
    // eslint-disable-next-line
    const [response, setResponse] = useState(null);
    const [selectedRouteCode, setSelectedRouteCode] = useState(null);
    const {userLocation} = useLocationFetcher();

    const navigate = useNavigate();
    const location = useLocation();

    const getCreatedOrders = async () => {
        setIsLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await api.get(`/orders/todays-driver-orders/${driverID}/created/`, config);
            const activeOrders = response.data.items.filter(order => !['PICKED_UP_WITH_ISSUES', 'OTHER_SUBMITTED_STATUS'].includes(order.status));
            setCreatedOrders(activeOrders);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCreatedOrders();

        // Check for routeCode in URL
        const params = new URLSearchParams(location.search);
        const routeCodeFromUrl = params.get('routeCode');
        if (routeCodeFromUrl) {
            setSelectedRouteCode(routeCodeFromUrl);
        }
        // eslint-disable-next-line
    }, [location.search]);

    const handleRouteCardClick = (routeCode) => {
        setSelectedRouteCode(routeCode);
        navigate(`?routeCode=${routeCode}`);  // Update the URL with the selected route code
    };

    const handleBackToCards = () => {
        setSelectedRouteCode(null);
        navigate('');  // Clear the routeCode from the URL
    };

    const handleOpenModal = (latitude, longitude) => {
        if (latitude && longitude) {
            setModalMapCenter({lat: latitude, lng: longitude});
            setModalIsOpen(true);
        } else {
            console.error("Invalid coordinates: ", {latitude, longitude});
        }
    };

    const closeModal = () => setModalIsOpen(false);

    const openSuccessModal = orderItemData => {
        setCurrentOrderData(orderItemData);
        setSuccessModalIsOpen(true);
        setCustomNotes('');
    };

    const closeSuccessModal = useCallback(() => {
        if (!isSubmitting) {
            setSuccessModalIsOpen(false);
            setUploadStatus(null);
            setCustomNotes('');
        }
    }, [isSubmitting]);

    const resetAndClose = useCallback(() => {
        setUploadStatus(null);
        closeSuccessModal();
    }, [closeSuccessModal]);

    const handleSubmit = async () => {
        if (!currentOrderData?.id) {
            message.error('No order ID found. Refresh the page and try again.');
            return;
        }

        const headers = {Authorization: `Bearer ${token}`};
        const body = {
            boxIsEmpty,
            icePackInsideBox,
            orderId: currentOrderData?.id,
            customNotes,
        };

        try {
            const res = await api.post('/api/v2/driver-actions/pickup-report/', body, {headers});
            setResponse(res.data);
            setError(null);
            setCreatedOrders(createdOrders.filter(order => order.id !== res.data.order.id));
            resetAndClose();

            message.success('Order status updated successfully');

        } catch (err) {
            setError(err.response?.data?.message || err.message || 'Something went wrong');
            setResponse(null);
        }
    };

    // Grouping orders by route code
    const groupedOrders = createdOrders.reduce((acc, order) => {
        const code = order.type === 'SCHEDULED' && order.routeDetails?.code ? order.routeDetails.code : `ONCALL's`;
        if (!acc[code]) acc[code] = [];
        acc[code].push(order);
        return acc;
    }, {});

    const googleMapsLink = (orders) => {
        if (!orders || orders.length === 0) return ''; // Handle undefined or empty orders

        const baseUrl = 'https://www.google.com/maps/dir/';
        const startLocation = userLocation ? `${userLocation.latitude},${userLocation.longitude}` : '';
        const path = orders.map(order => `${order.latitude},${order.longitude}`).join('/');
        return `${baseUrl}${startLocation}/${path}`;
    };

    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Spin spinning={isLoading} tip="Loading..." size="large">
                    <SpinnerOverlay isVisible={isSubmitting} message="Submitting..."/>

                    {createdOrders.length > 0 ? (
                        !selectedRouteCode ? (
                            <>
                                {/* Render "ONCALL's" card at the top */}
                                {groupedOrders["ONCALL's"] && (
                                    <RouteCard
                                        key="ONCALL's"
                                        routeCode="ONCALL's" // Display ONCALL's without "Route:" prefix
                                        ordersCount={groupedOrders["ONCALL's"].length}
                                        onClick={() => handleRouteCardClick("ONCALL's")}
                                    />
                                )}

                                {/* Render other route cards with "Route: " prefix */}
                                {Object.keys(groupedOrders)
                                    .filter((routeCode) => routeCode !== "ONCALL's")
                                    .map((routeCode) => (
                                        <RouteCard
                                            key={routeCode}
                                            routeCode={routeCode}
                                            ordersCount={groupedOrders[routeCode].length}
                                            onClick={() => handleRouteCardClick(routeCode)}
                                        />
                                    ))}
                            </>
                        ) : (
                            <>
                                <Button
                                    type="primary"
                                    icon={<ArrowLeftOutlined/>}
                                    onClick={handleBackToCards}
                                    style={{
                                        marginBottom: "16px",
                                        backgroundColor: "#1890ff",
                                        borderColor: "#1890ff",
                                        color: "#fff",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: "bold",
                                        padding: "0 20px",
                                        height: "40px",
                                        borderRadius: "4px",
                                        transition: "background-color 0.3s ease",
                                    }}
                                >
                                    Back to All Routes
                                </Button>
                                <OrderList
                                    createdOrders={groupedOrders[selectedRouteCode] || []}
                                    handleOpenModal={handleOpenModal}
                                    openSuccessModal={openSuccessModal}
                                    orderStatusesForDriver={orderStatusesForDriver}
                                    getStatusColor={getStatusColor}
                                />
                            </>
                        )
                    ) : (
                        <Row justify="center" style={{marginTop: '20px'}}>
                            <Col>
                                <Typography.Title level={4}>
                                    There are currently no orders to pick up. Please check back later.
                                </Typography.Title>
                                <Typography.Paragraph type="secondary">
                                    You will receive an SMS message when new orders are available. Or dispatcher will
                                    contact you.
                                </Typography.Paragraph>
                            </Col>
                        </Row>
                    )}

                    {selectedRouteCode && groupedOrders[selectedRouteCode] && (
                        <ActionButtons
                            userLocation={userLocation}
                            createdOrders={groupedOrders[selectedRouteCode]}
                            googleMapsLink={googleMapsLink(
                                groupedOrders[selectedRouteCode]
                            )}
                        />
                    )}

                    <MyModalComponent
                        successModalIsOpen={successModalIsOpen}
                        closeSuccessModal={closeSuccessModal}
                        isSubmitting={isSubmitting}
                        uploadStatus={uploadStatus}
                        token={token}
                        currentOrderData={currentOrderData}
                        userLocation={userLocation}
                        handleSubmit={handleSubmit}
                        resetAndClose={resetAndClose}
                        setIcePackInsideBox={setIcePackInsideBox}
                        icePackInsideBox={icePackInsideBox}
                        setBoxIsEmpty={setBoxIsEmpty}
                        boxIsEmpty={boxIsEmpty}
                        customNotes={customNotes}
                        setCustomNotes={setCustomNotes}
                        error={error}
                    />

                    <OpenMapModal
                        isOpen={modalIsOpen}
                        closeModal={closeModal}
                        mapCenter={modalMapCenter}
                    />
                </Spin>
            </Col>
        </Row>
    );
};

export default DriverOnMyWayTab;
