import { useState, useContext } from "react"; 
import axioss from '../../../restAPI';
import { AuthContext } from "../../../components/AuthContext";
import Loading from "../../../components/Loading";
import {Button, Input, Space, App} from 'antd';

const RowSetNewPassword = (props) => {
    const { userID } = props;
    const [loading, setLoading] = useState(false);
    const { authConfig } = useContext(AuthContext);
    const {message} = App.useApp();

    const [newPassword, setNewPassword] = useState(""); 
    const submit = async () => {
        try {
            setLoading(true);
            const response = await axioss.put(`/users/update-password/${userID}`, { userID, newPassword }, authConfig);
            console.log(response);
            message.success("Updated Successfully")
            setNewPassword("")
        }
        catch (err) {
            console.log(err);
            message.error("Error while updating the password")
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <Loading loading={loading}>
            <Space direction="vertical">
                <b>Set Up New Password</b>
                <Input value={newPassword} onChange={(e) => {setNewPassword(e.target.value)}} placeholder="At Least 5 characters"/>
                <Button type="primary" onClick={submit} disabled={newPassword.length<5}>Submit</Button> 
            </Space>
        </Loading>
    );
};

export default RowSetNewPassword;
