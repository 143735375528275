import React, {useState, useEffect} from 'react';
import { Row, Col, Spin, Typography } from 'antd';
import GetAllAribill from './GetAllAribill';

const {Title} = Typography;

const AirbillDispatcher = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); 
    }, 2000);
  }, []);

  return (
    <Spin spinning={isLoading} tip="Loading..." size='large'>
    <Row justify="center" align="middle">
      <Col xl={23} lg={23} md={23} sm={23} xs={23}>
        <Row justify={'center'}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Title level={2} className="mainLabel">Airbill</Title>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <GetAllAribill/>
          </Col>
        </Row>
      </Col>
    </Row>
    </Spin>
  );
}

export default AirbillDispatcher;
