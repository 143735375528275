import React from "react";
import {  Table, Badge } from 'antd';
import { orderStatuses } from "../../../services/orderStatuses";

const columns = [
  {
    title: 'Route Code',
    dataIndex: ['routeDetails', 'code'],
    key: 'route_code',
    width: '100px',
    align: 'center'
  },    
  {
    title: 'Client ID',
    dataIndex: ['clientDetails', 'id'],
    key: 'client_id',
    width: '80px',
    align: 'center'
  },
  {
    title: 'Client Name',
    dataIndex: ['clientDetails', 'name'],
    key: 'name',
    align: 'center'
  },
  {
    title: 'Client Address',
    dataIndex: ['clientDetails', 'street'],
    key: 'street',
    align: 'center'
  },
  {
    title: 'Driver',
    dataIndex: 'driverDetails',
    key: 'driver',
    align: 'center',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '140px',
    align: 'center',
    render: (text) => {
      let badgeText = text.toUpperCase();
      let badgeColor = '';
      switch (text) {
        case orderStatuses.CREATED:
          badgeColor = '#888888';
          break;
        case orderStatuses.NOT_ASSIGNED:
          badgeColor = 'red';
          badgeText = 'NOT ASSIGNED';
          break;
        case orderStatuses.CANCELED:
          badgeColor = 'brown';
          break;
        case orderStatuses.PICKED_UP:
          badgeColor = 'green';
          badgeText = 'PICKED UP';
          break;
        case orderStatuses.PICKED_UP_WITH_ISSUES:
          badgeColor = 'black';
          badgeText = 'PICKED UP WITH ISSUES';
          break;
        case orderStatuses.HANDED_OVER:
          badgeColor = 'violet';
          badgeText = 'HANDED OVER';
          break;
        case orderStatuses.DROPPED_DOWN:
          badgeColor = 'violet';
          badgeText = 'DROPPED DOWN';
          break;
        case orderStatuses.CONFIRMED:
          badgeColor = 'blue';
          break;
        default:
          badgeColor = 'cyan';
          break;
      }
      return <Badge count={badgeText} style={{ backgroundColor: badgeColor }} />;
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '140px',
    align: 'center',
  }
];

const AirbillTable = ({ dataSource, rowSelection }) => (
  <Table
    rowSelection={rowSelection}
    columns={columns}
    dataSource={dataSource.map((item, index) => ({ key: index, ...item }))}
    bordered
    size='small'
    className="rowHighlight"
    scroll={{ y: 600 }}
  />
);

export default AirbillTable;