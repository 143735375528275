const getStatusColor = (status) => {
  switch (status) {
      case 'CREATED':
          return '#888888';
      case 'NOT_ASSIGNED':
          return 'cyan';
      case 'CANCELED':
          return 'brown';
      case 'PICKED_UP':
          return 'green';
      case 'PICKED_UP_WITH_ISSUES':
          return 'red';
      case 'CONFIRMED':
          return 'blue';
      default:
          return 'cyan';
  }
};

export default getStatusColor;
