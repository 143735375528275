import React from 'react';
import { Modal, Input } from 'antd';

const EditRouteModal = ({ editRoute, handleEditChange, closeEditModal, handleEditSubmit }) => (
    <Modal
        title="Edit Route"
        open={!!editRoute}
        onCancel={closeEditModal}
        onOk={handleEditSubmit}
    >
        <div>
          Name:
          <Input
            value={editRoute?.name}
            onChange={(e) => handleEditChange(e, 'name')}
          />
        </div>
        <div>
          Phone:
          <Input
            value={editRoute?.phone}
            onChange={(e) => handleEditChange(e, 'phone')}
          />
        </div>
        <div>
          Code:
          <Input
            value={editRoute?.code}
            onChange={(e) => handleEditChange(e, 'code')}
          />
        </div>
        <div>
          Pick Up Type:
          <Input
            value={editRoute?.pick_up_type}
            onChange={(e) => handleEditChange(e, 'pick_up_type')}
          />
        </div>
    </Modal>
);

export default EditRouteModal;
