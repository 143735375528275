import React, {useState, useEffect, useCallback} from 'react';
import {Row, Col, Select, DatePicker, Input, Typography} from 'antd';
import api from "../../../restAPI";
import dayjs from 'dayjs';
import {useNavigate, useLocation} from 'react-router-dom';

const {Option} = Select;
const {Text} = Typography;

const FilterPanel = ({filters, setFilters, token, onFilterChange}) => {
    const [drivers, setDrivers] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const updateURLParams = (updatedFilters) => {
        const queryParams = new URLSearchParams();

        for (const key in updatedFilters) {
            if (updatedFilters[key]) {
                if (dayjs.isDayjs(updatedFilters[key])) {
                    queryParams.set(key, updatedFilters[key].format('YYYY-MM-DD'));
                } else {
                    queryParams.set(key, updatedFilters[key]);
                }
            }
        }

        navigate({
            pathname: location.pathname,
            search: queryParams.toString(),
        });
    };

    const handleChange = (key, value) => {
        const updatedFilters = {
            ...filters,
            [key]: value,
        };

        setFilters(updatedFilters);
        onFilterChange(updatedFilters);
        updateURLParams(updatedFilters);
    };

    const handleDriverChange = (value) => {
        handleChange('driverIdSearch', value);
    };

    const handleRouteChange = (value) => {
        handleChange('routeCodeSearch', value);
    };

    const handleSearch = (value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            driverSearchInput: value,
        }));
    };

    const getDrivers = useCallback(async () => {
        try {
            setLoading(true);
            const response = await api.get('/driver/drivers-list/', {
                headers: {Authorization: `Bearer ${token}`}
            });
            setDrivers(response.data.drivers);
        } catch (err) {
            console.error("Error while fetching drivers", err);
        } finally {
            setLoading(false);
        }
    }, [token]);

    const fetchRoutes = useCallback(async () => {
        try {
            const config = {
                headers: {Authorization: `Bearer ${token}`}
            };
            const response = await api.get('/routes/?limit=1000', config);
            setRoutes(response.data.routes || []);
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    useEffect(() => {
        getDrivers();
        fetchRoutes();
    }, [getDrivers, fetchRoutes]);


    return (
        <Row gutter={[16, 16]} style={{marginBottom: 25}}>
            <Col span={3}>
                <Text type="secondary">By Client ID</Text>
                <Input
                    placeholder="Enter Client ID"
                    onChange={(e) => handleChange('clientIdSearch', e.target.value)}
                    value={filters.clientIdSearch}
                    style={{marginTop: 8}}
                />
            </Col>

            <Col span={3}>
                <Text type="secondary">By Date</Text>
                <DatePicker
                    placeholder="Select Date"
                    onChange={(date) => handleChange('selectedDate', date)}
                    value={filters.selectedDate}
                    style={{width: '100%', marginTop: 8}}
                />
            </Col>

            <Col span={3}>
                <Text type="secondary">By Status</Text>
                <Select
                    placeholder="Status"
                    onChange={(value) => handleChange('statusFilter', value)}
                    value={filters.statusFilter || undefined}  // Ensure placeholder shows when no value is selected
                    style={{width: '100%', marginTop: 8}}
                    allowClear
                >
                    <Option value="">ALL</Option>
                    <Option value="CANCELED">CANCELED</Option>
                    <Option value="CONFIRMED">CONFIRMED</Option>
                    <Option value="NOT_ASSIGNED">NOT ASSIGNED</Option>
                    <Option value="CREATED">CREATED</Option>
                    <Option value="PICKED_UP">PICKED UP</Option>
                    <Option value="PICKED_UP_WITH_ISSUES">PICKED UP WITH ISSUES</Option>
                    <Option value="RECEIVED_WITH_ISSUE">RECEIVED WITH ISSUE</Option>
                    {/*<Option value="HANDED_OVER_TO_ACCESSION">HANDED OVER</Option>*/}
                    {/*<Option value="DROPPED_DOWN">DROPPED DOWN</Option>*/}
                    {/*<Option value="PUSHED_TO_AIR_BILL">PUSHED TO AIR BILL</Option>*/}
                </Select>
            </Col>

            <Col span={3}>
                <Text type="secondary">By Order Type</Text>
                <Select
                    placeholder="Order Type"
                    onChange={(value) => handleChange('typeFilter', value)}
                    value={filters.typeFilter || undefined}  // Ensure placeholder shows when no value is selected
                    style={{width: '100%', marginTop: 8}}
                    allowClear
                >
                    <Option value="">ALL</Option>
                    <Option value="SCHEDULED">SCHEDULED</Option>
                    <Option value="ONCALLROUTINE">ONCALLROUTINE</Option>
                    <Option value="ONCALLSTAT">ONCALLSTAT</Option>
                </Select>
            </Col>

            <Col span={5}>
                <Text type="secondary">By Driver</Text>
                <Select
                    showSearch
                    placeholder="Select Driver"
                    onChange={handleDriverChange}
                    onSearch={handleSearch}
                    value={filters.driverIdSearch || undefined}  // Ensure placeholder shows when no value is selected
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{width: '100%', marginTop: 8}}
                    loading={loading}
                    allowClear
                >
                    {drivers.map(driver => (
                        <Option key={driver.id} value={driver.id}>
                            {`${driver.first_name} ${driver.last_name}`}
                        </Option>
                    ))}
                </Select>
            </Col>

            <Col span={5}>
                <Text type="secondary">By Route</Text>
                <Select
                    placeholder="Select a route"
                    showSearch
                    onChange={handleRouteChange}
                    value={filters.routeCodeSearch || undefined}  // Ensure placeholder shows when no value is selected
                    style={{width: '100%', marginTop: 8}}
                    filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    allowClear
                >
                    {routes.map(route => (
                        <Option key={route.code} value={route.code}
                                label={`${route.code} - ${route.name} (${route.region})`}>
                            <span>
                                <b>{route.code}</b> - {route.name} ({route.region})
                            </span>
                        </Option>
                    ))}
                </Select>
            </Col>
        </Row>
    );
};

export default FilterPanel;
