import React from "react";
import {Outlet} from "react-router-dom";
import {Layout, Row, Col, Typography} from "antd";

import Navigation from "./Navigation";
import dayjs from "dayjs";

const AppLayout = () => {
    const {Footer, Content} = Layout;
    const {Text} = Typography;

    return (
        <Layout className="layout">
            <Navigation className="header"/>

            <Content className="content">
                <Outlet/>
            </Content>

            <Footer className="footer">
                <Row justify="center" align="middle">
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Row justify="center">
                            <Text className="footerLabel">
                                2008-{dayjs().format('YYYY')} Empire City Laboratories, Inc.
                            </Text>
                        </Row>
                    </Col>
                </Row>
            </Footer>
        </Layout>
    );
};

export default AppLayout;
