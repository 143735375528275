import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Table, Tabs, Row, Col, Typography, Badge, notification, Tag, Empty, Spin} from 'antd';
import api from '../../../restAPI';
import {AuthContext} from '../../../components/AuthContext';
import {orderStatuses} from '../../../services/orderStatuses';
import DetailsModal from './DetailsModal';
import buildQueryParams from '../../../services/utilities/buildQueryParams';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const {Title} = Typography;

const formatDate = (date) => (dayjs(date).isValid() ? dayjs(date).tz('America/New_York').format('MM/DD/YYYY HH:mm A') : '-- : --');

const columns = [
    {
        title: 'Created At',
        dataIndex: 'created',
        key: 'created',
        width: '100px',
        align: 'center',
        render: (text) => formatDate(text),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '140px',
        align: 'center',
        render: (text) => {
            let badgeText = text.toUpperCase();

            if (text === orderStatuses.CREATED) {
                return <Badge count={badgeText} style={{backgroundColor: '#888888'}}/>;
            }
            if (text === orderStatuses.NOT_ASSIGNED) {
                return <Badge count={'NOT ASSIGNED'} style={{backgroundColor: 'red'}}/>;
            }
            if (text === orderStatuses.CANCELED) {
                return <Badge count={badgeText} style={{backgroundColor: 'brown'}}/>;
            }
            if (text === orderStatuses.PICKED_UP) {
                return <Badge count={'PICKED UP'} style={{backgroundColor: 'green'}}/>;
            }
            if (text === orderStatuses.PICKED_UP_WITH_ISSUES) {
                return <Badge count={'PICKED UP ISSUES'} style={{backgroundColor: 'black'}}/>;
            }
            if (text === orderStatuses.DROPPED_DOWN) {
                return <Badge count={'DROPPED DOWN'} style={{backgroundColor: 'green'}}/>;
            }
            if (text === orderStatuses.PUSHED_TO_AIR_BILL) {
                return <Badge count={'PUSHED TO AIRBILL'} style={{backgroundColor: 'green'}}/>;
            }
            if (text === orderStatuses.HANDED_OVER) {
                return <Badge count={'HANDED OVER'} style={{backgroundColor: 'violet'}}/>;
            }
            if (text === orderStatuses.CONFIRMED) {
                return <Badge count={badgeText} style={{backgroundColor: 'blue'}}/>;
            }
            return <Badge count={badgeText} style={{backgroundColor: 'cyan'}}/>;
        },
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: '140px',
        align: 'center',
        render: (text) => {
            if (text === 'ONCALLSTAT' || text === 'ONCALLROUTINE') {
                return <Badge count={'ONCALL'} style={{backgroundColor: 'red'}}/>;
            }
            if (text === 'SCHEDULED') {
                return <Badge count={text.toUpperCase()} style={{backgroundColor: '#888888'}}/>;
            }
            return <Badge count={text.toUpperCase()} style={{backgroundColor: 'cyan'}}/>;
        }
    },
    // {
    //     title: 'Route Code',
    //     dataIndex: ['routeDetails', 'code'],
    //     key: 'route_code',
    //     width: '100px',
    //     align: 'center',
    //     render: text => text ? <Tag bordered={false}><strong>{text}</strong></Tag> : 'N/A'
    // },
    {
        title: 'Client ID',
        dataIndex: 'client_id',
        key: 'client_id',
        width: '100px',
        align: 'center'
    },
    {
        title: 'Client Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        render: (text, record) => record.clientDetails ? record.clientDetails.name : 'N/A'
    },
    {
        title: 'Client Address',
        dataIndex: 'street',
        key: 'street',
        align: 'center',
        render: (text, record) => record.clientDetails ? record.clientDetails.street : 'N/A'
    },
    // {
    //     title: 'Driver',
    //     dataIndex: 'driverDetails',
    //     key: 'driver',
    //     align: 'center',
    //     render: (driverDetails) => driverDetails.first_name !== null ? `${driverDetails.first_name} ${driverDetails.last_name}` : 'N/A'
    // },
    {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        align: 'center'
    },
    // {
    //     title: 'Driver Comments',
    //     dataIndex: 'comments',
    //     key: 'comments',
    //     align: 'center'
    // },
];

const PayAttentionPage = () => {
    const [activeTab, setActiveTab] = useState('tab1');
    const [data, setData] = useState([]);
    const {token} = useContext(AuthContext);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [routes, setRoutes] = useState([]);
    const [selectKey, setSelectKey] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [status, setStatus] = useState(null);
    // eslint-disable-next-line
    const [clientIdSearch, setClientIdSearch] = useState("");
    // eslint-disable-next-line
    const [clientNameSearch, setClientNameSearch] = useState("");
    // eslint-disable-next-line
    const [statusFilter, setStatusFilter] = useState("");
    // eslint-disable-next-line
    const [typeFilter, setTypeFilter] = useState("");
    // eslint-disable-next-line
    const [selectedDate, setSelectedDate] = useState();
    // eslint-disable-next-line
    const [totalItems, setTotalItems] = useState(0);
    // eslint-disable-next-line
    const [limit, setLimit] = useState(1000);
    // eslint-disable-next-line
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [offset, limit]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientIdSearch, clientNameSearch, typeFilter, statusFilter, selectedDate]);

    const fetchData = async () => {
        setIsLoading(true);
        const config = {headers: {Authorization: `Bearer ${token}`}};
        const params = {
            limit,
            offset,
            clientIdSearch,
            clientNameSearch,
            typeFilter,
            statusFilter,
            selectedDate: dayjs(selectedDate).format('YYYY-MM-DD')
        };
        const url = `/orders/oncall-orders/?${buildQueryParams(params)}`;
        try {
            const response = await api.get(url, config);
            const items = response.data.items;

            setData(items);
            setTotalItems(response.data.count.count);
        } catch (error) {
            notification.error({message: 'Failed to fetch orders.'});
            console.log(error);
        }
        setIsLoading(false);
    };

    const fetchRoutes = useCallback(async () => {
        try {
            const config = {
                headers: {Authorization: `Bearer ${token}`},
                params: {limit: 2000}
            };
            const response = await api.get('/routes/', config);
            setRoutes(response.data.routes);
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    const assignRoute = async (orderId, driverId) => {
        setIsSubmitting(true);
        try {
            const config = {headers: {Authorization: `Bearer ${token}`}};
            // await api.put(`/orders/assign-route/${orderId}/`, {        --------------- legacy code
            await api.put(`/orders/assign-driver-to-order/${orderId}/`, {
                order_id: orderId,
                driver_id: driverId
            }, config);
            setIsModalVisible(false);
            fetchData();
            notification.success({
                message: 'Success',
                description: 'Route assigned successfully!',
            });
        } catch (error) {
            console.error(error);
            notification.error({
                message: 'Error',
                description: 'Failed to assign route!',
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        fetchRoutes();
    }, [fetchRoutes]);

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const handleRowHover = (record) => {
        setHoveredRow(record);
    };

    const handleRowClick = (record) => {
        setSelectedRowData(record);
        setSelectKey(prevKey => prevKey + 1);
        setIsModalVisible(true);
        setStatus(record.status);
    };

    const resetSelectedRoute = () => {
        setSelectedRoute(null);
    };

    const handleModalClose = () => {
        resetSelectedRoute();
        setIsModalVisible(false);
    };

    const filterOptionForRoutes = (input, option) => {
        const routeData = routes.find(route => route.id === option.value);
        if (!routeData) return false;
        const routeCode = String(routeData.code).toLowerCase();
        const routeDriverId = String(routeData.driver_id).toLowerCase();
        return (
            routeCode.includes(input.toLowerCase()) || routeDriverId.includes(input.toLowerCase())
        );
    };

    useEffect(() => {
        if (!isModalVisible) {
            setSelectedRoute(null);
        }
    }, [isModalVisible]);

    return (
        <Spin
            spinning={isLoading} size="large" tip="Loading..."
            style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
        >

            <Row justify="center" align="middle">
                <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                    <Row justify="center">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Title level={2} className="mainLabel">
                                "Pay Attention" Dashboard
                            </Title>
                            <Typography.Paragraph type="secondary">
                                This page displays all stops what needed a Dispatcher's attention.
                                <br/>
                                Here we show all &nbsp;
                                <Tag bordered={false} color={'rgba(255,174,76,0.64)'}>Not assigned</Tag>,&nbsp;&nbsp;
                                <Tag bordered={false} color={'rgba(227,14,14,0.59)'}>With issues</Tag> On-Call stops
                                and etc.
                            </Typography.Paragraph>
                        </Col>

                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                                <Tabs.TabPane tab="NY" key="tab1"/>
                            </Tabs>
                            {activeTab === 'tab1' && (
                                data.length > 0 ? (
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        bordered
                                        size='small'
                                        onRow={(record) => ({
                                            onClick: () => handleRowClick(record),
                                            onMouseEnter: () => handleRowHover(record),
                                            onMouseLeave: () => handleRowHover(null),
                                            style: {cursor: record === hoveredRow ? 'pointer' : 'default'},
                                        })}
                                        pagination={false}
                                    />
                                ) : (
                                    <Empty description="No Data"/>
                                )
                            )}
                        </Col>
                    </Row>
                </Col>

                <DetailsModal
                    isModalVisible={isModalVisible}
                    selectedRowData={selectedRowData}
                    handleModalClose={handleModalClose}
                    selectedRoute={selectedRoute}
                    assignRoute={assignRoute}
                    routes={routes}
                    selectKey={selectKey}
                    filterOptionForRoutes={filterOptionForRoutes}
                    isSubmitting={isSubmitting}
                    status={status}
                />

            </Row>
        </Spin>
    );
};

export default PayAttentionPage;
