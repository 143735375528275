import api from '../../../restAPI';
import {AuthContext} from "../../../components/AuthContext";
import React, {useState, useEffect, useContext} from "react";
import UsersTable from "./table"
import Loading from "../../../components/Loading";
import RegisterSuperUser from "../../Dispatcher/Registration/RegisterSuperUser";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Modal, Typography, message, Row, Col} from 'antd'

const UsersManagement = () => {
    const [loading, setLoading] = useState(false)
    const {authConfig} = useContext(AuthContext)
    const [firstLoading, setFirstLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);

    // const { modal } = App.useApp()

    useEffect(() => {
        if (firstLoading) {
            getUsers();
            setFirstLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //* pagination
    // eslint-disable-next-line no-unused-vars
    const [totalItems, setTotalItems] = useState(0)
    // eslint-disable-next-line no-unused-vars
    const [limit, setLimit] = useState(1000)
    // eslint-disable-next-line no-unused-vars
    const [offset, setOffset] = useState(0)
    useEffect(() => {
        if (!firstLoading) {
            getUsers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset, limit])
    //* pagination

    console.log(users)


    const getUsers = async () => {
        try {
            setLoading(true)
            const response = await api.get(`/users?limit=${limit}&offset=${offset}`, authConfig)
            setUsers(response.data.users)
            setTotalItems(response.data.count)
        } catch (err) {
            console.log(err)
            message.warning({
                title: 'Something went wrong...',
                content: err.message,
            });
        } finally {
            setLoading(false)
        }
    }

    return (
        <Row justify="center">
            <Col xl={22} lg={22} md={22} sm={22} xs={22}>
                <Typography.Title level={2} style={{marginTop: 25}}>
                    Users
                </Typography.Title>

                <Typography.Paragraph type={'secondary'}>
                    Manage users
                </Typography.Paragraph>

                <Button type="primary" onClick={() => {
                    setIsModalVisible(true)
                }} className="addUserBtn">
                    <PlusOutlined style={{marginRight: '4px'}}/> Add User
                </Button>

                <Loading loading={loading}>
                    <Modal
                        open={isModalVisible} onCancel={() => {
                        setIsModalVisible(false)
                    }}
                        footer={null}
                    >

                        <RegisterSuperUser onSuccess={() => {
                            window.location.reload();
                        }}/>
                    </Modal>

                    <UsersTable dataSource={users}/>
                </Loading>
            </Col>
        </Row>
    )
}

export default UsersManagement