import React, { useState, useContext, useEffect, useCallback } from "react";
import { Table, Row, Col, Typography, Modal, Button, Spin, message } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import api from "../../../restAPI";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../../components/AuthContext";
import RegisterSuperUser from "../Registration/RegisterSuperUser";
import RegisterDispatcher from "../Registration/RegisterDispatcher";
import EditDriverModal from './EditDriverModal';
import TableRow from "./tableRow";

const { Title } = Typography;

const UsersList = () => {
  const [data, setData] = useState([]);
  const { token } = useContext(AuthContext);
  const userRole = token ? jwt_decode(token).userRole : null;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editingDriver, setEditingDriver] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await api.get('/driver', config);
      setData(response.data.driver);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  const handleUpdate = async (updatedDriver) => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      await api.put(`/driver/${updatedDriver.id}`, updatedDriver, config);
      await fetchData();
      setIsEditModalVisible(false);
      message.success('Driver details updated successfully!');
    } catch (error) {
      console.log(error);
      message.error("An error occurred while updating the driver data. Please try again.");
    } finally {
      setIsLoading(false);
    }
};

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEdit = (record) => {
    setEditingDriver(record);
    setIsEditModalVisible(true); 
  };

  const refetchAndCloseModal = useCallback(async () => {
    await fetchData();
    setIsModalVisible(false);
  }, [fetchData]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: '80px'
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
      render: (_, record) => `${record.first_name} ${record.last_name}`,
      width: '200px',

    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: '120px',
    },

    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: '50px'
    },
    {
      title: 'Edit',
      key: 'edit',
      width: '50px',
      render: (text, record) => (
        <Button type="none" icon={<EditOutlined />} onClick={() => handleEdit(record)} style={{color:'blue'}}/>
      ),
    },
    {
      title: 'Remove',
      key: 'remove',
      width: '50px',
      render: (text, record) => (
        <Button 
          type="danger" 
          icon={<DeleteOutlined style={{color:'red'}} />} 
          onClick={() => message.info("Sergey said to temporarily disable the driver deletion function. It can be reactivated at any moment, it's a matter of a minute.", 5)}
        />
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Spin spinning={isLoading} tip="Loading..." size='large'>
        <Row justify="center">
          <Col xl={23} lg={22} md={22} sm={22} xs={22}>
            <Row justify="center">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                {userRole === 1 && <Title level={2} className="mainLabel">Drivers Maintenance</Title>}
                {userRole === 2 && <Title level={2} className="mainLabel">Drivers Maintenance 2</Title>}
              </Col>
              
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                {userRole === 1 && 
                  <Button type="primary" onClick={showModal} className="addUserBtn">
                    <PlusOutlined style={{ marginRight: '4px' }} /> Add User
                  </Button>
                }
                
                {userRole === 2 && 
                  <Button type="primary" onClick={showModal} className="addUserBtn">
                    <PlusOutlined style={{ marginRight: '4px' }} /> Add Driver
                  </Button>
                }
                
                
                <Modal open={isModalVisible} onCancel={handleCancel} footer={null}>
                  {userRole === 1 && <RegisterSuperUser onCancel={handleCancel} onSuccess={refetchAndCloseModal} />}
                  {userRole === 2 && <RegisterDispatcher onCancel={handleCancel} onSuccess={refetchAndCloseModal} />}
                </Modal>
              </Col>
            </Row>
    
            <Row justify={'center'} align={'middle'}>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Spin spinning={isLoading}>
                  <Table
                      columns={columns}
                      dataSource={data}
                      rowKey="id"
                      size="small"
                      onChange={onChange}
                      pagination={false}
                      scroll={{ y: 550 }}
                      expandable={{ expandedRowRender: (record) => (<div style={{ margin: 0, }} > <TableRow record={record} /> </div>), rowExpandable: (record) => record.name !== 'Not Expandable', }}
                  />
                </Spin>
              </Col>
            </Row>
          </Col>
        </Row>

        <EditDriverModal isVisible={isEditModalVisible} onCancel={() => setIsEditModalVisible(false)} driver={editingDriver} onUpdate={handleUpdate} />
    </Spin>
  )
}

export default UsersList;