import React from 'react';
import {Spin} from 'antd';

const SpinnerOverlay = ({isVisible, message}) => {
    if (!isVisible) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            background: 'rgba(255, 255, 255, 0.8)',
            zIndex: 2000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Spin tip={message} size="large"/>
        </div>
    );
};

export default SpinnerOverlay;
