import React from 'react';
import {Badge} from 'antd';
import {orderStatuses} from '../../../services/orderStatuses';

const statusColors = {
    [orderStatuses.CREATED]: '#888888',
    [orderStatuses.NOT_ASSIGNED]: 'red',
    [orderStatuses.CANCELED]: 'brown',
    [orderStatuses.PICKED_UP]: 'green',
    [orderStatuses.PICKED_UP_WITH_ISSUES]: 'black',
    [orderStatuses.DROPPED_DOWN]: 'green',
    [orderStatuses.PUSHED_TO_AIR_BILL]: 'green',
    [orderStatuses.HANDED_OVER]: 'violet',
    [orderStatuses.CONFIRMED]: 'blue'
};

const typeColors = {
    'ONCALLSTAT': 'red',
    'ONCALLROUTINE': 'red',
    'SCHEDULED': '#888888'
};

export const BadgeRenderer = ({text, type}) => {
    const colors = type === 'status' ? statusColors : typeColors;
    const color = colors[text] || 'purple';
    const badgeText = (type === 'type' && (text === 'ONCALLSTAT' || text === 'ONCALLROUTINE'))
        ? 'ONCALL'
        : text.replace('_', ' ').toUpperCase();

    return <Badge count={badgeText} style={{backgroundColor: color}}/>;
};
