import React from 'react';
import {Card, Typography} from 'antd';


const RouteCard = ({routeCode, ordersCount, onClick, style}) => {
    const isOnCall = routeCode === "ONCALL's";

    return (
        <Card
            title={
                <Typography.Title
                    level={5}
                    style={{color: isOnCall ? 'white' : undefined}}
                >
                    {isOnCall ? routeCode : `Route: ${routeCode}`}
                </Typography.Title>
            }
            hoverable
            style={{
                marginBottom: '16px',
                backgroundColor: isOnCall ? 'rgb(164,34,34)' : undefined,
                color: isOnCall ? 'white' : undefined,
                ...style,
            }}
            onClick={() => onClick(routeCode)}
        >
            <p>{ordersCount} orders</p>
            <p style={{fontSize: 10}}>click for details</p>
        </Card>
    );
};

export default RouteCard;
