import React, {useEffect, useState} from 'react';
import {Modal, Button} from 'antd';
import {Loader} from '@googlemaps/js-api-loader';

const mapContainerStyle = {
    height: '80vh',
    width: "100%",
};

const GOOGLE_MAPS_API_KEY = 'AIzaSyCeF9pve4e1BMOP9f6gA3p1MpEbiHlGY3I';

const MapModal = ({visible, onClose, driverLocation, driverId, socket}) => {
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [coords, setCoords] = useState(driverLocation);
    const [streetName, setStreetName] = useState('');
    // eslint-disable-next-line
    const [nextUpdateTime, setNextUpdateTime] = useState('');

    useEffect(() => {
        const initializeMap = async () => {
            const loader = new Loader({
                apiKey: GOOGLE_MAPS_API_KEY,
                version: "weekly",
                libraries: ["marker"],
            });

            await loader.load();
            const mapElement = document.getElementById("map");
            if (mapElement) {
                const mapInstance = new window.google.maps.Map(mapElement, {
                    center: {
                        lat: driverLocation.latitude,
                        lng: driverLocation.longitude,
                    },
                    zoom: 15,
                    mapId: "d671dcfc9062cba5", // Your mapId if you have one
                });

                const newMarker = new window.google.maps.marker.AdvancedMarkerElement({
                    position: {
                        lat: driverLocation.latitude,
                        lng: driverLocation.longitude,
                    },
                    map: mapInstance,
                });

                setMap(mapInstance);
                setMarker(newMarker);
            }
        };

        if (visible && driverLocation) {
            initializeMap();
        }

        return () => {
            if (socket) {
                socket.off('driver-location-updated');
            }
        };
    }, [visible, driverLocation, socket]);

    useEffect(() => {
        if (socket) {
            const handleLocationUpdate = (locationUpdate) => {
                if (locationUpdate.driverId === driverId) {
                    const newLatLng = new window.google.maps.LatLng(locationUpdate.location.latitude, locationUpdate.location.longitude);
                    if (marker && map) {
                        marker.position = newLatLng;
                        map.panTo(newLatLng);
                        setCoords(locationUpdate.location);
                        setNextUpdateTime(new Date().toLocaleTimeString());
                    }
                }
            };

            socket.on('driver-location-updated', handleLocationUpdate);

            return () => {
                socket.off('driver-location-updated', handleLocationUpdate);
            };
        }
    }, [socket, driverId, marker, map]);

    const fetchStreetName = (lat, lng) => {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`)
            .then(response => response.json())
            .then(data => {
                if (data.results && data.results.length > 0) {
                    const address = data.results[0].formatted_address;
                    setStreetName(address);
                } else {
                    setStreetName('Address not found');
                }
            })
            .catch(error => {
                console.error('Error fetching street name:', error);
                setStreetName('Error fetching address');
            });
    };

    const handleFetchStreetName = () => {
        fetchStreetName(coords.latitude, coords.longitude);
    };

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            footer={null}
            width={1000}
            style={{top: 20}}
        >
            <div id="map" style={mapContainerStyle}></div>
            <div style={{padding: '15px'}}>
                <Button
                    size={'small'}
                    type="primary"
                    onClick={handleFetchStreetName}
                    disabled={!!streetName}
                >
                    Show Current Address
                </Button>

                {streetName && (
                    <>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <strong>Street Name:</strong> {streetName}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default MapModal;
