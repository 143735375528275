import React, {useContext} from "react";
import {AuthContext} from "../../../components/AuthContext";
import {useParams} from "react-router-dom";
import WeeklyScheduleComponent from "./WeeklyScheduleComponent";


const ClientScheduleDetails = () => {
    const {clientId} = useParams();
    const {token} = useContext(AuthContext);
    return (
        <div>
            <WeeklyScheduleComponent client_id={clientId} token={token}/>
        </div>
    );
}

export default ClientScheduleDetails;