import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Table, Row, Col, Modal, Typography, Input, Select, Spin} from 'antd';
import api from '../../../restAPI';
import {AuthContext} from '../../../components/AuthContext';
import CreatePickUp from './CreatePickUp';

const {Title} = Typography;
const {Search} = Input;
const {Option} = Select;

const columns = [
    {
        title: 'Client ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        sorter: (a, b) => a.id - b.id,
        width: 110,
    },
    {
        title: 'Client Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: 400,
    },
    {
        title: 'Address',
        dataIndex: 'street',
        key: 'street',
        align: 'center',
        width: 400,
    },
    {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        align: 'center',
        width: 200,
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        align: 'center',
        width: 70,
    },
    {
        title: 'ZIP',
        dataIndex: 'zip',
        align: 'center',
        key: 'zip',
        width: 100,
    },
];

const NewPickUp = () => {
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const {token} = useContext(AuthContext);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [searchId, setSearchId] = useState('');
    const [searchType, setSearchType] = useState('id');
    const [hoveredRow, setHoveredRow] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const response = await api.get('/clients/', config);
            setData(response.data.clients);
            setAllData(response.data.clients);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [token]);

    const handleRowClick = (record) => {
        setSelectedRowData(record);
        setIsModalVisible(true);
    };

    const handleRowHover = (record) => {
        setHoveredRow(record);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    const handleSearch = useCallback(async (event) => {
        const value = event.target.value;
        setSearchId(value);

        if (!value) {
            setData(allData);
            return;
        }

        setIsLoading(true);

        try {
            let response;

            if (searchType === 'id' && Number(value)) {
                response = await api.get(`/clients/search/id/${value}/`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data.clients.length > 0) {
                    setData([response.data.clients[0]]);
                } else {
                    setData([]);
                }
            } else if (searchType === 'name' && value.trim() !== '') {
                response = await api.get(`/clients/search/name/${value}/`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                setData(response.data.client);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [allData, searchType, token]);

    const handleSearchTypeChange = (value) => {
        setSearchType(value);
        setSearchId('');
    };

    useEffect(() => {
        let filteredData = allData;

        if (searchType === 'id' && Number(searchId)) {
            filteredData = allData.filter(item => item.id === Number(searchId));
        } else if (searchType === 'name' && searchId.trim() !== '') {
            filteredData = allData.filter(item => item.name.toLowerCase().includes(searchId.toLowerCase()));
        }

        setData(filteredData);
    }, [searchType, searchId, allData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Spin spinning={isLoading} tip="Loading..." size='large'>
            <Row justify="center" align="middle">
                <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                    <Row justify={'center'} align={'middle'}>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Title level={2} className="mainLabel">New Pick-Up</Title>
                            <Row gutter={[16, 16]} justify="center" align="middle">
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Select
                                        defaultValue="id"
                                        onChange={handleSearchTypeChange}
                                        style={{width: '170px'}}
                                    >
                                        <Option value="id">Search by ID</Option>
                                        <Option value="name">Search by name</Option>
                                    </Select>

                                    <Search
                                        placeholder={`Enter client ${searchType === 'id' ? 'ID' : 'name'}`}
                                        allowClear
                                        size="medium"
                                        onChange={handleSearch}
                                        className='searchClients'
                                        value={searchId}
                                    />
                                </Col>
                            </Row>

                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                size='small'
                                onRow={(record) => ({
                                    onClick: () => handleRowClick(record),
                                    onMouseEnter: () => handleRowHover(record),
                                    onMouseLeave: () => handleRowHover(null),
                                    style: {cursor: record === hoveredRow ? 'pointer' : 'default'},
                                })}
                                scroll={{y: 550}}
                            />
                        </Col>
                    </Row>
                </Col>

                {/* Info Window */}
                <Modal
                    title="Create a New Pick-Up"
                    open={isModalVisible}
                    onCancel={handleModalClose}
                    footer={null}
                    width={1000}
                    style={{marginTop: '-20px'}}
                >
                    {selectedRowData && (
                        <CreatePickUp client={selectedRowData} closeModal={handleModalClose}/>
                    )}
                </Modal>
            </Row>
        </Spin>
    );
};

export default NewPickUp;