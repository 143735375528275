import React, {useState, useEffect, useContext} from 'react';
import api from '../../../restAPI';
import {AuthContext} from '../../../components/AuthContext';
import {Modal, Button, notification, Typography, List, Row, Col, Popconfirm, Tag, Spin, Divider} from 'antd';
import {PlusOutlined} from "@ant-design/icons";

const DriverRoutesDetails = ({closeDetailsModal, selectedDriver}) => {
    const [routeDetails, setRouteDetails] = useState([]);
    const [allRoutes, setAllRoutes] = useState([]);
    const [assignModalVisible, setAssignModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const {token} = useContext(AuthContext);

    useEffect(() => {
        const fetchRouteDetails = async () => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                const response = await api.get(`/routes/get-driver-routes/${selectedDriver?.id}/`, config);
                setRouteDetails(response.data.routes);
            } catch (error) {
                notification.error({
                    message: 'Error',
                    description: 'An error occurred while fetching route details.'
                });
            }
        };

        const fetchAllRoutes = async () => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                const response = await api.get(`/routes/`, config);
                setAllRoutes(response.data.routes);
            } catch (error) {
                notification.error({
                    message: 'Error',
                    description: 'An error occurred while fetching all routes.'
                });
            }
        };

        fetchRouteDetails();
        fetchAllRoutes();
    }, [selectedDriver?.id, token]);

    const removeRouteFromDriver = async (routeId) => {
        try {
            setLoading(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: {routeId} // Ensure the routeId is sent in the data
            };
            const response = await api.delete(`/driver/${selectedDriver.id}/remove-route/`, config);
            if (response.data.status === 'Success') {
                notification.success({
                    message: 'Success',
                    description: 'Route removed successfully from the driver!'
                });
                setRouteDetails(routeDetails.filter(route => route.id !== routeId));
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message || 'An error occurred while removing the route.'
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while removing the route.'
            });
        } finally {
            setLoading(false);
        }
    };


    const addRouteToDriver = async (routeId) => {
        try {
            setLoading(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const response = await api.post(`/driver/${selectedDriver.id}/add-route/`, {routeId}, config);
            if (response.data.status === 'Success') {
                notification.success({
                    message: 'Success',
                    description: 'Route added successfully to the driver!'
                });
                setRouteDetails([...routeDetails, allRoutes.find(route => route.id === routeId)]);
                setAssignModalVisible(false);
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message || 'An error occurred while adding the route.'
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while adding the route.'
            });
        } finally {
            setLoading(false);
        }
    };

    const filteredRoutes = allRoutes.filter(route => !routeDetails.some(detail => detail.id === route.id));

    return (
        <Spin spinning={loading} tip="Loading...">
            <Modal
                open={true}
                onCancel={closeDetailsModal}
                footer={[<Button key="close" onClick={closeDetailsModal}>Close</Button>]}
                width={1000}
                closable={false}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Typography.Title level={4}>
                    Assigned Routes for Driver {selectedDriver?.first_name} {selectedDriver?.last_name}
                </Typography.Title>

                <Button
                    size={'small'}
                    icon={<PlusOutlined/>}
                    type="primary" onClick={() => setAssignModalVisible(true)}
                >Assign Route</Button>

                <Divider type={'horizontal'}/>

                <Row gutter={16}>
                    <Col lg={24}>
                        <List
                            bordered
                            dataSource={routeDetails}
                            renderItem={route => (
                                <List.Item
                                    actions={[
                                        <Popconfirm
                                            title="Are you sure to remove this route?"
                                            onConfirm={() => removeRouteFromDriver(route.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type={'primary'} size={'small'}>Remove</Button>
                                        </Popconfirm>
                                    ]}>

                                    <Tag>{route.code}</Tag> {route.name} - {route.region}
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Modal>

            <Modal
                open={assignModalVisible}
                onCancel={() => setAssignModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setAssignModalVisible(false)}>Cancel</Button>
                ]}
                width={800}
                title={`Assign route to ${selectedDriver?.first_name} ${selectedDriver?.last_name}`}
                closable={false}
                destroyOnClose={true}
                maskClosable={false}
                style={{maxHeight: '400px', overflowY: 'scroll'}}
            >
                <List
                    bordered
                    dataSource={filteredRoutes}
                    renderItem={route => (
                        <List.Item actions={[
                            <Popconfirm
                                title="Are you sure to assign this route?"
                                onConfirm={() => addRouteToDriver(route.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button size="small" type="primary">Assign</Button>
                            </Popconfirm>
                        ]}>
                            <Tag>{route.code}</Tag> {route.name} - {route.region}
                        </List.Item>
                    )}
                />
            </Modal>
        </Spin>
    );
};

export default DriverRoutesDetails;
