import React, {useContext} from 'react';
import {Row, Col, Typography} from 'antd';
import {AuthContext} from '../../../components/AuthContext';
import RoutesList from "./RoutesList";


const RoutesManagement = () => {
    const {token} = useContext(AuthContext);

    return (
        <Row justify="center" align="middle">
            <Col xl={22} lg={22} md={22} sm={22} xs={22}>
                <Row justify={'center'} align={'middle'}>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                        <Typography.Title level={2} className="mainLabel">
                            Routes List Management
                        </Typography.Title>

                        <Typography.Paragraph type="secondary">
                            ...
                        </Typography.Paragraph>

                        <RoutesList token={token}/>

                    </Col>
                </Row>
            </Col>
        </Row>
    );
};


export default RoutesManagement;
