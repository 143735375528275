import React from 'react';
import {Table} from 'antd';

const columns = [
    {
        title: 'Route',
        dataIndex: 'route_code',
        key: 'route_code',
        width: '20px',
        align: 'left'
    },
    {
        title: 'Client ID',
        dataIndex: 'client_id',
        key: 'client_id',
        width: '20px',
        align: 'left'
    },
    {
        title: 'Client Name',
        dataIndex: ['client', 'name'],
        key: 'client_name',
        width: '150px',
        align: 'left'
    },
    {
        title: 'Client Address',
        dataIndex: 'client',
        key: 'client_address',
        width: '170px',
        align: 'left',
        render: (text, record) => {
            return <>{record.client.street}, {record.client.city}</>;
        }
    },
    {
        title: 'ZIP',
        dataIndex: ['client', 'zip'],
        key: 'zip',
        width: '30px',
        align: 'left'
    },
    {
        title: 'Notes',
        dataIndex: 'client_schedule_notes',
        key: 'notes',
        width: '250px',
        align: 'left'
    },
    {
        title: 'SPM#',
        key: 'spm',
        width: '50px',
        align: 'left'
    },
    {
        title: 'ECL#',
        key: 'ecl',
        width: '50px',
        align: 'left'
    },
    {
        title: 'Time',
        key: 'time',
        width: '80px',
        align: 'left'
    },
];

const ScheduleTable = ({data, routeCode}) => {
    return (
        <Table
            id={`pdfTable_${routeCode}`}
            columns={columns}
            dataSource={data}
            bordered
            size="small"
            className="rowHighlight"
            pagination={false}
        />
    );
};

export default ScheduleTable;
