import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Table, notification, Spin } from 'antd';
import api from '../../../restAPI';
import moment from 'moment-timezone';
import { AuthContext } from '../../../components/AuthContext';

const GetAllAribill = () => {
  const [data, setData] = useState([]);
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(1);

  const columns = [
    {
      title: `№ (${totalRows})`,
      align: "center",
      width: "60px",
      dataIndex: "key",
      key: "key",
      render: (text, record, index) => index + 1
    },
    {
      title: 'Order ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: "340px",
    },
    {
      title: 'Tracking Number',
      dataIndex: 'tracking_number',
      key: 'tracking_number',
      align: 'center',
      width: "300px",
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      align: 'center',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      align: 'center',
      width: "130px",
      render: (text) => moment(text).tz("America/New_York").format('MM-DD-YY HH:mm'),
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
      align: 'center',
      width: "130px",
      render: (text) => moment(text).tz("America/New_York").format('MM-DD-YY HH:mm'),
    },
  ];

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await api.get('/airbill/get-all-airbill-orders/', config);
      if (response.status === 200) {
        const itemsWithKeys = response.data.items.map((item, index) => ({
          ...item,
          key: index
        }));
        setData(itemsWithKeys);
        setTotalRows(itemsWithKeys.length);
      } else {
        notification.error({ message: 'Failed to fetch orders.' });
      }
    } catch (error) {
      notification.error({ message: 'Failed to fetch orders.' });
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Spin spinning={isLoading}>
      <Row justify="center">
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Row justify="center">
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Table dataSource={data} columns={columns} size='small' rowKey="id" pagination={false} scroll={{ y: 550 }} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default GetAllAribill;
