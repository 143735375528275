import React from 'react';
import dayjs from 'dayjs';
import {Table, Empty, Button, Typography, Tag} from 'antd';
import {BadgeRenderer} from './BadgeRenderer';
import {
    CarryOutOutlined, CloseCircleOutlined, CheckCircleOutlined, WarningOutlined, EyeOutlined
} from '@ant-design/icons';


export const OrderTable = ({
                               data,
                               handleRowHover,
                               hoveredRow,
                               handleDetailsClick,
                               handleWarningClick,
                               handleAcceptClick
                           }) => {


    const columns = [

        {
            title: 'CREATED AT',
            dataIndex: 'created',
            key: 'client_id',
            width: '120px',
            align: 'center',
            render: (created) => dayjs(created).format('MM/DD/YYYY')
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text) => <BadgeRenderer text={text} type="status"/>
        },

        {
            title: 'QTY',
            dataIndex: 'quantity',
            key: 'quantity',
            width: '120px',
            align: 'center',
            render: (quantity) => quantity
                ? <Tag bordered={false} color={"blue"}>{quantity}</Tag>
                : <Tag bordered={false} color={"red"}>TBD</Tag>
        },

        {
            title: 'CLIENT',
            dataIndex: 'client_id',
            key: 'client_id',
            width: '100px',
            align: 'center'
        },
        {
            title: 'ROUTE',
            dataIndex: ['routeDetails', 'code'],
            key: 'route_code',
            width: '100px',
            align: 'center',
            render: (_, item) => item.routeDetails.code ? <Tag bordered={false}>
                <strong>{item.routeDetails.code}</strong>
            </Tag> : 'N/A'
        },
        {
            width: '320px',
            title: 'ACTIONS',
            key: (_, record) => `actionButton-${record.id}`,
            align: 'center',
            render: (_, record) => (
                <>
                    {record.status === 'CONFIRMED' || record.status === 'RECEIVED_WITH_ISSUE' ? (
                        <Button
                            icon={<EyeOutlined/>}
                            type={'default'}
                            onClick={() => handleDetailsClick(record)}
                        >
                            Details
                        </Button>
                    ) : (
                        <>
                            <Button
                                type={'primary'}
                                icon={<CarryOutOutlined/>}
                                onClick={() => handleAcceptClick(record)}
                            >
                                Accept order
                            </Button>

                            <Button
                                size={'small'}
                                danger
                                type={'dashed'}
                                icon={<WarningOutlined/>}
                                onClick={() => handleWarningClick(record)}
                                style={{marginLeft: 8}}
                            >
                                Warning
                            </Button>

                        </>
                    )}

                </>
            ),
        },
        {
            title: 'PICKED UP',
            dataIndex: 'boxIsEmpty',
            key: 'boxIsEmpty',
            align: 'center',
            render: (boxIsEmpty) => !boxIsEmpty ? <CheckCircleOutlined style={{color: 'green'}}/> :
                <CloseCircleOutlined style={{color: 'red'}}/>,
            width: '100px'
        },
        {
            title: 'ICEPACK',
            dataIndex: 'icePackInsideBox',
            key: 'icePackInsideBox',
            align: 'center',
            render: (icePackInsideBox) => icePackInsideBox ? <CheckCircleOutlined style={{color: 'green'}}/> :
                <CloseCircleOutlined style={{color: 'red'}}/>,
            width: '100px'
        },
        {
            title: 'COMMENTS',
            dataIndex: 'notes',
            key: 'notes',
            align: 'left',
            render: (_, item) => item?.notes ? item?.notes : 'No comments'
        },
        {
            title: 'PICKED UP BY',
            dataIndex: 'driverDetails',
            key: 'driver',
            align: 'left',
            render: (driverDetails) => <>
                <Typography.Paragraph style={{margin: 0}}>
                    {driverDetails.first_name !== null ? `${driverDetails.first_name} ${driverDetails.last_name}` : 'N/A'}
                </Typography.Paragraph>

                <Typography.Text type={'secondary'}>
                    {driverDetails.phone !== null ? `${driverDetails.phone}` : 'N/A'}
                </Typography.Text>
            </>,
            width: '200px'
        },
        {
            title: 'HANDED OVER TO',
            dataIndex: 'handedOverTo',
            key: 'handedOverTo',
            align: 'center',
            render: handedOverTo => <Typography.Text>{handedOverTo}</Typography.Text>
        },

        {
            title: 'CLIENT DETAILS',
            key: 'clientDetails',
            align: 'left',
            render: (text, record) => record.clientDetails ? (
                <>
                    <Typography.Paragraph style={{margin: 0}}>
                        {record.clientDetails.name}
                    </Typography.Paragraph>
                    <Typography.Text type={'secondary'}>
                        {record.clientDetails.street ? `${record.clientDetails.street}, ` : ''}
                        {record.clientDetails.state ? `${record.clientDetails.state}, ` : ''}
                        {record.clientDetails.zip ? `${record.clientDetails.zip}` : ''}
                    </Typography.Text>
                </>
            ) : 'N/A'
        },
    ];


    return (
        data.length > 0 ? (
            <Table
                scroll={{x: 1000}}
                columns={columns}
                dataSource={data}
                bordered
                size='large'
                onRow={(record) => ({
                    onMouseEnter: () => handleRowHover(record),
                    onMouseLeave: () => handleRowHover(null),
                    style: {cursor: record === hoveredRow ? 'pointer' : 'default'},
                })}
                pagination={false}
            />
        ) : (
            <Empty description="No Data"/>
        )
    )
};
