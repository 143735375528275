import React, {useState, useEffect, useContext} from "react";
import {Table, Button} from 'antd';
import {AuthContext} from "../AuthContext";
import Loading from "../Loading";
import restAPI from "../../restAPI";
import MapModal from "./MapModal";
import {NavLink} from "react-router-dom";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);


const DriversList = () => {
    const [loading, setLoading] = useState(false);
    const {authConfig, socket} = useContext(AuthContext);
    const [drivers, setDrivers] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);

    // Get the user's local timezone
    const userTimeZone = dayjs.tz.guess();

    const getDrivers = async () => {
        try {
            setLoading(true);
            const response = await restAPI.get('/driver/drivers-list/', authConfig);
            setDrivers(response.data.drivers);
        } catch (err) {
            console.log("Error while fetching drivers", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDrivers();

        if (socket) {
            const handleStatusUpdate = (updatedDriver) => {
                setDrivers((prevDrivers) =>
                    prevDrivers.map(driver =>
                        driver.id === updatedDriver.driverId ? {...driver, status: updatedDriver.status} : driver
                    )
                );
            };

            const handleLocationUpdate = (locationUpdate) => {
                setDrivers((prevDrivers) =>
                    prevDrivers.map(driver =>
                        driver.id === locationUpdate.driverId ? {...driver, location: locationUpdate.location} : driver
                    )
                );
            };

            socket.on('driver-status-updated', handleStatusUpdate);
            socket.on('driver-location-updated', handleLocationUpdate);

            return () => {
                socket.off('driver-status-updated', handleStatusUpdate);
                socket.off('driver-location-updated', handleLocationUpdate);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, authConfig]);

    const handleShowLocation = (driver) => {
        if (driver.location && driver.location.latitude && driver.location.longitude) {
            setSelectedDriver(driver);
            setModalVisible(true);
        } else {
            console.log('Driver location not available:', driver);
        }
    };

    const sortedDrivers = [...drivers].sort((a, b) => {
        if (a.status === 'online' && b.status !== 'online') {
            return -1;
        }
        if (a.status !== 'online' && b.status === 'online') {
            return 1;
        }
        return 0;
    });


    const columns = [
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
            render: (text, record) => <NavLink to={`/driver-details/${record.id}`}>
                {record.first_name} {record.last_name}
            </NavLink>
        },

        {
            title: 'Last login',
            dataIndex: 'last_login',
            key: 'last_login',
            render: lastLogin => lastLogin || "Never",
            sorter: (a, b) => a.last_login.localeCompare(b.last_login),
        },

        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => <span style={{color: status === 'online' ? 'green' : 'red'}}>{status}</span>
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button
                    type="primary"
                    onClick={() => handleShowLocation(record)}
                    disabled={!(record.status === 'online' && record.location && record.location.latitude && record.location.longitude)}
                >
                    Show Location
                </Button>
            )
        },
    ];

    const preparedDataSource = sortedDrivers.map((user) => ({
        ...user,
        last_login: user.last_login ? dayjs.utc(user.last_login).tz(userTimeZone).format('MMMM DD, YYYY hh:mm A') : "Never",
    }));

    return (
        <Loading loading={loading}>
            <Table
                dataSource={preparedDataSource}
                columns={columns}
                rowKey="id"
                pagination={false}
            />
            {selectedDriver && (
                <MapModal
                    visible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    driverLocation={selectedDriver.location}
                    driverId={selectedDriver.id}
                    socket={socket}
                />
            )}
        </Loading>
    );
};

export default DriversList;
