import dayjs from 'dayjs';
import api from '../../../restAPI';
import {AuthContext} from '../../../components/AuthContext';
import {useReactToPrint} from 'react-to-print';
import styled from 'styled-components';
import {FilePdfOutlined} from '@ant-design/icons';
import React, {useState, useContext, useEffect, useCallback, useRef} from 'react';
import {Table, Row, Col, Spin, Typography, Select, Tag, Button, Divider} from 'antd';
import {NavLink} from "react-router-dom";

const {Option} = Select;

const PageBreak = styled.div`
    page-break-after: always;
`;

const columns = [
    {
        title: 'Route',
        dataIndex: ['routeDetails', 'code'],
        key: 'route_code',
        width: '20px',
        align: 'left'
    },
    {
        title: 'Client ID',
        dataIndex: ['clientDetails', 'id'],
        key: 'client_id',
        width: '20px',
        align: 'left'
    },
    {
        title: 'Client Name',
        dataIndex: ['clientDetails', 'name'],
        key: 'name',
        width: '150px',
        align: 'left'
    },
    {
        title: 'Client Address',
        dataIndex: ['clientDetails', 'street'],
        key: 'street',
        width: '170px',
        align: 'left',
        render: (text, record) => {
            return <>{record?.clientDetails?.street}, {record?.clientDetails?.city}</>;
        }
    },
    {
        title: 'ZIP',
        dataIndex: ['clientDetails', 'zip'],
        key: 'street',
        width: '30px',
        align: 'left'
    },
    {
        title: 'Notes',
        dataIndex: ['clientScheduleDetails', 'details_notes'],
        key: 'notes',
        width: '250px',
        align: 'left'
    },
    {
        title: 'SPM#',
        key: 'spm',
        width: '50px',
        align: 'left'
    },
    {
        title: 'ECL#',
        key: 'ecl',
        width: '50px',
        align: 'left'
    },
    {
        title: 'Time',
        key: 'time',
        width: '80px',
        align: 'left'
    },
];

const DriversSchedule = () => {
    const [data, setData] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [apiDataInResponse, setApiDataInResponse] = useState(null);
    const {token} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [drivers, setDrivers] = useState([]);
    const componentRef = useRef();

    const fetchDrivers = useCallback(async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await api.get('/driver', config);
            setDrivers(response.data.driver);
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    const fetchData = useCallback(async () => {
        if (!selectedDriver) return;

        try {
            setIsLoading(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await api.get(`/orders/todays-driver-orders/${selectedDriver.id}/scheduled/`, config);
            setApiDataInResponse(response.data);
            setData(response.data.items);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }, [token, selectedDriver]);

    const filterOption = (input, option) => {
        const driverData = drivers.find((driver) => driver.id === option.value);
        if (!driverData) return false;
        const driverName = `${driverData.first_name} ${driverData.last_name}`.toLowerCase();
        const driverId = `ID:${driverData.id}`.toLowerCase();
        return driverName.includes(input.toLowerCase()) || driverId.includes(input.toLowerCase());
    };

    const filteredData = data;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Driver Schedule',
        pageStyle: `
@page {
    size: Letter landscape;
    margin: 10mm;
}
        @media print {
   body {
        -webkit-print-color-adjust: exact;
        margin: 0;
    }
    .print-container {
        font-size: 11px;
        color: black;
        margin: 10mm;
    }

    .print-container .ant-table {
        border-collapse: collapse !important;
    }

    .print-container .ant-table th, .print-container .ant-table td {
        padding: 4px !important;
        border: 1px solid #ddd !important;
    }

    .print-container .ant-table th {
        background-color: #f2f2f2 !important;
    }
        .print-container .title {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 20px;
        }
        .print-container .total-stops,
        .print-container .date-of-print {
            margin-top: 0;
            font-size: 12px;
        }
    }
`,
    });


    useEffect(() => {
        fetchDrivers();
        if (selectedDriver) {
            fetchData();
        }
    }, [fetchData, fetchDrivers, selectedDriver]);

    const totalStops = filteredData.length;
    const currentDate = dayjs().format('MM/DD/YYYY hh:mm A');


    const routeCodes = [...new Set(filteredData.map(item => item.routeDetails.code))];

    const tables = routeCodes.map((routeCode, index) => {
        const dataForRoute = filteredData.filter(item => item.routeDetails.code === routeCode);

        return (
            <div key={index}>

                <Typography.Title level={5} align={'left'}>
                    {(selectedDriver?.first_name || 'No driver selected')} {(selectedDriver?.last_name || '')} -
                    Schedule for Route: <Tag>{routeCode}</Tag> {`${dayjs().format('MM/DD/YYYY')}`}

                </Typography.Title>

                <Row>
                    <Col span={6}>
                        <Typography.Paragraph align={'left'} className="total-stops">
                            <strong>Driver phone:</strong> {selectedDriver?.phone || 'N/A'}
                        </Typography.Paragraph>
                    </Col>

                    <Col span={4}>
                        <Typography.Paragraph align={'left'} className="total-stops">
                            <strong>Total Stops:</strong> {dataForRoute.length}
                        </Typography.Paragraph>
                    </Col>

                    <Col span={6}>
                        <Typography.Paragraph align={'left'} className="date-of-print">
                            <strong>Date of Print:</strong> {currentDate}
                        </Typography.Paragraph>
                    </Col>

                    <Col span={6}>
                        <Typography.Paragraph align={'left'} className="date-of-print">
                            <strong>Received time:</strong> ___________________
                        </Typography.Paragraph>
                    </Col>
                </Row>

                <Table
                    id={`pdfTable_${routeCode}`}
                    columns={columns}
                    dataSource={dataForRoute}
                    bordered
                    size="small"
                    className="rowHighlight"
                    pagination={false}
                />
                {index !== routeCodes.length - 1 && <PageBreak/>}
            </div>
        );
    });

    return (
        // <div className="main-content" style={{margin: '16px', maxHeight: 'calc(100vh - 11vh)', overflowY: 'auto'}}>
        <Row justify="center" align="middle">
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Row justify={'center'}>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Typography.Title level={2} className="mainLabel">
                            Driver's Schedule
                        </Typography.Title>
                        <Typography.Paragraph type="secondary">
                            Each day the system will generate a list of stops for each driver. Please select a driver to
                            view their schedule for today.
                            <br/> If you need to predict the driver's schedule for different day, please use the &nbsp;
                            <NavLink to={'/predict-schedule'}>"Predict Schedule"</NavLink> feature.


                        </Typography.Paragraph>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{width: 300}}
                            placeholder="Select a driver"
                            onChange={(value) => setSelectedDriver(drivers.find(d => d.id === value))}
                        >
                            {Array.isArray(drivers) && drivers.map((driver) => (
                                <Option key={driver.id} value={driver.id}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Tag color="magenta">ID:{driver.id}</Tag>
                                        <span
                                            style={{marginLeft: '4px'}}>{driver.first_name} {driver.last_name}</span>
                                    </div>
                                </Option>
                            ))}
                        </Select>

                        {filteredData.length > 0 && (
                            <Button
                                type="primary"
                                onClick={handlePrint}
                                danger
                                style={{marginLeft: "16px"}}
                                icon={<FilePdfOutlined style={{margin: '0px 6px 0px -6px'}}/>}
                            >
                                PDF
                            </Button>
                        )}
                    </Col>

                    {selectedDriver && (
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{marginTop: '20px'}}>
                            <Spin spinning={isLoading} tip="Loading..." size="large">

                                {/*<pre>*/}
                                {/*    {JSON.stringify(apiDataInResponse, null, 2)}*/}
                                {/*</pre>*/}

                                {/*<pre>*/}
                                {/*    {JSON.stringify(data, null, 2)}*/}
                                {/*</pre>*/}


                                <TableContainer ref={componentRef} className="print-container">


                                    {/*<Table*/}
                                    {/*    id="pdfTable"*/}
                                    {/*    columns={columns}*/}
                                    {/*    dataSource={filteredData}*/}
                                    {/*    bordered*/}
                                    {/*    size="small"*/}
                                    {/*    className="rowHighlight"*/}
                                    {/*    pagination={false}*/}
                                    {/*/>*/}

                                    {tables}

                                    {tables.length > 1 && (
                                        <>
                                            <Divider/>

                                            <Typography.Title level={5} align={'left'} className="total-stops">
                                                <Tag>Total stops for {dayjs().format('MM/DD/YYYY')}:</Tag> {totalStops}
                                            </Typography.Title>

                                            <Typography.Title level={5} align={'left'} className="date-of-print">
                                                <Tag>Driver:</Tag> {(selectedDriver?.first_name || 'No driver selected')} {(selectedDriver?.last_name || '')}
                                            </Typography.Title>
                                        </>
                                    )}


                                </TableContainer>

                            </Spin>
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
        // </div>
    );
};

export default DriversSchedule;

const TableContainer = styled.div`
    font-family: Arial, sans-serif;
    font-size: 9px !important;
    color: black;

    .ant-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 9px;
        font-size: 9px !important;
        border-radius: 0 !important;
    }

    .ant-table th, .ant-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    .ant-table th {
        background-color: #f2f2f2;
    }
`;
