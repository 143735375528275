import React from "react";
import {Routes, Route} from "react-router-dom";
import {App} from 'antd';
import AppLayout from "./Layout";
import Login from "./pages/Common/Login";
import Dashboard from "./pages/Dispatcher/Dashboard";
import AllStops from "./pages/Dispatcher/All Stops";
import DriversSchedule from "./pages/Dispatcher/Drivers Schedule";
import RoutesMaintenance from "./pages/Dispatcher/Routes Maintenance";
import DriversMaintenance from "./pages/Dispatcher/DriversMaintenance";
import ClientSchedule from "./pages/Dispatcher/ClientSchedule";
import ClientDetails from "./pages/Dispatcher/ClientSchedule/ClientDetails";
import DriverModalWindow from "./pages/Dispatcher/Dashboard/DriverModalWindow";
import MySchedule from "./pages/Driver/My Schedule";
import MyAccount from "./pages/Driver/My Account";
import Fedex from "./pages/Dispatcher/Fedex";
import AirbillDispatcher from "./pages/Dispatcher/Airbill";
import Airbill from "./pages/Driver Charter/Airbill";
import {ClientProvider} from "./pages/Dispatcher/ClientSchedule/ClientContext";
import Reports from "./pages/Dispatcher/Reports";
import NewPickUp from "./pages/Dispatcher/New Pick-Up";
import RegisterSuperUser from "./pages/Dispatcher/Registration/RegisterSuperUser";
import UsersList from "./pages/Dispatcher/Users";
import {AuthRequired} from "./components/AuthRequired";
import LogisticUpdates from "./pages/Logistic/Updates";
import UsersManagement from "./pages/Management/Users";
import ClientScheduleDetails from "./pages/Dispatcher/ClientSchedule/ClientScheduleDetails";
import PredictSchedule from "./pages/Dispatcher/PredictSchedule";
import PayAttentionPage from "./pages/Dispatcher/PayAttentionPage";

// Receivers components
import Receiver from "./pages/Receiver";
import ReceiverDashboard from "./pages/Receiver/ReceiverDashboard";
import DriverDetailsPage from "./pages/Dispatcher/DriverDetailsPage";
import RoutesManagement from "./pages/Dispatcher/RoutesManagement";
import RouteFullDetails from "./pages/Dispatcher/RoutesManagement/RouteFullDetails";

export default function Main() {
    return (
        <App>
            <ClientProvider>
                <Routes>
                    <Route path="login" element={<Login/>}/>
                    <Route path="/" element={<AuthRequired><AppLayout/></AuthRequired>}>
                        <Route path="dashboard" element={<AuthRequired> <Dashboard/> </AuthRequired>}/>
                        <Route path="drivers-schedule" element={<AuthRequired> <DriversSchedule/> </AuthRequired>}/>
                        <Route path="all-stops" element={<AuthRequired> <AllStops/> </AuthRequired>}/>
                        <Route path="active-drivers/:id" element={<AuthRequired> <DriverModalWindow/> </AuthRequired>}/>

                        <Route path="routes-maintenance" element={
                            <AuthRequired>
                                <RoutesMaintenance/>
                            </AuthRequired>
                        }/>

                        <Route path="drivers-maintenance" element={<AuthRequired>
                            <DriversMaintenance/>
                        </AuthRequired>}/>

                        <Route
                            path="driver-details/:driverId"
                            element={<AuthRequired>
                                <DriverDetailsPage/>
                            </AuthRequired>}
                        />

                        <Route path={"routes-management"} element={<AuthRequired><RoutesManagement/></AuthRequired>}/>
                        <Route
                            path={"routes-management/:routeUuid"}
                            element={<AuthRequired><RouteFullDetails/></AuthRequired>}
                        />

                        <Route path="client-schedule" element={<AuthRequired> <ClientSchedule/> </AuthRequired>}/>
                        <Route path="client-schedule/:id" element={<AuthRequired> <ClientDetails/> </AuthRequired>}/>

                        <Route
                            path="client-schedule-detail/:clientId"
                            element={<AuthRequired>
                                <ClientScheduleDetails/>
                            </AuthRequired>}
                        />

                        <Route path="reports" element={<AuthRequired> <Reports/> </AuthRequired>}/>
                        <Route path="new-pick-up" element={<AuthRequired> <NewPickUp/> </AuthRequired>}/>
                        <Route path="register" element={<AuthRequired> <RegisterSuperUser/> </AuthRequired>}/>
                        <Route path="users" element={<AuthRequired> <UsersList/> </AuthRequired>}/>
                        <Route path="driver-schedule" element={<AuthRequired> <MySchedule/> </AuthRequired>}/>
                        <Route path="driver-account" element={<AuthRequired> <MyAccount/> </AuthRequired>}/>
                        <Route path="fedex" element={<AuthRequired> <Fedex/> </AuthRequired>}/>
                        <Route path="airbill-dispatcher" element={<AuthRequired> <AirbillDispatcher/> </AuthRequired>}/>
                        <Route path="airbill" element={<AuthRequired> <Airbill/> </AuthRequired>}/>
                        <Route path="logistic-updates" element={<AuthRequired> <LogisticUpdates/> </AuthRequired>}/>

                        <Route path="predict-schedule" element={<AuthRequired>
                            <PredictSchedule/>
                        </AuthRequired>}/>

                        <Route path="pay-attention-page" element={<AuthRequired>
                            <PayAttentionPage/>
                        </AuthRequired>}/>

                        <Route path="/management/users" element={<UsersManagement/>}/>

                        <Route path="receiver" element={<AuthRequired><Receiver/></AuthRequired>}/>
                        <Route path="receiver/dashboard" element={<AuthRequired><ReceiverDashboard/></AuthRequired>}/>

                        <Route path="*" element={<NoMatch/>}/>
                    </Route>
                </Routes>
            </ClientProvider>
        </App>
    );
}

function NoMatch() {
    return <h1>404</h1>;
}
