// import './WeeklyScheduleTimeLine.css';
import '../WeeklySchedule.css';
import React, {useState, useEffect, useMemo} from 'react';
import api from '../../../../restAPI';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import randomColor from 'randomcolor';
import {Spin, Modal, message, Typography} from 'antd';
import ButtonGroup from './ButtonGroup';
import ScrollSyncContainer from './ScrollSyncContainer';
import DayCard from './DayCard';

import CreateSchedule from './CreateSchedule';

dayjs.extend(isBetween);
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',];

const CustomModal = ({title, visible, onClose, content, width = 1800}) => {
    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onClose}
            footer={null}
            width={width}
            maskClosable={false} // Prevent modal from closing on mask click
        >
            {content}
        </Modal>
    )
};

const WeeklyScheduleComponent = ({token, client_id}) => {

    // ---
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedModalContent, setSelectedModalContent] = useState(null);

    const handleSettingClick = (day = null) => {
        setSelectedModalContent(<CreateSchedule
            onSubmit={handleScheduleSubmit}
            clientID={client_id}
            day={day}
            refreshScheduleBoard={refreshScheduleBoard}
        />);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedModalContent(null);
    };

    const handleScheduleSubmit = async (newSchedule) => {
        // message.success(JSON.stringify(newSchedule));
        setIsModalVisible(false);
    };

    const [editMode, setEditMode] = useState(false);
    const [schedules, setSchedules] = useState([]);
    const [loading, setLoading] = useState(false);

    const editModeHandler = () => {
        setEditMode((prevMode) => !prevMode);
    };

    const fetchSchedules = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/client-schedule/?clientID=${client_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setSchedules(response.data);
        } catch (error) {
            message.error('Failed to load schedules from the server');
        } finally {
            setLoading(false);
        }
    };

    const refreshScheduleBoard = () => {
        fetchSchedules();
    };


    const removeScheduleItemHandler = async (scheduleItemUuid) => {
        setLoading(true);

        try {
            const response = await api.delete(`/client-schedule/client-schedule-days/${scheduleItemUuid}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Check if the status code is 204 (No Content)
            if (response.status === 204) {
                try {
                    setSchedules((prevSchedules) => {
                        return prevSchedules.map((schedule) => {
                            return {
                                ...schedule,
                                days: schedule.days.filter(d => d.client_schedule_day_id !== scheduleItemUuid)
                            };
                        });
                    });

                    message.success(`Removed schedule UUID: ${scheduleItemUuid} for Client ID: ${client_id}`);
                } catch (error) {
                    message.error('Failed to update state');
                }
            } else {
                message.error('Failed to delete schedule from the server');
            }
        } catch (error) {
            message.error('Failed to load schedules from the server');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSchedules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client_id, token]);

    const getSchedulesByDay = useMemo(() => {
        const schedulesByDay = {};
        daysOfWeek.forEach((day) => {
            const filteredSchedules = schedules
                .filter((schedule) => schedule.days.some(d => d.day === day))
                .sort((a, b) => (dayjs(a.open, 'HH:mm').isBefore(dayjs(b.open, 'HH:mm')) ? -1 : 1));
            schedulesByDay[day] = filteredSchedules;
        });
        return schedulesByDay;
    }, [schedules]);

    const generateColor = (id) =>
        randomColor({
            seed: id,
            luminosity: 'dark',
            format: 'rgba',
            alpha: 1,
        });

    const isCurrentTimeRange = (open, close) => {
        const now = dayjs();
        return now.isBetween(dayjs(open, 'HH:mm'), dayjs(close, 'HH:mm'));
    };

    const getDriverInitials = (name) => {
        const [firstName = '', lastName = ''] = name.split(' ');

        if (!firstName && !lastName) {
            return 'N/A'; // Return 'N/A' or any default value when both names are missing
        }

        const firstInitial = firstName ? firstName[0] : '?';
        const lastInitial = lastName ? lastName[0] : '?';

        return `${firstInitial}${lastInitial}`;
    };

    const refreshOrdersDependedOnClientScheduleByClientId = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/client-schedule/process-updated-client-schedule/${client_id}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                message.success('Successfully processed updated client schedules');
                message.success(`Total orders today: ${response.data?.totalOrdersToday}`);
            } else {
                message.error('Failed to process updated client schedules');
            }
        } catch (error) {
            console.log('Error while refreshing schedule', error);
            message.error('Failed to process updated client schedules');
        }
        setLoading(false);
    };

    return (
        <>

            <ButtonGroup
                refreshOrdersDependedOnClientScheduleByClientId={refreshOrdersDependedOnClientScheduleByClientId}
                editModeHandler={editModeHandler}
                editMode={editMode}
                handleSettingClick={handleSettingClick}
            />

            <Typography.Title level={3} align="center" style={{marginTop: 30}}>
                Weekly Schedule for Client ID: {client_id}
            </Typography.Title>

            <ScrollSyncContainer>
                {loading ? (
                    <Spin size="large" fullscreen={true}/>
                ) : (
                    <div className="schedule-container">
                        {daysOfWeek.map((day) => (
                            <DayCard
                                key={day}
                                day={day}
                                schedules={getSchedulesByDay[day]}
                                editMode={editMode}
                                generateColor={generateColor}
                                isCurrentTimeRange={isCurrentTimeRange}
                                getDriverInitials={getDriverInitials}
                                removeScheduleItemHandler={removeScheduleItemHandler}
                                handleSettingClick={handleSettingClick}
                            />
                        ))}
                    </div>
                )}
            </ScrollSyncContainer>

            <CustomModal
                width={'40%'}
                title={<Typography.Title align={'center'} level={3}>
                    Create Schedule for Client ID: {client_id}
                </Typography.Title>}
                visible={isModalVisible}
                onClose={handleModalClose}
                content={selectedModalContent}
                style={{position: 'relative', zIndex: 9999}}
            />

        </>
    );
};

export default WeeklyScheduleComponent;
