import React, {useContext, useEffect, useState} from "react";
import {
    Row, Col, Typography, DatePicker, List, Card, Descriptions, Tag, Collapse, Spin, Tabs, Badge, Button, Divider,
    Popconfirm, Modal
} from 'antd';
import {useParams, useLocation, useNavigate, NavLink} from "react-router-dom";
import {
    DownOutlined, UpOutlined, DeleteOutlined, EditOutlined, UserSwitchOutlined
} from '@ant-design/icons';
import dayjs from "dayjs";
import restAPI from "../../../restAPI";
import {AuthContext} from "../../../components/AuthContext";
import PhotoDisplayingComponent from "./PhotoDisplayingComponent";

const {Panel} = Collapse;
const {TabPane} = Tabs;

const DriverDetailsPage = () => {
    const {driverId} = useParams();
    const {token} = useContext(AuthContext);
    const [driverData, setDriverData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeRouteId, setActiveRouteId] = useState('all');
    const location = useLocation();
    const navigate = useNavigate();

    const query = new URLSearchParams(location.search);
    const dateFromQuery = query.get("date") ? dayjs(query.get("date")) : dayjs();
    const [selectedDate, setSelectedDate] = useState(dateFromQuery);

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    useEffect(() => {
        const fetchDriverData = async () => {
            setLoading(true);
            try {
                const response = await restAPI.get(`/driver/details/${driverId}/?date=${selectedDate.format('MM-DD-YYYY')}`, config);
                setDriverData(response.data.data);
                console.log('Driver details:', response.data);
            } catch (error) {
                console.error('Error fetching driver details:', error.response.data);
            } finally {
                setLoading(false);
            }
        };

        fetchDriverData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driverId, selectedDate]);

    const deleteOrderById = async neededOrderId => {
        setLoading(true);
        try {
            await restAPI.delete(`/orders/${neededOrderId}/`, config);
            setDriverData(driverData.filter(order => order.id !== neededOrderId));
            Modal.info({
                title: 'Order deleted',
                content: <Typography.Paragraph>Order has been successfully deleted.</Typography.Paragraph>
            });
        } catch (error) {
            console.error('Error deleting order:', error.response);
        } finally {
            setLoading(false);
        }
    };


    const handleDateChange = (date) => {
        setSelectedDate(date);
        navigate(`?date=${date.format('MM-DD-YYYY')}`);
    };

    const handleTabChange = (key) => {
        setActiveRouteId(key);
    };

    const renderPanelHeader = (item) => (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <div>
                <Typography.Text
                    strong>{item.client_name}</Typography.Text> - {`${item.client_street}, ${item.client_city}, ${item.client_state}, ${item.client_zip}`}
            </div>
            <div>
                <Tag color={item.status === 'active' ? 'green' : 'red'}>{item.status}</Tag>
                <Typography.Text>{dayjs(item.created).format('MM/DD/YYYY')}</Typography.Text>
            </div>
        </div>
    );

    const uniqueRoutes = driverData.reduce((acc, item) => {
        if (!acc.some(route => route.route_id === item.route_id)) {
            acc.push({route_id: item.route_id, route_code: item.route_code});
        }
        return acc;
    }, []);

    const filteredData = activeRouteId === 'all' ? driverData : driverData.filter(item => item.route_id === activeRouteId);

    return (
        <Spin spinning={loading} size="large" tip="Loading..." style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999
        }}>
            <Row justify="center" style={{marginTop: 20, padding: '0 16px'}}>
                <Col span={24} style={{maxWidth: '1200px'}}>
                    <Typography.Title level={4}>
                        Driver Details:
                        for {driverData[0]?.driver_first_name} {driverData[0]?.driver_last_name} (ID:{driverId})
                    </Typography.Title>
                </Col>
                <Col span={24} style={{maxWidth: '1200px'}}>
                    <DatePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                        format="MM-DD-YYYY"
                    />
                </Col>
                <Col span={24} style={{maxWidth: '1200px'}}>
                    <Tabs defaultActiveKey="all" onChange={handleTabChange} style={{userSelect: 'none'}}>
                        <TabPane
                            tab={
                                <Badge size="small" count={driverData.length} offset={[13, 0]} status={'default'}>
                                    All stops
                                </Badge>
                            }
                            key="all"
                        >
                            <List
                                grid={{gutter: 16, column: 1}}
                                dataSource={filteredData}
                                renderItem={item => (
                                    <List.Item>
                                        <Card
                                            bordered={false}
                                            style={{marginBottom: 16}}
                                        >
                                            <Collapse
                                                bordered={false}
                                                expandIcon={({isActive}) => isActive ? <UpOutlined/> : <DownOutlined/>}
                                            >
                                                <Panel header={renderPanelHeader(item)} key={item.client_id}
                                                       style={{userSelect: 'none'}}>
                                                    <Descriptions column={1} size="small">
                                                        <Descriptions.Item
                                                            label="Client ID">{item.client_id}</Descriptions.Item>
                                                        <Descriptions.Item
                                                            label="Phone">{item.client_phone}</Descriptions.Item>
                                                        <Descriptions.Item
                                                            label="Sales Group">{item.client_sales_group}</Descriptions.Item>
                                                        <Descriptions.Item
                                                            label="Created">{dayjs(item.created).format('MM/DD/YYYY')}</Descriptions.Item>
                                                        <Descriptions.Item label="Type">{item.type}</Descriptions.Item>

                                                        {item.type === 'SCHEDULED' && (
                                                            <Descriptions.Item label="Generated via">
                                                                <NavLink
                                                                    to={`/client-schedule-detail/${item.client_id}`}>
                                                                    Client Schedule
                                                                </NavLink>
                                                            </Descriptions.Item>
                                                        )}

                                                        <Divider dashed={true}/>

                                                        <Descriptions.Item>
                                                            <Button.Group>
                                                                <Button
                                                                    disabled={true}
                                                                    type={'text'} size={'small'}
                                                                    icon={<UserSwitchOutlined/>}
                                                                >
                                                                    Transfer to another driver
                                                                </Button>
                                                                <Button
                                                                    disabled={true}
                                                                    type={'text'} size={'small'}
                                                                    icon={<EditOutlined/>}
                                                                >
                                                                    Edit stop
                                                                </Button>
                                                                <Popconfirm
                                                                    title="Are you sure you want to delete this stop?"
                                                                    onConfirm={async () => await deleteOrderById(item.id)}
                                                                    onCancel={() => console.log('Cancelled')}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Button
                                                                        danger type={'text'} size={'small'}
                                                                        icon={<DeleteOutlined/>}
                                                                    >
                                                                        Delete stop
                                                                    </Button>
                                                                </Popconfirm>
                                                            </Button.Group>
                                                        </Descriptions.Item>
                                                    </Descriptions>

                                                    <PhotoDisplayingComponent orderId={item.id}
                                                                              token={token}
                                                    />

                                                </Panel>
                                            </Collapse>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </TabPane>

                        {uniqueRoutes.map(route => (
                            <TabPane
                                tab={
                                    <Badge
                                        offset={[15, 0]}
                                        size="small"
                                        count={driverData.filter(item => item.route_id === route.route_id).length}>
                                        {`${route.route_code || `On Call's`}`}
                                    </Badge>}
                                key={route.route_id}
                            >
                                <List
                                    grid={{gutter: 16, column: 1}}
                                    dataSource={driverData.filter(item => item.route_id === route.route_id)}
                                    renderItem={item => (
                                        <List.Item>
                                            <Card
                                                bordered={false}
                                                style={{marginBottom: 16}}
                                            >
                                                <Collapse
                                                    bordered={false}
                                                    expandIcon={({isActive}) => isActive ? <UpOutlined/> :
                                                        <DownOutlined/>}
                                                >
                                                    <Panel header={renderPanelHeader(item)} key={item.client_id}>
                                                        <Descriptions column={1} size="small">
                                                            <Descriptions.Item
                                                                label="Client ID">{item.client_id}</Descriptions.Item>
                                                            <Descriptions.Item
                                                                label="Phone">{item.client_phone}</Descriptions.Item>
                                                            <Descriptions.Item
                                                                label="Sales Group">{item.client_sales_group}</Descriptions.Item>
                                                            <Descriptions.Item
                                                                label="Created">{dayjs(item.created).format('MM/DD/YYYY')}</Descriptions.Item>
                                                            <Descriptions.Item
                                                                label="Type">{item.type}</Descriptions.Item>

                                                            {item.type === 'SCHEDULED' && (
                                                                <Descriptions.Item label="Generated via">
                                                                    <NavLink
                                                                        to={`/client-schedule-detail/${item.client_id}`}>
                                                                        Client Schedule
                                                                    </NavLink>
                                                                </Descriptions.Item>
                                                            )}

                                                            <Divider dashed={true}/>

                                                            <Descriptions.Item>
                                                                <Button.Group>
                                                                    <Button
                                                                        disabled={true}
                                                                        type={'text'} size={'small'}
                                                                        icon={<UserSwitchOutlined/>}
                                                                    >
                                                                        Transfer to another driver
                                                                    </Button>
                                                                    <Button
                                                                        disabled={true}
                                                                        type={'text'} size={'small'}
                                                                        icon={<EditOutlined/>}
                                                                    >
                                                                        Edit stop
                                                                    </Button>
                                                                    <Popconfirm
                                                                        title="Are you sure you want to delete this stop?"
                                                                        onConfirm={async () => await deleteOrderById(item.id)}
                                                                        onCancel={() => console.log('Cancelled')}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button
                                                                            danger type={'text'} size={'small'}
                                                                            icon={<DeleteOutlined/>}
                                                                        >
                                                                            Delete stop
                                                                        </Button>
                                                                    </Popconfirm>
                                                                </Button.Group>
                                                            </Descriptions.Item>
                                                        </Descriptions>


                                                        <PhotoDisplayingComponent orderId={item.id}
                                                                                  token={token}
                                                        />

                                                    </Panel>
                                                </Collapse>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </TabPane>
                        ))}
                    </Tabs>
                </Col>
            </Row>
        </Spin>
    );
}

export default DriverDetailsPage;
