import React, {useContext, useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {ClientContext} from './ClientContext';
import {Row, Col, Typography, Card, Descriptions, Modal, Badge, Spin, List, Popconfirm} from 'antd';
import {EditOutlined, EllipsisOutlined, PlusOutlined, DeleteOutlined} from '@ant-design/icons';
import {AuthContext} from '../../../components/AuthContext';
import api from '../../../restAPI';
import CreateSchedule from './CreateSchedule';
import UpdateSchedule from './UpdateSchedule';
import {getSelectedDaysText} from './SelectedDays';

const {Title} = Typography;

const ClientDetails = () => {
    const {id: clientID} = useParams();
    const {clientData, isLoading, setIsLoading} = useContext(ClientContext);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [schedule, setSchedule] = useState([]);
    const [selectedModalContent, setSelectedModalContent] = useState(null);
    const {token} = useContext(AuthContext);
    const scrollRef = useRef();
    const [daysOfWeek] = useState([
        {day: 'Monday', checked: false},
        {day: 'Tuesday', checked: false},
        {day: 'Wednesday', checked: false},
        {day: 'Thursday', checked: false},
        {day: 'Friday', checked: false},
        {day: 'Saturday', checked: false},
        {day: 'Sunday', checked: false},
    ]);

    const handleSettingClick = () => {
        setSelectedModalContent(<CreateSchedule onSubmit={handleScheduleSubmit} clientID={clientID}/>);
        setIsModalVisible(true);
    };

    const refreshSchedule = async () => {
        setIsLoading(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const response = await api.get(`/client-schedule/?clientID=${clientID}`, config);
            const sortedSchedule = response.data.sort((a, b) => b.is_active - a.is_active);
            setSchedule(sortedSchedule);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditClick = (schedule) => {
        setSelectedModalContent(
            <UpdateSchedule
                onSubmit={handleScheduleUpdate}
                onClose={handleModalClose}
                clientID={clientID}
                onUpdate={refreshSchedule}
                scheduleID={schedule.id}
                initialValues={{
                    days: schedule.days,
                    startTime: schedule.open,
                    endTime: schedule.close,
                    selectedRoute: schedule.route_id,
                    scheduleID: schedule.id,
                    notes: schedule.notes,
                    isActive: schedule.is_active
                }}
            />
        );
        setIsModalVisible(true);
    };


    const handleDeleteClick = async (scheduleID) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            setSchedule(prevSchedule => prevSchedule.filter(schedule => schedule.id !== scheduleID));
            await api.delete(`/client-schedule/${scheduleID}/`, config);
        } catch (error) {
            console.error(error);
        }
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedModalContent(null);
    };

    const handleScheduleSubmit = (selectedInfo) => {
        const clientID = clientData.id;
        const newScheduleData = {
            clientID,
            ...selectedInfo,
        };
        setSchedule(prevData => [...prevData, newScheduleData]);
        setIsModalVisible(false);
    };

    const handleScheduleUpdate = (updatedInfo) => {
        const updatedSchedule = schedule.map(item => {
            if (item.id === updatedInfo.id) {
                return {
                    ...item,
                    ...updatedInfo,
                };
            }
            return item;
        });
        setSchedule(updatedSchedule);
        setIsModalVisible(false);
    };

    useEffect(() => {
        const fetchSchedule = async () => {
            setIsLoading(true);
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                const response = await api.get(`/client-schedule/?clientID=${clientID}`, config);
                const sortedSchedule = response.data.sort((a, b) => b.is_active - a.is_active);
                setSchedule(sortedSchedule);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSchedule();
    }, [token, clientID, setIsLoading]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    }, [schedule]);

    if (isLoading && !clientData) {
        return <Spin spinning={isLoading} tip="Loading..."/>;
    }

    const {id, name, city, street, state, zip} = clientData;

    return (
        <Row justify="center" align="middle">
            <Col xl={22} lg={22} md={22} sm={22} xs={22}>
                <Spin spinning={isLoading} tip="Loading..." size='large'>
                    <Row justify="center" align="middle">
                        <Card
                            className='clientCard'
                            actions={[
                                <span onClick={handleSettingClick}><PlusOutlined key="setting"/> Create schedule</span>,
                                <EditOutlined key="edit"/>,
                                <EllipsisOutlined key="ellipsis"/>,
                            ]}
                        >
                            <Badge.Ribbon
                                text="Client"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: -30,
                                }}
                            >
                                <Title>{name}</Title>
                            </Badge.Ribbon>

                            <Descriptions>
                                <Descriptions.Item label="Client ID">{id}</Descriptions.Item>
                                <Descriptions.Item label="Address">{street}</Descriptions.Item>
                                <Descriptions.Item label="City">{city}</Descriptions.Item>
                                <Descriptions.Item label="State">{state}</Descriptions.Item>
                                <Descriptions.Item label="ZIP">{zip}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Row>

                    <Row justify="center" align="middle">
                        <div style={{maxHeight: '500px', overflow: 'auto'}} ref={scrollRef}>
                            <List
                                split={false}
                                dataSource={schedule}
                                renderItem={(item) => (
                                    <List.Item>
                                        <Card
                                            size='small'
                                            style={{margin: '0'}}
                                            className={`clientCard ${item.is_active ? 'active' : 'inactive'}`}
                                            actions={[
                                                <span onClick={() => handleEditClick(item, clientID)}><EditOutlined
                                                    key="edit"/> Update Schedule</span>,
                                                <Popconfirm
                                                    title="Delete the schedule"
                                                    description="Are you sure to delete this schedule?"
                                                    okText="Yes"
                                                    cancelText="No"
                                                    onConfirm={() => handleDeleteClick(item.id)}
                                                >
                                                    <span><DeleteOutlined key="delete"/> Delete Schedule</span>
                                                </Popconfirm>,
                                                <Badge
                                                    count={item.is_active ? "Active" : "Not Active"}
                                                    style={{
                                                        backgroundColor: item.is_active ? '#52c41a' : '#f5222d',
                                                    }}
                                                />
                                            ]}
                                        >
                                            <Descriptions className='desc'>
                                                <Descriptions.Item label="Days">
                                                    {getSelectedDaysText(item.days, daysOfWeek)}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Time">
                                                    {item.open}-{item.close}
                                                </Descriptions.Item>
                                                {/* <Descriptions.Item label="Route ID">
                          {item.route_id}
                        </Descriptions.Item> */}
                                                <Descriptions.Item label="Notes">
                                                    {item.notes}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Row>
                    <Modal
                        title={selectedModalContent && selectedModalContent.type === CreateSchedule ? "Create Schedule" : "Update Schedule"}
                        open={isModalVisible}
                        onCancel={handleModalClose}
                        footer={null}
                        width={1800}
                    >
                        {selectedModalContent}
                    </Modal>
                </Spin>
            </Col>
        </Row>
    );
};

export default ClientDetails;