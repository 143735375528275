// import React, {useContext, useEffect, useState, useRef} from "react";
// import {AuthContext} from "../../components/AuthContext";
// import styled from 'styled-components';
//
// const Overlay = styled.div`
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: rgba(0, 0, 0, 0.5);
//     color: white;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 1000;
// `;
//
// const GeoErrorDiv = styled.div`
//     position: fixed;
//     bottom: 0;
//     width: 100%;
//     background-color: red;
//     color: white;
//     text-align: center;
//     z-index: 1000;
//     padding: 20px 0;
// `;
// // eslint-disable-next-line
// const SocketErrorDiv = styled.div`
//     position: fixed;
//     bottom: ${({geoError}) => geoError ? "40px" : 0};
//     width: 100%;
//     background-color: orange;
//     color: white;
//     text-align: center;
//     z-index: 1000;
//     padding: 20px 0;
// `;
//
// // eslint-disable-next-line
// const SocketSuccessDiv = styled.div`
//     position: fixed;
//     bottom: ${({geoError, socketError}) => (geoError ? "40px" : socketError ? "40px" : 0)};
//     width: 100%;
//     background-color: green;
//     color: white;
//     text-align: center;
//     z-index: 1000;
//     padding: 20px 0;
// `;
//
// const withDriverLocation = (WrappedComponent) => {
//     return (props) => {
//         const {socket, userRole, handleLogout, setUserLocation} = useContext(AuthContext);
//         const [location, setLocation] = useState(null);
//         const [geoError, setGeoError] = useState(null);
//         // eslint-disable-next-line
//         const [socketError, setSocketError] = useState(null);
//         const [isWaitingForGeolocation, setIsWaitingForGeolocation] = useState(false);
//         // eslint-disable-next-line
//         const [socketSuccess, setSocketSuccess] = useState(false);
//         const locationInterval = useRef(null);
//         const isLoggedIn = useRef(true);
//
//         useEffect(() => {
//             isLoggedIn.current = true;
//             return () => {
//                 isLoggedIn.current = false;
//                 if (locationInterval.current) {
//                     clearInterval(locationInterval.current);
//                 }
//             };
//         }, []);
//
//         const handleGeoError = (error) => {
//             if (!isLoggedIn.current) return;
//             switch (error.code) {
//                 case error.PERMISSION_DENIED:
//                     setGeoError("User denied the request for Geolocation. Please allow access and try again.");
//                     handleLogout(); // Log out the user if geolocation is denied
//                     break;
//                 case error.POSITION_UNAVAILABLE:
//                     setGeoError("Location information is unavailable.");
//                     break;
//                 case error.TIMEOUT:
//                     setGeoError("The request to get user location timed out.");
//                     handleLogout(); // Log out if the user losing TIMEOUT
//                     break;
//                 default:
//                     setGeoError("An unknown error occurred while fetching the location.");
//                     break;
//             }
//         };
//
//         const getLocation = () => {
//             if (!isLoggedIn.current || userRole !== 3) return;
//             if (navigator.geolocation) {
//                 navigator.geolocation.getCurrentPosition(
//                     (position) => {
//                         if (!isLoggedIn.current) return;
//                         setIsWaitingForGeolocation(false);
//                         const location = {
//                             latitude: position.coords.latitude,
//                             longitude: position.coords.longitude,
//                         };
//                         setLocation(location);
//                         setUserLocation(location);  // Save location in AuthProvider
//                         console.log("Driver's location:", location);
//                         if (socket) {
//                             socket.emit('location-update', location);
//                             setSocketError(null); // Clear socket error on successful emission
//                         }
//                         setGeoError(null); // Clear geo error on successful location fetch
//                     },
//                     (error) => {
//                         if (!isLoggedIn.current) return;
//                         setIsWaitingForGeolocation(false);
//                         console.error("Error getting location:", error);
//                         handleGeoError(error);
//                     },
//                     {
//                         enableHighAccuracy: true,
//                         timeout: 5000,
//                         maximumAge: 0,
//                     }
//                 );
//             } else {
//                 setIsWaitingForGeolocation(false);
//                 console.error("Geolocation is not supported by this browser.");
//                 setGeoError("Geolocation is not supported by this browser.");
//             }
//         };
//
//         useEffect(() => {
//             const checkGeolocationPermission = async () => {
//                 setIsWaitingForGeolocation(true);
//                 try {
//                     const result = await navigator.permissions.query({name: 'geolocation'});
//                     if (result.state === 'granted') {
//                         setIsWaitingForGeolocation(false);
//                         getLocation();
//                         const interval = setInterval(getLocation, 5000);
//                         locationInterval.current = interval; // Save interval ID
//                     } else if (result.state === 'prompt') {
//                         getLocation();
//                     } else {
//                         setIsWaitingForGeolocation(false);
//                         setGeoError("Geolocation access denied. Please enable it in your browser settings and try again.");
//                         handleLogout(); // Log out the user if geolocation is denied
//                     }
//
//                     result.onchange = () => {
//                         if (result.state === 'granted') {
//                             setIsWaitingForGeolocation(false);
//                             getLocation();
//                             const interval = setInterval(getLocation, 5000);
//                             locationInterval.current = interval; // Save interval ID
//                             setGeoError(null);
//                         } else {
//                             setIsWaitingForGeolocation(false);
//                             setGeoError("Geolocation access denied. Please enable it in your browser settings.");
//                             handleLogout(); // Log out the user if geolocation is denied
//                             if (locationInterval.current) clearInterval(locationInterval.current);
//                         }
//                     };
//                 } catch (error) {
//                     setIsWaitingForGeolocation(false);
//                     console.error("Error checking geolocation permissions:", error);
//                     setGeoError("An error occurred while checking geolocation permissions.");
//                 }
//             };
//
//             if (userRole === 3 && socket) {
//                 checkGeolocationPermission();
//             }
//
//             return () => {
//                 if (locationInterval.current) clearInterval(locationInterval.current);
//             };
//             // eslint-disable-next-line react-hooks/exhaustive-deps
//         }, [socket, userRole]);
//
//         useEffect(() => {
//             if (socket) {
//                 socket.on('disconnect', () => {
//                     console.error("Socket connection was killed.");
//                     setSocketError("Socket connection was killed.");
//                     setSocketSuccess(false); // Set socket success to false on disconnect
//                 });
//
//                 socket.on('connect', () => {
//                     setSocketError(null); // Clear socket error on reconnect
//                     setSocketSuccess(true); // Set socket success to true on connect
//                 });
//             }
//
//             return () => {
//                 if (socket) {
//                     socket.off('disconnect');
//                     socket.off('connect');
//                 }
//             };
//         }, [socket]);
//
//         useEffect(() => {
//             if (userRole !== 3 && locationInterval.current) {
//                 clearInterval(locationInterval.current); // Clear interval on user logout
//                 locationInterval.current = null; // Reset interval state
//             }
//         }, [userRole]);
//
//         return (
//             <>
//                 {isWaitingForGeolocation && (
//                     <Overlay>
//                         <div>
//                             <p>Please allow access to your location.</p>
//                         </div>
//                     </Overlay>
//                 )}
//                 {geoError && <GeoErrorDiv>{geoError}</GeoErrorDiv>}
//
//                 {/*{socketError && <SocketErrorDiv geoError={geoError}>{socketError}</SocketErrorDiv>}*/}
//
//                 {/*{socketSuccess && <SocketSuccessDiv geoError={geoError} socketError={socketError}>*/}
//                 {/*    Connected successfully via socket. User role: {userRole}*/}
//                 {/*</SocketSuccessDiv>}*/}
//
//                 <WrappedComponent location={location} {...props} />
//             </>
//         );
//     };
// };
//
// export default withDriverLocation;

import React, {useContext, useEffect, useState, useRef} from "react";
import {AuthContext} from "../../components/AuthContext";
import styled from 'styled-components';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const GeoErrorDiv = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
    z-index: 1000;
    padding: 20px 0;
`;

// eslint-disable-next-line
const SocketErrorDiv = styled.div`
    position: fixed;
    bottom: ${({geoError}) => geoError ? "40px" : 0};
    width: 100%;
    background-color: orange;
    color: white;
    text-align: center;
    z-index: 1000;
    padding: 20px 0;
`;

// eslint-disable-next-line
const SocketSuccessDiv = styled.div`
    position: fixed;
    bottom: ${({geoError, socketError}) => (geoError ? "40px" : socketError ? "40px" : 0)};
    width: 100%;
    background-color: green;
    color: white;
    text-align: center;
    z-index: 1000;
    padding: 20px 0;
`;

const withDriverLocation = (WrappedComponent) => {
    return (props) => {
        const {socket, userRole, handleLogout, setUserLocation} = useContext(AuthContext);
        const [location, setLocation] = useState(null);
        const [geoError, setGeoError] = useState(null);
        // eslint-disable-next-line
        const [socketError, setSocketError] = useState(null);
        const [isWaitingForGeolocation, setIsWaitingForGeolocation] = useState(false);
        // eslint-disable-next-line
        const [socketSuccess, setSocketSuccess] = useState(false);
        const locationInterval = useRef(null);
        const isLoggedIn = useRef(true);
        const retryTimeout = useRef(null);

        useEffect(() => {
            isLoggedIn.current = true;
            return () => {
                isLoggedIn.current = false;
                if (locationInterval.current) {
                    clearInterval(locationInterval.current);
                }
                if (retryTimeout.current) {
                    clearTimeout(retryTimeout.current);
                }
            };
        }, []);

        const handleGeoError = (error) => {
            if (!isLoggedIn.current) return;
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    setGeoError("User denied the request for Geolocation. Please allow access and try again.");
                    handleLogout(); // Log out the user if geolocation is denied
                    break;
                case error.POSITION_UNAVAILABLE:
                    setGeoError("Location information is unavailable.");
                    break;
                case error.TIMEOUT:
                    setGeoError("The request to get user location timed out. Retrying...");
                    retryLocationFetch();
                    break;
                default:
                    setGeoError("An unknown error occurred while fetching the location.");
                    break;
            }
        };

        const retryLocationFetch = () => {
            if (!isLoggedIn.current) return;
            retryTimeout.current = setTimeout(() => {
                getLocation();
            }, 5000); // Retry fetching location after 5 seconds
        };

        const getLocation = () => {
            if (!isLoggedIn.current || userRole !== 3) return;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        if (!isLoggedIn.current) return;
                        setIsWaitingForGeolocation(false);
                        const location = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        };
                        setLocation(location);
                        setUserLocation(location);  // Save location in AuthProvider
                        console.log("Driver's location:", location);
                        if (socket) {
                            socket.emit('location-update', location);
                            setSocketError(null); // Clear socket error on successful emission
                        }
                        setGeoError(null); // Clear geo error on successful location fetch
                    },
                    (error) => {
                        if (!isLoggedIn.current) return;
                        setIsWaitingForGeolocation(false);
                        console.error("Error getting location:", error);
                        handleGeoError(error);
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0,
                    }
                );
            } else {
                setIsWaitingForGeolocation(false);
                console.error("Geolocation is not supported by this browser.");
                setGeoError("Geolocation is not supported by this browser.");
            }
        };

        useEffect(() => {
            const checkGeolocationPermission = async () => {
                setIsWaitingForGeolocation(true);
                try {
                    const result = await navigator.permissions.query({name: 'geolocation'});
                    if (result.state === 'granted') {
                        setIsWaitingForGeolocation(false);
                        getLocation();
                        const interval = setInterval(getLocation, 5000);
                        locationInterval.current = interval; // Save interval ID
                    } else if (result.state === 'prompt') {
                        getLocation();
                    } else {
                        setIsWaitingForGeolocation(false);
                        setGeoError("Geolocation access denied. Please enable it in your browser settings and try again.");
                        handleLogout(); // Log out the user if geolocation is denied
                    }

                    result.onchange = () => {
                        if (result.state === 'granted') {
                            setIsWaitingForGeolocation(false);
                            getLocation();
                            const interval = setInterval(getLocation, 5000);
                            locationInterval.current = interval; // Save interval ID
                            setGeoError(null);
                        } else {
                            setIsWaitingForGeolocation(false);
                            setGeoError("Geolocation access denied. Please enable it in your browser settings.");
                            handleLogout(); // Log out the user if geolocation is denied
                            if (locationInterval.current) clearInterval(locationInterval.current);
                        }
                    };
                } catch (error) {
                    setIsWaitingForGeolocation(false);
                    console.error("Error checking geolocation permissions:", error);
                    setGeoError("An error occurred while checking geolocation permissions.");
                }
            };

            if (userRole === 3 && socket) {
                checkGeolocationPermission();
            }

            return () => {
                if (locationInterval.current) clearInterval(locationInterval.current);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [socket, userRole]);

        useEffect(() => {
            if (socket) {
                socket.on('disconnect', () => {
                    console.error("Socket connection was killed.");
                    setSocketError("Socket connection was killed.");
                    setSocketSuccess(false); // Set socket success to false on disconnect
                });

                socket.on('connect', () => {
                    setSocketError(null); // Clear socket error on reconnect
                    setSocketSuccess(true); // Set socket success to true on connect
                });
            }

            return () => {
                if (socket) {
                    socket.off('disconnect');
                    socket.off('connect');
                }
            };
        }, [socket]);

        useEffect(() => {
            if (userRole !== 3 && locationInterval.current) {
                clearInterval(locationInterval.current); // Clear interval on user logout
                locationInterval.current = null; // Reset interval state
            }
        }, [userRole]);

        return (
            <>
                {isWaitingForGeolocation && (
                    <Overlay>
                        <div>
                            <p>Please allow access to your location.</p>
                        </div>
                    </Overlay>
                )}
                {geoError && <GeoErrorDiv>{geoError}</GeoErrorDiv>}

                {/* Uncomment below lines if needed */}
                {/*{socketError && <SocketErrorDiv geoError={geoError}>{socketError}</SocketErrorDiv>}*/}

                {/*{socketSuccess && <SocketSuccessDiv geoError={geoError} socketError={socketError}>*/}
                {/*    Connected successfully via socket. User role: {userRole}*/}
                {/*</SocketSuccessDiv>}*/}

                <WrappedComponent location={location} {...props} />
            </>
        );
    };
};

export default withDriverLocation;
