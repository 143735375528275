import React, { useState } from 'react';
import { Form, Input, Select, Button, Modal, Spin } from 'antd';
import api from '../../../restAPI';

const { Option } = Select; 

const NewRoute = ({ handleModalSubmit, drivers, fetchData }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();

      const routeData = {
        code: values.routeCode,
        name: values.routeName,
        region: values.routeRegion,
        driver_ids: values.assignDrivers,
      };

      const response = await api.post('/routes', routeData);

      if (response.status === 200) {
        Modal.success({
          content: 'You have successfully created a new route!',
        });
        handleModalSubmit(routeData); 
        fetchData();
      }
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: 'Error occurred during the creation of the route: ' + error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading} tip="Loading..." size='large'>
      <Form form={form} name="createRouteForm">
        <Form.Item
          label="Route Code"
          name="routeCode"
          rules={[{ required: true, message: 'Please input route code!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Route Name"
          name="routeName"
          rules={[{ required: true, message: 'Please input route name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Region"
          name="routeRegion"
          rules={[{ required: true, message: 'Please input region!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Assign Drivers"
          name="assignDrivers"
          rules={[{ required: true, message: 'Please select driver!' }]}
        >
          <Select
            mode="multiple" 
            placeholder="Select drivers"
            showSearch
            optionFilterProp="children"
          >
            {drivers.map(driver => (
              <Option key={driver.value} value={driver.value}>
                {driver.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default NewRoute;
