import React, {useState, useEffect, useContext} from "react";
import {AuthContext} from '../../../components/AuthContext';
import {useParams} from "react-router-dom";
import {Spin, Card, Row, Col, Tag, Divider, Descriptions, message, Segmented, Collapse} from "antd";
import api from "../../../restAPI";
import RouteMap from './RouteMap';
import UpdateClientCoordinatesButton from "./UpdateClientCoordinatesButton";

const {Panel} = Collapse;

const RouteFullDetails = () => {
    const {token} = useContext(AuthContext);
    const {routeUuid} = useParams();
    const [routeDetails, setRouteDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filterDay, setFilterDay] = useState("");
    const [routePoints, setRoutePoints] = useState([]);

    const fetchRouteDetails = async () => {
        try {
            const response = await api.get(`/client-schedule/route-details/${routeUuid}/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setRouteDetails(response.data);
        } catch (error) {
            message.error('Failed to load route details from the server');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRouteDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeUuid]);

    useEffect(() => {
        if (filterDay && routeDetails) {
            const points = routeDetails.schedules
                .filter(schedule => schedule.day_of_week === filterDay)
                .map(schedule => ({
                    latitude: schedule.latitude,
                    longitude: schedule.longitude,
                    client_name: schedule.client_name
                }));
            setRoutePoints(points);
        } else {
            setRoutePoints([]);
        }
    }, [filterDay, routeDetails]);

    if (loading) {
        return <Spin size="large"/>;
    }

    if (!routeDetails) {
        return <div>No route details available</div>;
    }

    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const availableDays = [...new Set(routeDetails.schedules.map(schedule => schedule.day_of_week))];

    const dayOptions = [
        {label: "All", value: "All"},
        ...daysOfWeek.map(day => ({
            label: day,
            value: day,
            disabled: !availableDays.includes(day)
        }))
    ];

    const filteredSchedules = filterDay && filterDay !== "All"
        ? routeDetails.schedules.filter(schedule => schedule.day_of_week === filterDay)
        : routeDetails.schedules;

    const groupedByClient = filteredSchedules.reduce((acc, schedule) => {
        const {client_id, client_name} = schedule;
        if (!acc[client_id]) {
            acc[client_id] = {client_name, schedules: []};
        }
        acc[client_id].schedules.push(schedule);
        return acc;
    }, {});

    return (
        <div style={{padding: '20px'}}>
            <Card title={`Route Details: ${routeDetails.route_code}`}>
                <Descriptions bordered>
                    <Descriptions.Item label="Route Code">{routeDetails.route_code}</Descriptions.Item>
                    <Descriptions.Item label="Route Name">{routeDetails.route_name}</Descriptions.Item>
                </Descriptions>
                <Divider/>
                <h3>Schedules</h3>
                <Segmented
                    options={dayOptions}
                    onChange={value => setFilterDay(value === "All" ? "" : value)}
                    style={{marginBottom: '20px'}}
                />

                <Row gutter={[16, 16]}>
                    <Col span={10}>
                        <Collapse accordion>
                            {Object.keys(groupedByClient).map(clientId => (
                                <Panel header={`${groupedByClient[clientId].client_name} (${clientId})`} key={clientId}>
                                    {groupedByClient[clientId].schedules.map(schedule => (
                                        <Card type="inner" key={schedule.schedule_id}>
                                            <Descriptions column={1}>
                                                <Descriptions.Item
                                                    label="Day of Week">{schedule.day_of_week}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Time">{`${schedule.open} - ${schedule.close}`}</Descriptions.Item>
                                                <Descriptions.Item label="Notes">{schedule.notes}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Contact Person">{schedule.contact_person}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Contact Phone">{schedule.contact_phone}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Box In House">{schedule.box_in_house}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Box Location">{schedule.box_location}</Descriptions.Item>
                                                <Descriptions.Item label="Is Active">
                                                    <Tag color={schedule.is_active ? 'green' : 'red'}>
                                                        {schedule.is_active ? 'Active' : 'Inactive'}
                                                    </Tag>
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Latitude">{schedule.latitude}</Descriptions.Item>

                                                <Descriptions.Item
                                                    label="Longitude">{schedule.longitude}</Descriptions.Item>

                                                <Descriptions.Item>
                                                    <UpdateClientCoordinatesButton clientId={schedule.client_id}
                                                                                   token={token}/>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Card>
                                    ))}
                                </Panel>
                            ))}
                        </Collapse>
                    </Col>

                    <Col span={14}>
                        {filterDay && filterDay !== "All" && routePoints.length > 0 && (
                            <div style={{marginTop: '20px'}}>
                                <h3>Route Map for {filterDay}</h3>
                                <RouteMap route={routePoints}/>
                            </div>
                        )}
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default RouteFullDetails;
