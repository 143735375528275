import React, {useState, useEffect} from 'react';
import {GoogleMap, useJsApiLoader, Marker, DirectionsRenderer} from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '700px'
};

const center = {
    lat: 0,
    lng: 0
};

const startEndPoint = {
    lat: 40.65052823284166,
    lng: -74.01534251894682
};

const RouteMap = ({route}) => {
    const [directionsResponse, setDirectionsResponse] = useState(null);

    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCeF9pve4e1BMOP9f6gA3p1MpEbiHlGY3I"
    });

    useEffect(() => {
        if (isLoaded && route.length > 0) {
            const waypoints = route.map(point => ({
                location: {lat: parseFloat(point.latitude), lng: parseFloat(point.longitude)},
                stopover: true
            }));

            const origin = startEndPoint;
            const destination = startEndPoint;

            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    waypoints: waypoints,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    optimizeWaypoints: false
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(result);
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                }
            );
        } else {
            setDirectionsResponse(null);
        }
    }, [isLoaded, route]);

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={route.length > 0 ? startEndPoint : center}
            zoom={route.length > 0 ? 12 : 2}
        >
            <Marker position={startEndPoint}/>
            {route.map((point, index) => (
                <Marker key={index} position={{lat: parseFloat(point.latitude), lng: parseFloat(point.longitude)}}/>
            ))}
            {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse}/>
            )}
            <Marker position={startEndPoint}/>
        </GoogleMap>
    );
};

export default RouteMap;
