import React, {useState, useEffect} from 'react';
import {DeleteOutlined} from '@ant-design/icons';
import {Select, DatePicker, Tag, Button, Space} from 'antd';
import dayjs from 'dayjs';

const {Option} = Select;

const Filters = ({
                     drivers,
                     routes,
                     selectedDriver,
                     selectedRoute,
                     selectedDate,
                     handleDriverChange,
                     handleRouteChange,
                     setSelectedDate
                 }) => {
    const [initialDriverRoutes, setInitialDriverRoutes] = useState([]);

    useEffect(() => {
        if (selectedDriver) {
            const driverRoutes = routes.filter(route => route.driverId === selectedDriver.id);
            setInitialDriverRoutes(driverRoutes);
        } else {
            setInitialDriverRoutes([]);
        }
    }, [selectedDriver, routes]);

    const sortRoutesAlphabetically = (routes) => {
        return routes.sort((a, b) => a.code.localeCompare(b.code));
    };

    const renderRoutesOptions = () => {
        let options = [];

        if (selectedDriver) {
            options.push(
                <Option key="all-routes" value="all-routes">
                    All Routes - {selectedDriver.first_name} {selectedDriver.last_name}
                </Option>
            );

            const driverRoutes = initialDriverRoutes.length ? initialDriverRoutes : routes;
            const sortedDriverRoutes = sortRoutesAlphabetically(driverRoutes);
            options = options.concat(
                sortedDriverRoutes.map((route) => (
                    <Option key={route.code} value={route.code}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Tag color="blue">{route.code}</Tag>
                            <span style={{marginLeft: '4px'}}>{route.name}</span>
                        </div>
                    </Option>
                ))
            );
        } else {
            options.push(
                <Option key="all-routes" value={null}>
                    All Routes
                </Option>
            );

            if (Array.isArray(routes)) {
                const sortedRoutes = sortRoutesAlphabetically(routes);
                options = options.concat(
                    sortedRoutes.map((route) => (
                        <Option key={route.code} value={route.code}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Tag color="blue">{route.code}</Tag>
                                <span style={{marginLeft: '4px'}}>{route.name}</span>
                            </div>
                        </Option>
                    ))
                );
            }
        }

        return options;
    };

    const resetFilters = () => {
        handleDriverChange(null);
        handleRouteChange(null);
    };

    return (
        <Space size={'small'}>

            <Button onClick={resetFilters} type="primary" danger icon={<DeleteOutlined/>}>
                Reset Filters
            </Button>

            <Select
                showSearch
                filterOption={(input, option) => {
                    const driver = drivers.find(d => d.id === option.value);
                    const driverName = driver ? `${driver.first_name} ${driver.last_name}`.toLowerCase() : '';
                    const driverId = driver ? `ID:${driver.id}`.toLowerCase() : '';
                    return driverName.includes(input.toLowerCase()) || driverId.includes(input.toLowerCase());
                }}
                style={{width: 300}}
                placeholder="All Drivers"
                onChange={(value) => handleDriverChange(value)}
                value={selectedDriver ? selectedDriver.id : null}
            >
                <Option value={null}>All Drivers</Option>
                {Array.isArray(drivers) && drivers.map((driver) => (
                    <Option key={driver.id} value={driver.id}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Tag color="magenta">ID:{driver.id}</Tag>
                            <span style={{marginLeft: '4px'}}>{driver.first_name} {driver.last_name}</span>
                        </div>
                    </Option>
                ))}
            </Select>

            <Select
                showSearch
                style={{width: 300}}
                placeholder="All Routes"
                onChange={(value) => handleRouteChange(value === "all-routes" ? null : value)}
                value={selectedRoute}
            >
                {renderRoutesOptions()}
            </Select>

            <DatePicker
                style={{marginRight: 16}}
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                disabledDate={(current) => current && current < dayjs().startOf('day')}
            />


        </Space>
    );
};

export default Filters;
