import React, {useState, useEffect, useRef} from 'react';
import {Modal, Typography, Button, Form, InputNumber, Alert, message, Spin} from 'antd';
import api from '../../../restAPI';

const {Title} = Typography;

const ReceivingModal = ({isModalVisible, selectedRowData, handleModalClose, token, setData}) => {
    const [form] = Form.useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [showError, setShowError] = useState(false);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isModalVisible) {
            form.resetFields();
            setDisableSubmit(false);
            setShowError(false);

            // Set focus on the InputNumber field when the modal opens
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 100);
        }
    }, [isModalVisible, form]);

    const handleSaveAndClose = async () => {
        try {
            const values = await form.validateFields();
            await acceptOrder(selectedRowData.id, values.number, token);
            handleModalClose();
        } catch (error) {
            console.log('Validation failed:', error);
        }
    };

    const handleInputChange = (value) => {
        if (value === 0 && !selectedRowData?.boxIsEmpty) {
            setDisableSubmit(true);
            setShowError(true);
        } else {
            setDisableSubmit(false);
            setShowError(false);
        }
    };

    const acceptOrder = async (orderId, quantity, token) => {
        setLoading(true);
        try {
            const response = await api.put('/api/v2/receiver-actions/accept-order/', {orderId, quantity}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.success) {
                message.success('Order accepted successfully');
                setData((prevData) => prevData.filter((order) => order.id !== orderId));
                handleModalClose();
            } else {
                message.error('Failed to accept order');
            }
        } catch (error) {
            console.error('Error accepting order:', error);
            message.error('Error accepting order');
        } finally {
            setLoading(false);
        }
    };


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSaveAndClose();
        }
    };

    return (
        <Modal
            title={<Title level={4}>Order Details</Title>}
            open={isModalVisible}
            onCancel={handleModalClose}
            closable={false}
            maskClosable={false}
            footer={[
                <Button key="close" onClick={handleModalClose} disabled={loading}>
                    Close
                </Button>,
                <Button key="saveAndClose" type="primary" onClick={handleSaveAndClose}
                        disabled={disableSubmit || loading}>
                    Save & Close
                </Button>,
            ]}
        >
            <Spin spinning={loading}>
                {selectedRowData && (
                    <Form form={form} layout="vertical" onKeyDown={handleKeyPress}>
                        <Form.Item
                            name="number"
                            label="Number of Specimens"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a number'
                                },
                                {
                                    type: 'number',
                                    min: selectedRowData?.boxIsEmpty ? 0 : 1,
                                    message: selectedRowData?.boxIsEmpty
                                        ? 'Number cannot be negative'
                                        : 'Number must be greater than zero'
                                }
                            ]}
                        >
                            <InputNumber
                                ref={inputRef}
                                min={0}
                                placeholder="Enter a number"
                                style={{width: '100%'}}
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                        {showError && (
                            <Alert
                                message="Number must be greater than zero to proceed."
                                type="warning"
                                showIcon
                            />
                        )}
                    </Form>
                )}
            </Spin>
        </Modal>
    );
};

export default ReceivingModal;
