import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Space, message, Spin, Button, List, Divider, Input, Row, Col, Tag} from 'antd';
import {ArrowRightOutlined, ClearOutlined} from '@ant-design/icons';
import api from "../../../restAPI";
import InfiniteScroll from 'react-infinite-scroll-component';

const RoutesList = ({token}) => {
    const [routeSchedules, setRouteSchedules] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalRoutes, setTotalRoutes] = useState(0);
    const [routeCode, setRouteCode] = useState('');
    const [clientId, setClientId] = useState('');
    const [hasMore, setHasMore] = useState(true);

    const fetchRouteSchedules = async (reset = false) => {
        if (loading) return;

        setLoading(true);
        try {
            const response = await api.get('/client-schedule/distinct-route-schedules/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    routeCode: routeCode || undefined,
                    clientId: clientId || undefined,
                    page,
                    limit: 10,
                }
            });
            const newRouteSchedules = response.data.routeSchedules;

            setRouteSchedules(reset ? newRouteSchedules : [...routeSchedules, ...newRouteSchedules]);
            setTotalRoutes(response.data.totalRoutes);
            setHasMore(page * 10 < response.data.totalRoutes);
        } catch (error) {
            message.error('Failed to load schedules from the server');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRouteSchedules(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeCode, clientId]);

    const handleRouteCodeSearch = (e) => {
        setRouteCode(e.target.value);
        setPage(1);
    };

    const handleClientIdSearch = (e) => {
        setClientId(e.target.value);
        setPage(1);
    };

    const loadMore = () => {
        if (loading || !hasMore) return;
        setPage(prevPage => {
            const newPage = prevPage + 1;
            fetchRouteSchedules();
            return newPage;
        });
    };

    return (
        <div style={{padding: '20px', background: '#f0f2f5', display: 'flex', flexDirection: 'column'}}>
            <Row gutter={[16, 16]} style={{marginBottom: 20}}>
                <Col span={12}>
                    <Input
                        placeholder="Search by route code"
                        value={routeCode}
                        onChange={handleRouteCodeSearch}
                        onPressEnter={() => fetchRouteSchedules(true)}
                    />
                </Col>
                <Col span={12}>
                    <Input
                        placeholder="Search by client ID"
                        value={clientId}
                        onChange={handleClientIdSearch}
                        onPressEnter={() => fetchRouteSchedules(true)}
                    />
                </Col>
            </Row>

            <Row align="middle">
                <Col>
                    <Tag bordered={false}>Total Routes: <strong>{totalRoutes}</strong></Tag>
                </Col>
                <Col style={{marginLeft: '20px'}}>
                    <Button
                        disabled={!routeCode && !clientId}
                        type="text"
                        danger={true}
                        icon={<ClearOutlined/>}
                        onClick={() => {
                            setRouteCode('');
                            setClientId('');
                        }}
                    >
                        Reset Filters
                    </Button>
                </Col>
            </Row>

            <Divider/>

            <InfiniteScroll
                dataLength={routeSchedules.length}
                next={loadMore}
                hasMore={hasMore}
                loader={<Spin size="large"/>}
                endMessage={<Divider plain>End of schedules</Divider>}
                style={{overflow: 'hidden'}}
            >
                <List
                    itemLayout="vertical"
                    dataSource={routeSchedules}
                    renderItem={routeSchedule => (
                        <List.Item
                            key={routeSchedule.route_id}
                            actions={[
                                <Link to={`/routes-management/${routeSchedule.route_id}`}>
                                    <Button type="primary" icon={<ArrowRightOutlined/>}>View Details</Button>
                                </Link>
                            ]}
                        >
                            <List.Item.Meta
                                title={
                                    <Space>
                                        <Link to={`/routes-management/${routeSchedule.route_id}`}>
                                            {routeSchedule.route_code} - ({routeSchedule.schedules.length} clients)
                                        </Link>
                                    </Space>
                                }
                                // description={
                                //     <Row>
                                //         {routeSchedule.schedules.map(schedule => (
                                //             <Col span={24} key={schedule.schedule_id}>
                                //                 <Tag bordered={false}>
                                //                     <strong>{schedule.client_id} --- {schedule.client_name}</strong> - {schedule.open} to {schedule.close}
                                //                 </Tag>
                                //             </Col>
                                //         ))}
                                //     </Row>
                                // }
                            />
                        </List.Item>
                    )}
                    style={{textAlign: 'start', backgroundColor: '#ffffff', padding: '20px', borderRadius: '8px'}}
                />
            </InfiniteScroll>
        </div>
    );
};

export default RoutesList;
