import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Card, Row, Col, DatePicker, Typography, Button, Modal, Spin, Radio, Divider, Form, Select, Tag, Input, Switch, Tooltip } from 'antd';
import { ClockCircleOutlined, CalendarOutlined, ArrowDownOutlined, UnorderedListOutlined } from '@ant-design/icons';
import moment from 'moment';
import api from '../../../restAPI';
import { AuthContext } from '../../../components/AuthContext';
import { getSelectedDaysText } from './SelectedDays';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const UpdateSchedule = ({ onClose, initialValues, clientID, scheduleID, onUpdate }) => {
  const [daysOfWeek, setDaysOfWeek] = useState([
    { day: 'Monday', checked: false },
    { day: 'Tuesday', checked: false },
    { day: 'Wednesday', checked: false },
    { day: 'Thursday', checked: false },
    { day: 'Friday', checked: false },
    { day: 'Saturday', checked: false },
    { day: 'Sunday', checked: false },
  ]);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [driverId, setDriverId] = useState(null);
  const [addedNotes, setAddedNotes] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [routes, setRoutes] = useState([]);
  const { token } = useContext(AuthContext);
  const [isActive, setIsActive] = useState(true);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleCardClick = (index) => {
    const updatedDaysOfWeek = [...daysOfWeek];
    updatedDaysOfWeek[index].checked = !updatedDaysOfWeek[index].checked;
    setDaysOfWeek(updatedDaysOfWeek);
  };

  const selectedDaysText = getSelectedDaysText(
    daysOfWeek.filter((day) => day.checked).map((day) => day.day),
    daysOfWeek
  );

  const handleStartTimeChange = (time, timeString) => {
    setStartTime(timeString);
  };

  const handleEndTimeChange = (time, timeString) => {
    setEndTime(timeString);
  };

  const handleBusinessDaysClick = () => {
    const updatedDaysOfWeek = daysOfWeek.map((day) => ({
      ...day,
      checked: day.day !== 'Saturday' && day.day !== 'Sunday',
    }));
    setDaysOfWeek(updatedDaysOfWeek);
  };

  const handleWholeWeekClick = () => {
    const updatedDaysOfWeek = daysOfWeek.map((day) => ({
      ...day,
      checked: true,
    }));
    setDaysOfWeek(updatedDaysOfWeek);
  };

  const handleManuallyClick = () => {
    const updatedDaysOfWeek = daysOfWeek.map((day) => ({
      ...day,
      checked: false,
    }));
    setDaysOfWeek(updatedDaysOfWeek);
  };

  const switchInfo = (
    <div style={{textAlign:'center'}}>
      <Text style={{color:'white'}}>Please note that the changes will take effect on the following day</Text>
    </div>
  );

  const handleFormSubmit = async () => {
    const selectedInfo = {
      clientId: parseInt(clientID),
      routeId: selectedRoute,
      open: startTime,
      close: endTime,
      days: daysOfWeek.filter((day) => day.checked).map((day) => day.day),
      notes: 'something',
      contactPerson: 'Test',
      contactPhone: 564846245,
      boxInHouse: 'yes',
      boxLocation: 'NYC',
      isActive: isActive,
    };
  
    setIsLoading(true);
  
    try {
      const response = await api.put(`/client-schedule/${scheduleID}/`, selectedInfo, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.status === 'Success') {
        Modal.success({
          content: 'Schedule has been successfully updated!',
          onOk: onClose,
        });
        if(onUpdate) {
          onUpdate(); 
        }
      } else {
        Modal.error({
          title: 'Error',
          content: 'Something went wrong!',
          onOk: onClose,
        });
      }
    } catch (error) {
      console.error(error);
      Modal.error({
        title: 'Error',
        content: 'Something went wrong!',
        onOk: onClose,
      });
    } finally {
      setIsLoading(false);
      onClose(); 
    }
  };

  const handleModalOk = () => {
    setModalVisible(false);
  };

  const fetchRoutes = useCallback(async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await api.get('/routes/', config);
      setRoutes(response.data.routes);
      setDriverId(response.data[0].driver_id);
    } catch (error) {
      console.error(error);
    }
  }, [token]);

  const filterOptionForRoutes = (input, option) => {
    const routeData = routes.find(route => route.id === option.value);
    if (!routeData) return false;
    const routeCode = String(routeData.code).toLowerCase();
    const routeDriverId = String(routeData.driver_id).toLowerCase();
    return (
      routeCode.includes(input.toLowerCase()) || routeDriverId.includes(input.toLowerCase())
    );
  };

  useEffect(() => {
    fetchRoutes();
  }, [fetchRoutes]);

  useEffect(() => {
    const selectedRouteObj = routes.find((route) => route.id === selectedRoute);
    const driverId = selectedRouteObj ? selectedRouteObj.driver_id : null;
    setDriverId(driverId);
  }, [selectedRoute, routes]);

  useEffect(() => {
    if (initialValues) {
      setDaysOfWeek((prevDaysOfWeek) =>
        prevDaysOfWeek.map((day) => ({
          ...day,
          checked: initialValues.days.includes(day.day),
        }))
      );
      setStartTime(initialValues.startTime);
      setEndTime(initialValues.endTime);
      setSelectedRoute(initialValues.selectedRoute);
      setAddedNotes(initialValues.notes);
      setIsActive(initialValues.isActive);
    }
  }, [initialValues]);

  return (
    <Spin spinning={isLoading} tip="Loading..." size="large">
      <Divider plain style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
        <CalendarOutlined /> Choose days
      </Divider>
      <Row justify="center" align="middle" gutter={[16, 16]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row justify="center" gutter={16}>
            {daysOfWeek.map((day, index) => (
              <Col key={index} flex="100px">
                <Card
                  hoverable
                  onClick={() => handleCardClick(index)}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: day.checked ? '#204E57' : 'white',
                    color: day.checked ? 'white' : 'black',
                  }}
                >
                  {day.day}
                </Card>
              </Col>
            ))}
          </Row>

          <Row justify="center" align="middle" gutter={[16, 16]} className="radioWeek">
            <Radio.Group defaultValue="a" buttonStyle="solid" size="small">
              <Radio.Button value="a" onClick={handleManuallyClick}>
                Manually
              </Radio.Button>
              <Radio.Button value="b" onClick={handleBusinessDaysClick}>
                Business Days
              </Radio.Button>
              <Radio.Button value="c" onClick={handleWholeWeekClick}>
                Whole Week
              </Radio.Button>
            </Radio.Group>
          </Row>

          <Divider plain style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
            <ClockCircleOutlined /> Choose time
          </Divider>
          <Row justify="center" style={{ marginTop: '20px' }}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="scheduleTime">
              <Text>From: </Text>
              <DatePicker
                onChange={handleStartTimeChange}
                picker="time"
                format="HH:mm"
                style={{ marginRight: '20px' }}
                value={startTime ? moment(startTime, 'HH:mm') : null}
              />
              <Text>To: </Text>
              <DatePicker
                onChange={handleEndTimeChange}
                picker="time"
                format="HH:mm"
                style={{ marginLeft: '8px' }}
                value={endTime ? moment(endTime, 'HH:mm') : null}
              />
            </Col>
          </Row>

          <Divider plain style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
            <ArrowDownOutlined /> Add route
          </Divider>
          <Row justify="center" align="middle">
            <Form.Item
              name="assign"
              rules={[{ required: true, message: 'Please add route!' }]}
            >
              <div style={{ position: 'relative', zIndex: 1 }}>
                <Select
                  showSearch
                  style={{ width: '700px' }}
                  onChange={(value) => setSelectedRoute(value)}
                  value={selectedRoute}
                  className="option"
                  filterOption={filterOptionForRoutes}
                >
                  {routes.map((route) => (
                    <Option key={route.id} value={route.id}>
                      <span>
                        <Tag color="magenta">
                          <b>{route.code}</b>
                        </Tag>
                        <Tag color="purple">{route.name}</Tag>
                        <Tag color="cyan">{route.region}</Tag>
                        <Tag color="blue">{route.driver_id}</Tag> 
                      </span>
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </Row>

          <Divider plain style={{ color: 'rgba(0, 0, 0, 0.4)' }}><UnorderedListOutlined /> Add notes</Divider>
          <Row justify="center" align="middle">
            <Form.Item>
            <TextArea
              value={addedNotes}
              defaultValue={addedNotes}
              onChange={(e) => setAddedNotes(e.target.value)}
              placeholder="Here you can add notes"
              style={{ width: "700px", }}
              autoSize={{
                minRows: 2,
                maxRows: 5,
              }}
            />
            </Form.Item>
          </Row>

          {startTime && endTime && daysOfWeek.some((day) => day.checked) && selectedRoute && (
            <Row justify="center">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Card>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24} className="scheduleCard">
                  <Text>
                    You have chosen: <b>{selectedDaysText}</b> from <b>{startTime}</b> to{' '}
                    <b>{endTime}</b> and {selectedRoute && (
                      <b>
                        {routes.find((route) => route.id === selectedRoute)?.code}
                      </b>
                    )} route. The driver's ID is <b>{driverId}</b>
                  </Text>

                    <Row justify="end" align="middle">
                      <Col>
                        <Text style={{marginRight:'8px'}}>Active:</Text>
                      </Col>
                        
                      <Col>
                        <Tooltip
                          title={switchInfo}
                          color='red'
                          placement="topRight"
                          open={isTooltipVisible}
                        >
                          <Switch
                            checkedChildren="ON" unCheckedChildren="0FF"
                            checked={isActive}
                            onChange={(checked) => setIsActive(checked)}
                            style={{marginRight:'20px'}}
                            onMouseEnter={() => setIsTooltipVisible(true)}
                            onMouseLeave={() => setIsTooltipVisible(false)}
                          />
                        </Tooltip>
                      </Col>
                        
                      <Col>
                        <Spin spinning={isLoading} size="small">
                          <Button
                            type="primary"
                            onClick={handleFormSubmit}
                            disabled={isLoading}
                          >
                            Update
                          </Button>
                        </Spin>
                      </Col>
                    </Row>

                    <Modal
                      title="Schedule Status"
                      open={modalVisible}
                      onOk={handleModalOk}
                      onCancel={handleModalOk}
                    />
                  </Col>
                </Card>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default UpdateSchedule;
