import React, { useState, useContext, useEffect, useCallback } from "react";
import { Row, Col, Typography, Spin, Badge, Card, Button, Tag} from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import api from "../../../restAPI";
import Modal from 'react-modal';
import { AuthContext } from "../../../components/AuthContext";
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';

const { Title } = Typography;
Modal.setAppElement('#root');

/* Map */
function OpenMap({ mapCenter }) {
  const [markerPosition, setMarkerPosition] = useState(null);
  const mapStyles = {   
    zIndex:10000,    
    height: "100%",
    width: "100%"};

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMarkerPosition(mapCenter);
    }, 10); 

    return () => clearTimeout(timeoutId); 
  }, [mapCenter]);

  return (
    <LoadScriptNext
      googleMapsApiKey='AIzaSyCeF9pve4e1BMOP9f6gA3p1MpEbiHlGY3I'>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={13}
        center={mapCenter}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
    </LoadScriptNext>
  )
}

const DriverModalWindow = ({ driver }) => {
  const [data, setData] = useState([]);
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMapCenter, setModalMapCenter] = useState({ lat: 0, lng: 0 });
  const formattedDate = new Date().toLocaleDateString();
  const driverID = driver.id;

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await api.get(`/orders/todays-driver-orders/${driverID}/created/`, config);
      setData(response.data.items);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [token, driverID]);

  const handleOpenModal = (latitude, longitude) => {
    if (latitude && longitude) {
      setModalMapCenter({ lat: latitude, lng: longitude });
      setModalIsOpen(true);
    } else {
      console.error("Invalid coordinates: ", { latitude, longitude });
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (driverID) {
      fetchData();
    }
  }, [fetchData, driverID]);

  return (
    <div>
      <Spin spinning={isLoading} tip="Loading..." size='large'>
        <Row justify="center">
          <Col xl={22} lg={22} md={22} sm={22} xs={22}>
            <Row justify="center">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Title level={2} className="mainLabel">Driver's Schedule</Title>
                <Tag color="#f5222d" style={{marginBottom:'20px', fontSize:'14px'}}>{formattedDate}</Tag>
              </Col>
            </Row>
  
            <Row gutter={[16, 16]} justify="start" style={{ maxHeight: '70vh', overflow: 'auto' }}>
              {data.map((item) => (
                <Col xxl={8} xl={12} lg={12} md={24} sm={24} xs={24} key={item.id}>
                  <Card title={item.clientDetails?.name} bordered>
                    <p>Client Phone: {item.clientDetails?.phone}</p>
                    <p>Client Address: {item.clientDetails?.street}, {item.clientDetails?.zip}</p>
                    <p>Time Delivery: {item.clientScheduleDetails?.details_open} - {item.clientScheduleDetails?.details_close}</p>
                    <p>Notes: {item.clientScheduleDetails?.details_notes}</p>
                    <div style={{ display: 'block' }}>
                      <Badge
                        count={item.status === 'CREATED' ? 'CREATED' : 'UPDATED'}
                        style={{
                          backgroundColor: item.status ? '#52c41a' : '#f5222d',
                          marginTop: '16px',
                        }}
                      />
                      <div>
                        <EnvironmentOutlined 
                          onClick={() => handleOpenModal(item.latitude, item.longitude)} 
                          style={{fontSize: '20px', cursor: 'pointer', color: 'blue', marginTop:'20px'}} 
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Spin>
  
      <Modal 
        isOpen={modalIsOpen} 
        onRequestClose={closeModal} 
        style={{
          overlay: { zIndex: 10001 },
          content: {
            display: 'flex',
            flexDirection: 'column',
            height: '40vh', 
            width: '40vw', 
            margin: 'auto',
          }
        }}
      >
        <OpenMap mapCenter={modalMapCenter} style={{ flexGrow: 1 }}/>
        <Button onClick={closeModal} style={{margin:'10px'}}>Close Map</Button>
      </Modal>
    </div>
  );  
}

export default DriverModalWindow;
