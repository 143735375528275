import React, {useState, useEffect} from 'react';
import {Modal, Select} from 'antd';
import api from '../../../restAPI'; // Adjust the path to your API service

const {Option} = Select;

const AssignDriverModal = ({isModalVisible, handleModalVisibility, onAssign, token, orderIds}) => {
    const [drivers, setDrivers] = useState([]);
    const [loadingDrivers, setLoadingDrivers] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);

    useEffect(() => {
        const fetchDrivers = async () => {
            try {
                setLoadingDrivers(true);
                const response = await api.get('/driver/drivers-list/', {
                    headers: {Authorization: `Bearer ${token}`}
                });
                setDrivers(response.data.drivers);
            } catch (err) {
                console.error("Error while fetching drivers", err);
            } finally {
                setLoadingDrivers(false);
            }
        };

        if (isModalVisible) {
            fetchDrivers();
        }
    }, [isModalVisible, token]);

    const handleDriverChange = (value) => {
        setSelectedDriver(value);
    };

    const handleClear = () => {
        setSelectedDriver(null);
    };

    const handleOk = () => {
        onAssign(selectedDriver, orderIds);
        handleModalVisibility(false);
    };

    return (
        <Modal
            title="Assign/Reassign Driver"
            open={isModalVisible}
            onOk={handleOk}
            onCancel={() => {
                handleClear()
                handleModalVisibility(false)
            }}
            closable={false}
            maskClosable={false}
            destroyOnClose={true}
        >
            <Select
                showSearch
                placeholder="Select Driver"
                onChange={handleDriverChange}
                value={selectedDriver || undefined}
                loading={loadingDrivers}
                allowClear
                filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                }
                onClear={handleClear}
                style={{width: '100%'}}
            >
                {drivers.map(driver => (
                    <Option key={driver.id} value={driver.id}>
                        {`${driver.first_name} ${driver.last_name}`}
                    </Option>
                ))}
            </Select>
        </Modal>
    );
};

export default AssignDriverModal;
