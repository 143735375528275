import React from 'react';
import {List} from 'antd';
import OrderCollapse from "./OrderCollapse";

const OrderList = ({createdOrders, handleOpenModal, openSuccessModal, orderStatusesForDriver, getStatusColor}) => (
    <List
        dataSource={createdOrders}
        renderItem={item => (
            <List.Item key={item.id} style={{width: '100%'}}>
                <OrderCollapse
                    item={item}
                    orderStatusesForDriver={orderStatusesForDriver}
                    getStatusColor={getStatusColor}
                    handleOpenModal={handleOpenModal}
                    openSuccessModal={openSuccessModal}
                />
            </List.Item>
        )}
    />
);

export default OrderList;
