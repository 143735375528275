import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Form, Input, Row, Col, Typography, Select, Spin, Modal, Checkbox} from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import api from '../../../restAPI';

const {Title} = Typography;
const {Option} = Select;

const RegisterSuperUser = (props) => {

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [driverCharter, setDriverCharter] = useState(false);
    const [isFloridaState, setIsFloridaState] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");

    console.log("role", selectedRole)

    const userRegister = async (values) => {
        // const userData = {...values, role: values.driverCharter ? 5 : 3, state: isFloridaState ? 'FL' : 'NY', };//!what's happing to the role in this line? being like this causes registration errors on the backend: It always passes role 3 to the backend.
        const userData = {...values, state: isFloridaState ? 'FL' : 'NY', };
        console.log(userData);

        try {
            setIsLoading(true);
            setIsClicked(true);
            const response = await api.post('/register', userData);

            if (response.status === 201) {
                console.log('User registered successfully!');
                Modal.success({
                    content: 'You added user successfully!',
                });
                props.onSuccess();
                // props.onCancel();
                navigate('/users');
            } else {
                console.log('Something went wrong during registration!');
                Modal.error({
                    title: 'Error',
                    content: 'Something went wrong during registration!',
                });
            }
        } catch (error) {
            console.log('Error occurred during registration:', error);
            Modal.error({
                title: 'Error',
                content: 'Error occurred during registration: ' + error.message,
            });
        } finally {
            setIsLoading(false);
            setIsClicked(false);
        }
    };

    const handleRoleChange = (value) => {
        setSelectedRole(value);
        form.setFieldsValue({ role: value });
    }

    const setRole = () => {
        switch (selectedRole) {
          case '1':
            return "Super User";
          case '2':
            return "Dispatcher";
          case '3':
            return "Driver";
          case '4':
            return "Reciever";
          default:
            return "";
        }
      };
    

    const renderFormFields = () => {
        if (selectedRole === '1') {
            return (
                <Row justify="center" align="middle">
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                            name="firstName"
                            value={firstName}
                            rules={[{ required: true, message: 'Please input first name!', },
                                { pattern: /^[a-zA-Z]*$/, message: 'Please enter letters only!', },
                            ]}>
                                <Input
                                    placeholder="Enter first name"
                                    value={firstName}
                                    onChange={(e) => {
                                        const updatedValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                        setFirstName(updatedValue);
                                        form.setFieldsValue({ firstName: updatedValue });
                                    }}
                                />
                        </Form.Item>

                        <Form.Item
                            name="lastName"
                            value={lastName}
                            rules={[{ required: true, message: 'Please input last name!', },
                                { pattern: /^[a-zA-Z]*$/, message: 'Please enter letters only!', },
                            ]}>
                                <Input
                                    placeholder="Enter last name"
                                    value={lastName}
                                    onChange={(e) => {
                                        const updatedValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                        setLastName(updatedValue);
                                        form.setFieldsValue({ lastName: updatedValue });
                                    }}
                                />
                        </Form.Item>

                        <Form.Item name="password"
                            rules={[{ required: true, message: 'Please input password!', },
                                { whitespace: true, message: 'Password cannot contain spaces!', },
                            ]}>
                            <Input.Password placeholder="Enter password" />
                        </Form.Item>

                        <Form.Item name="confirmPassword"
                            rules={[{ required: true, message: 'Please confirm password!', },
                                { whitespace: true, message: 'Password cannot contain spaces!', },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('The two passwords do not match!')
                                        );
                                    },
                                }),
                            ]}>
                            <Input.Password placeholder="Confirm password" />
                        </Form.Item>

                        <Form.Item name="email"
                            rules={[{ required: true, message: 'Please input email!', },
                                { type: 'email', message: 'Please enter a valid email address!', },
                            ]}>
                            <Input placeholder="Enter email" />
                        </Form.Item>
                    </Col>
                </Row>
            );
        } else if (selectedRole === '2') {
            return (
                <Row justify="center" align="middle">
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                            name="firstName"
                            rules={[{ required: true, message: 'Please input first name!', },
                                { pattern: /^[a-zA-Z]*$/, message: 'Please enter letters only!', },
                            ]}>
                                <Input
                                    placeholder="Enter first name"
                                    value={firstName}
                                    onChange={(e) => {
                                        const updatedValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                        setFirstName(updatedValue);
                                        form.setFieldsValue({ firstName: updatedValue });
                                    }}
                                />
                        </Form.Item>

                        <Form.Item
                            name="lastName"
                            rules={[{ required: true, message: 'Please input last name!', },
                                { pattern: /^[a-zA-Z]*$/, message: 'Please enter letters only!', },
                            ]}>
                            <Input
                                placeholder="Enter last name"
                                value={lastName}
                                onChange={(e) => {
                                    const updatedValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                    setLastName(updatedValue);
                                    form.setFieldsValue({ lastName: updatedValue });
                                }}
                            />
                        </Form.Item>

                        <Form.Item name="password"
                            rules={[{ required: true, message: 'Please input password!', },
                                { whitespace: true, message: 'Password cannot contain spaces!', },
                            ]}>
                            <Input.Password placeholder="Enter password" />
                        </Form.Item>

                        <Form.Item name="confirmPassword"
                            rules={[{ required: true, message: 'Please confirm password!', },
                                { whitespace: true, message: 'Password cannot contain spaces!', },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('The two passwords do not match!')
                                        );
                                    },
                                }),
                            ]}>
                            <Input.Password placeholder="Confirm password" />
                        </Form.Item>

                        <Form.Item name="email"
                            rules={[{ required: true, message: 'Please input email!', },
                                { type: 'email', message: 'Please enter a valid email address!', },
                            ]}>
                            <Input placeholder="Enter email" />
                        </Form.Item>
                    </Col>
                </Row>
            );
        } else if (selectedRole === '3') {
            return (
                <Row justify="center" align="middle">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="firstName"
                      rules={[
                        { required: true, message: 'Please input first name!', },
                        { pattern: /^[a-zA-Z]*$/, message: 'Please enter letters only!', },
                      ]}
                    >
                      <Input
                        placeholder="Enter first name"
                        value={firstName}
                        onChange={(e) => {
                          const updatedValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                          setFirstName(updatedValue);
                          form.setFieldsValue({ firstName: updatedValue });
                        }}
                      />
                    </Form.Item>
          
                    <Form.Item
                      name="lastName"
                      rules={[
                        { required: true, message: 'Please input last name!', },
                        { pattern: /^[a-zA-Z]*$/, message: 'Please enter letters only!', },
                      ]}
                    >
                      <Input
                        placeholder="Enter last name"
                        value={lastName}
                        onChange={(e) => {
                          const updatedValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                          setLastName(updatedValue);
                          form.setFieldsValue({ lastName: updatedValue });
                        }}
                      />
                    </Form.Item>
          
                    <Form.Item
                        name="phone"
                        rules={[
                            { required: true, message: 'Please input phone number!', }
                        ]}
                    >
                        <PhoneInput
                            country={'us'}
                            value={phone}
                            onChange={(value) => {
                                setPhone(value);
                                form.setFieldsValue({ phone: value });
                            }}
                            inputStyle={{ width: '100%' }}
                        />
                    </Form.Item>

                    <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }} justify="center">
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item name="driverCharter" valuePropName="checked">
                  <Checkbox
                    checked={driverCharter}
                    onChange={e => setDriverCharter(e.target.checked)}
                  >
                    <b>Driver Charter</b>
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item name="floridaState" valuePropName="checked">
                  <Checkbox
                    checked={isFloridaState}
                    onChange={e => setIsFloridaState(e.target.checked)}
                  >
                    <b>Florida state</b>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
                  </Col>
                </Row>
              );
            } else if (selectedRole === '4') {
            return (
                <Row justify="center" align="middle">
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                            name="firstName"
                            rules={[{ required: true, message: 'Please input first name!', },
                                { pattern: /^[a-zA-Z]*$/, message: 'Please enter letters only!', },
                            ]}>
                                <Input
                                    placeholder="Enter first name"
                                    value={firstName}
                                    onChange={(e) => {
                                        const updatedValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                        setFirstName(updatedValue);
                                        form.setFieldsValue({ firstName: updatedValue });
                                    }}
                                />
                        </Form.Item>

                        <Form.Item
                            name="lastName"
                            rules={[{ required: true, message: 'Please input last name!', },
                                { pattern: /^[a-zA-Z]*$/, message: 'Please enter letters only!', },
                            ]}>
                            <Input
                                placeholder="Enter last name"
                                value={lastName}
                                onChange={(e) => {
                                    const updatedValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                    setLastName(updatedValue);
                                    form.setFieldsValue({ lastName: updatedValue });
                                }}
                            />
                        </Form.Item>

                        <Form.Item name="password"
                            rules={[{ required: true, message: 'Please input password!', },
                                { whitespace: true, message: 'Password cannot contain spaces!', },
                            ]}>
                            <Input.Password placeholder="Enter password" />
                        </Form.Item>

                        <Form.Item name="confirmPassword"
                            rules={[{ required: true, message: 'Please confirm password!', },
                                { whitespace: true, message: 'Password cannot contain spaces!', },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('The two passwords do not match!')
                                        );
                                    },
                                }),
                            ]}>
                            <Input.Password placeholder="Confirm password" />
                        </Form.Item>

                        <Form.Item name="email"
                            rules={[{ required: true, message: 'Please input email!', },
                                { type: 'email', message: 'Please enter a valid email address!', },
                            ]}>
                            <Input placeholder="Enter email" />
                        </Form.Item>
                    </Col>
                </Row>
            );
        }
    }

    return (
        <Row justify="center">
            <Col xl={22} lg={22} md={22} sm={22} xs={22} className="loginForm">
                <Spin spinning={isLoading}>
                    <Form
                        form={form}
                        onFinish={userRegister}
                        layout="vertical"
                    >
                        <Row justify={'center'} align={'middle'}>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Title level={3} className='registrationLabel'>
                                    Who do you want to add?
                                </Title>
                            </Col>
                        </Row>

                        <Row gutter={{xs: 16, sm: 16, md: 16, lg: 16, xl: 16}}>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Form.Item name="role"
                                           rules={[{
                                               required: true, message: 'Please select your role!',
                                           },]}>
                                    <Select placeholder="Select your role" onChange={handleRoleChange}>
                                        <Option value="1">Super User</Option>
                                        <Option value="2">Dispatcher</Option>
                                        <Option value="3">Driver</Option>
                                        <Option value="4">Reciever</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        {renderFormFields()}

                        <Row gutter={{xs: 16, sm: 16, md: 16, lg: 16, xl: 16}} justify="center">
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={() => form.submit()}
                                    disabled={isClicked}
                                    loading={isLoading}
                                >
                                    Add {setRole()}
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </Spin>
            </Col>
        </Row>);
};

export default RegisterSuperUser;
