import React, { createContext, useState } from 'react';

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [clientData, setClientData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);  

  return (
    <ClientContext.Provider value={{ clientData, setClientData, isLoading, setIsLoading }}>
      {children}
    </ClientContext.Provider>
  );
};