import React, {useState, useContext, useEffect, useRef} from "react";
import api from '../../../restAPI';
import {AuthContext} from "../../../components/AuthContext";
import {Space, Input, Button, Layout, Typography, message, Spin, Row, Col, Form} from "antd";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const Login = () => {
    const {doLogin} = useContext(AuthContext);
    const {Footer, Content} = Layout;
    const {Title, Text} = Typography;
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [identifier, setIdentifier] = useState("");
    const [password, setPassword] = useState("");

    // Reference for the first input field
    const identifierRef = useRef(null);

    // Auto-focus the first input when the component mounts
    useEffect(() => {
        if (identifierRef.current) {
            identifierRef.current.focus();
        }
    }, []);

    const handleSubmit = async () => {
        setLoading(true);

        const errorMessage = 'Wrong username or password!';
        const isEmail = (input) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(input);
        };

        try {
            const emailOrPhone = isEmail(identifier) ? {email: identifier} : {phone: identifier};

            // Capture the current local time and convert it to UTC
            const utcTime = dayjs().utc().format();

            const response = await api.post('/signin', {
                ...emailOrPhone,
                password,
                lastLoginUtc: utcTime // Send UTC time to the backend
            });

            const {data} = response;
            doLogin(data);
        } catch (error) {
            let content = errorMessage;
            messageApi.open({type: 'error', content});
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout style={{minHeight: '100vh', justifyContent: 'center'}}>
            {contextHolder}
            <Content>
                {loading ? (
                    <Spin style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh'}}
                          size="large"/>
                ) : (
                    <Row justify="center" align="middle" style={{minHeight: '90vh'}}>
                        <Col xs={22} sm={18} md={12} lg={8} xl={6}>
                            <Space direction="vertical" style={{width: '100%'}}>
                                <Row justify="center">
                                    <Col>
                                        <Title level={3}
                                               style={{textAlign: 'center', marginBottom: '10px'}}>
                                            LOGISTIC
                                        </Title>
                                        <Text style={{textAlign: 'center', display: 'block', marginBottom: '20px'}}>by
                                            Empire City Laboratories</Text>
                                    </Col>
                                </Row>

                                <Form
                                    name="loginForm"
                                    layout="vertical"
                                    onFinish={handleSubmit}
                                    autoComplete="off"
                                    style={{maxWidth: '100%'}}
                                >
                                    <Form.Item
                                        name="identifier"
                                        rules={[{required: true, message: 'Please input your email or phone!'}]}
                                        initialValue={identifier}
                                    >
                                        <Input
                                            placeholder="Email or phone"
                                            value={identifier} // Ensure the input is always tied to the identifier state
                                            onChange={(e) => setIdentifier(e.target.value)}
                                            ref={identifierRef}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        rules={[{required: true, message: 'Please input your password!'}]}
                                    >
                                        <Input.Password
                                            placeholder="Password"
                                            value={password} // Ensure the input is always tied to the password state
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" block
                                                disabled={!identifier || !password}>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Space>
                        </Col>
                    </Row>
                )}
            </Content>
            <Footer style={{textAlign: 'center', padding: '10px 0'}}>
                <Text>2008-{dayjs().format('YYYY')} Empire City Laboratories, Inc.</Text>
            </Footer>
        </Layout>
    );
};

export default Login;
