import { useState, useEffect, React, useContext } from "react";
import axioss from '../../../restAPI';
import { AuthContext } from "../../../components/AuthContext";
import Loading from "../../../components/Loading";
import {  Descriptions } from 'antd';

const DriverInformation = (props) => {
    const { userID } = props
    const [loading, setLoading] = useState(false)
    const { authConfig } = useContext(AuthContext)
    const [data, setData] = useState([])

    const getData = async () => {
        try {
            setLoading(true)
            const response = await axioss.get(`/driver/${userID}`, authConfig)
            setData(response.data.driver)
        }
        catch (err) {
            console.log("Error while fetching driver information")
            console.log(err)
            // modal.warning({
            //     title: 'Something went wrong...',
            //     content: err.message,
            // });
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const items = [
        {
          key: data.code,
          label: 'Code',
          children: data.code,
        },
        {
          key: data.pick_up_type,
          label: 'Pick Up Type',
          children: data.pick_up_type,
        },
        {
          key: data.state,
          label: 'State',
          children: data.state,
        },
    ]


    return (
        <Loading loading={loading}>
            <Descriptions size="small" bordered items={items} column={1} style={{width:"400px"}}/>
        </Loading>
    )
}
export default DriverInformation