import React, {useEffect} from 'react';
import {Modal, Row, Col, Typography, Button, Input, Form, message, InputNumber} from 'antd';
import api from '../../../restAPI';

const {TextArea} = Input;

const WarningsModal = ({
                           isModalVisible,
                           selectedRowData,
                           handleModalClose,
                           token,
                           setData
                       }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (!isModalVisible) {
            form.resetFields();
        }
    }, [isModalVisible, form]);

    const handleSaveAndClose = async () => {
        try {
            const values = await form.validateFields();
            // console.log(values); // Handle form values here
            await submitWarning(selectedRowData.id, values.description, values.number, token);
            form.resetFields();  // Reset the form fields on successful submission
            handleModalClose();
        } catch (error) {
            console.log('Validation failed:', error);
        }
    };

    const submitWarning = async (orderId, description, number, token) => {
        try {
            const response = await api.put('/api/v2/receiver-actions/notify-about-issue/', {
                orderId,
                description,
                number
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.success) {
                message.success('Warning submitted successfully');
                // Optionally, update the state or remove the processed order from the list
                setData((prevData) => prevData.filter((order) => order.id !== orderId));
            } else {
                message.error('Failed to submit warning');
            }
        } catch (error) {
            console.error('Error submitting warning:', error);
            message.error('Error submitting warning');
            throw error;  // Re-throw the error to be caught by handleSaveAndClose
        }
    };

    return (
        <Modal
            title={<Typography.Title level={4}>
                Notify about the Issue for client: {selectedRowData && selectedRowData.clientDetails.id}
            </Typography.Title>}
            open={isModalVisible}
            onCancel={handleModalClose}
            closable={false}
            maskClosable={false}
            destroyOnClose={true}
            footer={[
                <Button key="close" onClick={handleModalClose}>
                    Close
                </Button>,
                <Button key="saveAndClose" type="primary" onClick={handleSaveAndClose}>
                    Save & Close
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
                <Row gutter={16}>
                    <Col span={24}>
                        <Typography.Paragraph>
                            Please provide details about the issue you encountered. This information will help us to
                            understand and resolve the problem more effectively.
                        </Typography.Paragraph>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label="Issue Details"
                            rules={[{required: true, message: 'Please describe the issue'}]}
                        >
                            <TextArea
                                rows={4}
                                placeholder="Describe the issue here..."
                                maxLength={500}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="number"
                            label="Number of Specimens"
                            rules={[{required: true, message: 'Please enter a number', type: 'number', min: 0}]}
                        >
                            <InputNumber
                                min={0}
                                placeholder="Enter a number"
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default WarningsModal;
