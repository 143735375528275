import React from 'react';
import {Button} from 'antd';
import {CarOutlined} from '@ant-design/icons';
import styled from 'styled-components';

const GoogleMapsButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4285f4;
    color: white;
    padding: 25px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin: 20px 0;

    &:hover {
        background-color: #2b6cbf;
    }

    &:active {
        background-color: #1a4b8f;
    }

    .anticon {
        margin-right: 10px;
    }
`;

const ActionButtons = ({userLocation, createdOrders, googleMapsLink}) => (
    (userLocation && createdOrders.length !== 0) && (
        <GoogleMapsButton
            href={googleMapsLink}
            target="_blank"
            rel="noopener noreferrer"
            icon={<CarOutlined/>}
        >
            Build the route (Google maps)
        </GoogleMapsButton>
    )
);

export default ActionButtons;
