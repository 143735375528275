import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {Modal, Upload, Image, Skeleton} from 'antd';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';

const isToday = (someDate) => {
    const today = new Date();
    return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
    );
};

const showModal = (title, content) => {
    Modal.info({
        title: title,
        content: content,
        centered: true,
        onOk: () => {
        },
    });
};

const beforeUpload = (file, additionalParams) => {
    const allowedTypes = [
        'image/png',
        'image/webp',
        'image/jpeg',
        'image/jpg',
        'image/heic'
    ];

    const isAllowedType = allowedTypes.includes(file.type);
    if (!isAllowedType) {
        showModal('Error', 'You can only upload PNG, WEBP, JPEG, JPG, or HEIC file!');
        return Upload.LIST_IGNORE;
    }
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
        showModal('Error', 'Image must be smaller than 20MB!');
        return Upload.LIST_IGNORE;
    }

    // Check if the image was taken today using lastModified date
    const fileLastModifiedDate = new Date(file.lastModified);
    if (!isToday(fileLastModifiedDate)) {
        showModal('Error', `Image must be taken today! Last Modified: ${fileLastModifiedDate.toLocaleDateString()}`);
        return Upload.LIST_IGNORE;
    }

    // Add additional parameters to the file object
    file.additionalParams = additionalParams;

    return true;
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const UploadComponent = ({token, currentOrderId, latitude, longitude}) => {
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const baseURL = process.env.NODE_ENV === 'development'
                    ? 'http://localhost:8002/api/v2/pickup-file-uploader/'
                    : 'https://log.linkingapimid.com/api/v2/pickup-file-uploader/';
                const response = await axios.get(`${baseURL}list-of-files/`, {
                    headers: {'Authorization': `Bearer ${token}`},
                    params: {order_id: currentOrderId}
                });

                const files = response.data.files.map(file => ({
                    uid: file.newFileName,
                    name: file.originalFileName,
                    status: 'done',
                    url: file.url,
                    metadata: {
                        name: file.newFileName, // Update metadata with the new file name
                        type: '', // Add appropriate type if available
                        size: 0, // Add appropriate size if available
                        lastModified: '', // Add appropriate lastModified if available
                    }
                }));
                setFileList(files);
                setInitialLoading(false);
            } catch (error) {
                showModal('Error', 'Failed to fetch files');
                setInitialLoading(false);
            }
        };
        fetchFiles();
    }, [currentOrderId, token]);

    const handleChange = (info) => {
        let newFileList = info.fileList.map(file => {
            if (file.response) {
                // Use the new filename returned from the server
                const newFileName = file.response.files[0].newFileName;
                file.url = process.env.NODE_ENV === 'development'
                    ? `http://localhost:8002/static/${newFileName}`
                    : `https://log.linkingapimid.com/static/${newFileName}`;
                file.metadata = {
                    name: newFileName, // Update metadata with the new file name
                    type: file.type,
                    size: file.size,
                    lastModified: new Date(file.lastModified).toLocaleDateString(),
                    ...file.additionalParams // Include additional parameters in metadata
                };
            }
            return file;
        });

        setFileList(newFileList);
        setLoading(info.file.status === 'uploading');
    };

    const handleRemove = async (file) => {
        try {
            const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8002/api/v2/pickup-file-uploader/' : 'https://log.linkingapimid.com/api/v2/pickup-file-uploader/';

            await axios.delete(baseURL, {
                headers: {'Authorization': `Bearer ${token}`},
                params: {filename: file.metadata.name} // Use the new file name (UUID) for deletion
            });
            setFileList(fileList.filter(item => item.uid !== file.uid));
            showModal('Success', 'File removed successfully');
        } catch (error) {
            showModal('Error', 'Failed to remove file');
        }
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>Upload photo</div>
        </div>
    );

    const additionalParams = {
        locationCords: JSON.stringify({latitude: latitude, longitude: longitude}),
        timestamp: new Date().toISOString(),
        order_id: currentOrderId,
    };

    const customActions = {
        showRemoveIcon: true,
        showPreviewIcon: true,
    };

    return (
        <>
            {initialLoading ? (
                <Skeleton active/>
            ) : (
                <Upload
                    multiple
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={customActions}
                    action={process.env.NODE_ENV === 'development' ? 'http://localhost:8002/api/v2/pickup-file-uploader/' : 'https://log.linkingapimid.com/api/v2/pickup-file-uploader/'}
                    beforeUpload={(file) => beforeUpload(file, additionalParams)}
                    onChange={handleChange}
                    onRemove={handleRemove}
                    onPreview={handlePreview}
                    headers={{'Authorization': `Bearer ${token}`}}
                    data={file => ({
                        locationCords: additionalParams.locationCords,
                        timestamp: additionalParams.timestamp,
                        order_id: additionalParams.order_id,
                        ...file.additionalParams
                    })}
                    fileList={fileList} // Set the fileList to the Upload component
                >
                    {fileList.length >= 9 ? null : uploadButton}
                </Upload>
            )}
            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </>
    );
};

export default UploadComponent;
