import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React from 'react';
import {Collapse, Row, Col, Typography, Tag, Button} from 'antd';
import {EnvironmentOutlined, CheckCircleOutlined, FireOutlined, PushpinOutlined} from '@ant-design/icons';
import './OrderCollapse.css';

const {Panel} = Collapse;
const {Text} = Typography;

dayjs.extend(utc);
dayjs.extend(timezone);

const AddressHeader = ({clientDetails, routeDetails, pickupType}) => {

    const getPickupTypeDetails = (pickupType) => {
        if (pickupType === 'SCHEDULED') {
            return {
                text: <><PushpinOutlined/>&nbsp;regular stop</>,
                color: 'grey'
            };
        } else if (pickupType === 'ONCALLROUTINE' || pickupType === 'ONCALLSTAT') {
            return {
                text: <><FireOutlined/>&nbsp;on call stop</>,
                color: 'red'
            };
        } else {
            return {text: pickupType, color: 'default'};
        }
    };

    const {text, color} = getPickupTypeDetails(pickupType);

    return <Row>

        <Col span={16} style={{textAlign: 'left'}}>
            <Tag bordered={false} color={color}>
                {text}
            </Tag>

            <br/>

            <Text>
                <strong>Address:</strong> {clientDetails?.street}, {clientDetails?.zip}
            </Text>
        </Col>
        <Col span={8} style={{textAlign: 'right'}}>
            {routeDetails && routeDetails?.code === null ?
                null :
                <Tag bordered={false} color="default">{routeDetails?.code}</Tag>
            }
        </Col>
    </Row>
};

const ClientDetails = ({item, clientDetails, clientScheduleDetails}) => (
    <div style={{margin: '10px 0 0 25px', textAlign: 'left'}}>
        <Text>
            <strong>Client ID:</strong> {clientDetails?.id}
        </Text>
        <br/>
        <Text>
            <strong>Client name:</strong> {clientDetails?.name}
        </Text>
        <br/>
        <Text>
            <strong>Client Phone:</strong> {clientDetails?.phone}
        </Text>
        <br/>

        {item?.type === 'ONCALLROUTINE' || item?.type === 'ONCALLSTAT'
            ? (
                <>
                    <Text>
                        {/*{dayjs.utc(item?.order_details?.time, "HH:mm:ss").tz('America/New_York').format('h:mm A')}*/}
                        <strong>Requested time by client:</strong>&nbsp;
                        {dayjs.utc(item?.order_details?.date).tz('America/New_York').format('MM/DD/YYYY')} &nbsp;
                        {dayjs(item?.order_details?.time, "HH:mm:ss").format('h:mm A')}
                    </Text>
                    <br/>
                    <Text>
                        <strong>Notes:</strong> &nbsp;
                        {item?.notes || item?.notes?.length === 0 ? item?.notes : 'No notes'}
                    </Text>
                </>

            )
            : <>
                <Text>
                    <strong>Time Delivery:</strong>&nbsp;
                    {dayjs(clientScheduleDetails?.details_open, "HH:mm").format('h:mm A')} -
                    {dayjs(clientScheduleDetails?.details_close, "HH:mm").format('h:mm A')}
                </Text>
                <br/>
                <Text>
                    <strong>Notes:</strong> {clientScheduleDetails?.details_notes?.length === 0
                    ? clientScheduleDetails?.details_notes
                    : 'No notes'}
                </Text>
            </>
        }


    </div>
);

const ActionIcons = ({item, handleOpenModal, openSuccessModal}) => (
    <div style={{marginTop: '20px', textAlign: 'left'}}>
        <Button
            // type="primary"
            size="large"
            onClick={(e) => {
                e.stopPropagation();
                openSuccessModal(item);
            }}
            icon={<CheckCircleOutlined style={{color: '#52c41a', fontSize: '20px'}}/>}
            style={{margin: '0 15px'}}
        >
            Report back
        </Button>


        <EnvironmentOutlined
            onClick={(e) => {
                e.stopPropagation();
                handleOpenModal(item.latitude, item.longitude);
            }}
            style={{fontSize: '24px', cursor: 'pointer', color: '#1890ff', margin: '0 15px'}}
        />

    </div>
);

const OrderCollapse = ({
                           item,
                           orderStatusesForDriver,
                           getStatusColor,
                           handleOpenModal,
                           openSuccessModal,
                           openFailModal
                       }) => (
    <Collapse style={{width: '100%', marginTop: '20px'}} size="small" bordered={false}>
        <Panel
            header={<AddressHeader clientDetails={item.clientDetails} routeDetails={item.routeDetails}
                                   pickupType={item.type}/>}
            key="1"
            // className="custom-collapse-header" // Add custom class here
            className={`custom-collapse-header ${
                item?.type === 'ONCALLROUTINE' ? 'on_call_routine' :
                    item?.type === 'ONCALLSTAT' ? 'on_call_stat' :
                        'regular_pickup'
            }`}

            style={{background: '#f7f7f7', borderRadius: '4px', border: '1px solid #e8e8e8', overflow: 'hidden'}}
        >


            <ClientDetails item={item} clientDetails={item.clientDetails}
                           clientScheduleDetails={item.clientScheduleDetails}/>
            <ActionIcons item={item} handleOpenModal={handleOpenModal} openSuccessModal={openSuccessModal}
                         openFailModal={openFailModal}/>
        </Panel>
    </Collapse>
);

export default OrderCollapse;