import React, {useState, useContext, useEffect} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Row, Col, Button, Spin, Typography, Modal, notification, Space, Tag, Input} from 'antd';
import {PlusOutlined, SearchOutlined} from '@ant-design/icons';
import api from '../../../restAPI';
import {AuthContext} from '../../../components/AuthContext';
import NewRoute from "./NewRoute";
import DetailsModal from './DetailsModal';
import EditRouteModal from './EditRouteModal';

const {Title} = Typography;

const RoutesMaintenance = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {token} = useContext(AuthContext);
    const [detailRoute, setDetailRoute] = useState(null);
    const [editRoute, setEditRoute] = useState(null);
    const [drivers, setDrivers] = useState([]);
    const [currentConnectedDrivers, setCurrentConnectedDrivers] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchData(1, true);
        fetchDrivers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchData(1, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const fetchData = async (page, reset = false) => {
        setIsLoading(true);
        try {
            const config = {
                headers: {Authorization: `Bearer ${token}`},
                params: {page, limit: 20, code: searchTerm}
            };
            const response = await api.get('/routes/', config);
            const routes = response.data.routes.sort((a, b) => new Date(b.created) - new Date(a.created));
            setData(prevData => reset ? routes : [...prevData, ...routes]);
            setPage(page);
            setHasMore(routes.length === 20);
        } catch (error) {
            console.error("API call failed", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDrivers = async () => {
        setIsLoading(true);
        try {
            const config = {headers: {Authorization: `Bearer ${token}`}};
            const response = await api.get('/driver/', config);
            const sortedDrivers = response.data.driver.sort((a, b) => a.id - b.id);
            setDrivers(sortedDrivers);
        } catch (error) {
            console.error("Failed to fetch drivers", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDetails = (route) => {
        const connectedDriversForRoute = drivers.filter(driver => driver.code && driver.code.includes(route.code));
        setCurrentConnectedDrivers(connectedDriversForRoute);
        setDetailRoute(route);
    };

    // eslint-disable-next-line no-unused-vars
    const handleEditClick = (route) => {
        setEditRoute(route);
    };

    const handleEditSubmit = async () => {
        setIsLoading(true);
        try {
            const config = {headers: {Authorization: `Bearer ${token}`}};
            const response = await api.put(`/routes/${editRoute.id}`, editRoute, config);
            if (response.status === 200) {
                notification.success({message: 'Route updated successfully!'});
                fetchData(1, true);
            } else {
                throw new Error('Failed to update route');
            }
        } catch (error) {
            notification.error({message: 'Error updating route!'});
        } finally {
            setIsLoading(false);
            setEditRoute(null);
        }
    };

    // eslint-disable-next-line no-unused-vars
    const handleDeleteClick = (routeId) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this route?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                setIsLoading(true);
                try {
                    const config = {headers: {Authorization: `Bearer ${token}`}};
                    const response = await api.delete(`/routes/${routeId}`, config);
                    if (response.data.status === 'Success') {
                        notification.success({message: 'Route deleted successfully!'});
                        fetchData(1, true);
                    } else {
                        throw new Error('Failed to delete route');
                    }
                } catch (error) {
                    notification.error({message: 'Error deleting route!'});
                } finally {
                    setIsLoading(false);
                }
            }
        });
    };

    const handleCreateRouteClick = () => {
        setIsModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const handleModalSubmit = () => {
        setIsModalVisible(false);
        fetchData(1, true);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <Spin spinning={isLoading} tip="Loading..." size='large'>
            <Row justify="center" align="middle">
                <Col xl={23} lg={23} md={23} sm={24} xs={24}>
                    <Title level={2} className="mainLabel">Routes Maintenance</Title>
                    <Row justify="space-between" align="middle" style={{marginBottom: '20px'}}>
                        <Col>
                            <Button onClick={handleCreateRouteClick} type="primary" className='createRouteBtn'>
                                <PlusOutlined style={{marginRight: '4px'}}/> New Route
                            </Button>
                        </Col>
                        <Col>
                            <Input
                                placeholder="Search by Route Code"
                                prefix={<SearchOutlined/>}
                                onChange={handleSearch}
                                value={searchTerm}
                            />
                        </Col>
                    </Row>

                    <InfiniteScroll
                        style={{overflow: 'auto', padding: 10}}
                        dataLength={data.length}
                        next={() => fetchData(page + 1)}
                        hasMore={hasMore}
                        loader={<Spin spinning={true}/>}
                        endMessage={<p style={{textAlign: 'center'}}><b>No more routes to load</b></p>}
                    >
                        {data.map(route => (
                            <div key={route.id}
                                 style={{border: '1px solid #f0f0f0', padding: '6px', boxSizing: 'border-box'}}>
                                <Row gutter={16} align="middle" justify="space-between" style={{
                                    color: 'black',
                                    border: '1px dashed black',
                                    padding: '6px',
                                    borderRadius: '10px',
                                    boxSizing: 'border-box'
                                }}>
                                    <Col>{route.code} - {route.name} ({route.region})</Col>
                                    <Col>
                                        <Space>
                                            <Button onClick={() => handleDetails(route)} style={{
                                                color: 'green',
                                                border: '1px solid green'
                                            }}>Management</Button>
                                            {/*<Button onClick={() => handleEditClick(route)}*/}
                                            {/*        style={{color: 'blue', border: '1px solid blue'}}*/}
                                            {/*        icon={<EditOutlined/>}/>*/}
                                            {/*<Button onClick={() => handleDeleteClick(route.id)} danger*/}
                                            {/*        icon={<DeleteOutlined/>}/>*/}
                                        </Space>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </InfiniteScroll>
                </Col>
            </Row>

            <DetailsModal
                detailRoute={detailRoute}
                closeDetailsModal={() => setDetailRoute(null)}
                allDrivers={drivers}
                connectedDrivers={currentConnectedDrivers}
                fetchData={() => fetchData(1, true)}
            />

            <EditRouteModal
                editRoute={editRoute}
                handleEditChange={(e, field) => setEditRoute({...editRoute, [field]: e.target.value})}
                closeEditModal={() => setEditRoute(null)}
                handleEditSubmit={handleEditSubmit}
            />

            <Modal
                destroyOnClose={true}
                title="Create New Route"
                open={isModalVisible}
                onCancel={handleModalCancel}
                footer={null}
            >
                <NewRoute handleModalSubmit={handleModalSubmit} drivers={drivers.map(driver => ({
                    value: driver.id,
                    label: (
                        <span>
                            <Tag color="red">{driver.id}</Tag>{driver.first_name} {driver.last_name}
                        </span>
                    ),
                }))} fetchData={() => fetchData(1, true)}/>
            </Modal>
        </Spin>
    );
};

export default RoutesMaintenance;
