import React, {useState, useEffect, useCallback} from 'react';
import {Row, Col, Select, DatePicker, Form, Input, Typography} from 'antd';
import dayjs from 'dayjs';
import {useNavigate, useLocation} from 'react-router-dom';
import api from '../../../restAPI';

const {Option} = Select;
const {Text} = Typography;

const FilterPanel = ({filters, onFilter, token}) => {
    const [routes, setRoutes] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [localFilters, setLocalFilters] = useState(filters);
    const navigate = useNavigate();
    const location = useLocation();

    const updateURLParams = (updatedFilters) => {
        const params = new URLSearchParams();
        Object.entries(updatedFilters).forEach(([key, value]) => {
            if (value !== undefined && value !== null && value !== '') {
                if (!(key === 'selectedDate' && updatedFilters.dateDisabled === 'true')) {
                    params.set(key, value);
                }
            }
        });
        navigate(`${location.pathname}?${params.toString()}`, {replace: true});
    };
    const handleChange = (key, value) => {
        let updatedFilters = {
            ...localFilters,
            [key]: value,
        };

        if (key === 'selectedDate' && !value) {
            // If the date is cleared, remove the date from filters and add a flag
            delete updatedFilters.selectedDate;
            updatedFilters.dateDisabled = 'true'; // Flag to indicate date is disabled
        } else if (key === 'selectedDate') {
            // If a date is selected, remove the dateDisabled flag
            updatedFilters.selectedDate = dayjs(value).format('MM-DD-YYYY');
            delete updatedFilters.dateDisabled;
        }

        setLocalFilters(updatedFilters);
        onFilter(updatedFilters);
        updateURLParams(updatedFilters); // Update the URL with new filters
    };


    const handleRouteChange = (value) => {
        handleChange('routeCodeSearch', value);
        if (!value) {
            handleChange('routeCodeSearch', '');
        }
    };

    const handleDriverChange = (value) => {
        handleChange('driverIdSearch', value);
    };

    const fetchRoutes = useCallback(async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await api.get('/routes/?limit=1000', config);

            if (Array.isArray(response.data.routes)) {
                setRoutes(response.data.routes);
            } else {
                console.error('Expected routes data to be an array but received:', response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    const getDrivers = useCallback(async () => {
        try {
            setLoading(true);
            const response = await api.get('/driver/drivers-list/', {
                headers: {Authorization: `Bearer ${token}`}
            });
            setDrivers(response.data.drivers);
        } catch (err) {
            console.error("Error while fetching drivers", err);
        } finally {
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        fetchRoutes();
        getDrivers();
    }, [fetchRoutes, getDrivers]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const urlFilters = {};
        searchParams.forEach((value, key) => {
            urlFilters[key] = value;
        });

        // // Set defaults only if they are not provided in the URL
        // if (!urlFilters.hasOwnProperty('statusFilter')) {
        //     urlFilters.statusFilter = 'HANDED_OVER_TO_ACCESSION';
        // }
        // if (!urlFilters.hasOwnProperty('selectedDate') && !urlFilters.hasOwnProperty('dateDisabled')) {
        //     urlFilters.selectedDate = dayjs().format('YYYY-MM-DD');
        // }

        setLocalFilters((prev) => ({
            ...prev,
            ...urlFilters,
        }));

        onFilter(urlFilters);
        updateURLParams(urlFilters);  // Sync URL with filters
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    return (
        <Row gutter={[16, 16]} style={{marginBottom: 25}}>
            <Col span={24}>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Text type="secondary">By date</Text>
                        <DatePicker
                            placeholder="Select Date"
                            onChange={(date) => handleChange('selectedDate', date ? dayjs(date) : null)}
                            value={localFilters.selectedDate ? dayjs(localFilters.selectedDate) : null}
                            style={{width: '100%'}}
                            allowClear
                        />
                    </Col>

                    <Col span={6}>
                        <Text type="secondary">By status</Text>
                        <Select
                            placeholder="Status"
                            onChange={(value) => handleChange('statusFilter', value)}
                            value={localFilters.statusFilter || undefined}
                            style={{width: '100%'}}
                        >
                            <Option value="HANDED_OVER_TO_ACCESSION">HANDED OVER</Option>
                            <Option value="CONFIRMED">CONFIRMED</Option>
                            <Option value="RECEIVED_WITH_ISSUE">RECEIVED WITH ISSUE</Option>
                        </Select>
                    </Col>

                    {/* Route Filter */}
                    <Col span={6}>
                        <Form.Item name="route">
                            <Text type="secondary">By route code</Text>
                            <Select
                                placeholder="Select a route"
                                showSearch
                                onChange={handleRouteChange}
                                value={localFilters.routeCodeSearch || undefined}
                                className="option"
                                filterOption={(input, option) =>
                                    (option.children && option.children.toLowerCase().includes(input.toLowerCase()))
                                }
                                allowClear
                            >
                                {(routes || []).map((route) => (
                                    <Option key={route.code} value={route.code}>
                                        <span>{route.code} - {route.name}</span>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* Driver ID Filter */}
                    <Col span={6}>
                        <Form.Item name="driverId">
                            <Text type="secondary">By driver</Text>
                            <Select
                                showSearch
                                placeholder="Select Driver"
                                onChange={handleDriverChange}
                                onSearch={(value) => handleChange('driverSearchInput', value)}
                                value={localFilters.driverIdSearch || undefined}  // Ensure placeholder shows when no value is selected
                                filterOption={(input, option) =>
                                    option.children && option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                style={{width: '100%'}}
                                loading={loading}
                                allowClear
                            >
                                {drivers.map(driver => (
                                    <Option key={driver.id} value={driver.id}>
                                        {`${driver.first_name} ${driver.last_name}`}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
            </Col>

            <Col span={24}>
                <Row gutter={[24, 24]}>
                    <Col span={4}>
                        <Form.Item name="clientId">
                            <Text type="secondary">By client ID</Text>
                            <Input
                                placeholder="Enter Client ID"
                                type="number"
                                value={localFilters.clientIdSearch}
                                onChange={(e) => handleChange('clientIdSearch', e.target.value)}
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    </Col>

                    {/* Street Filter */}
                    <Col span={6}>
                        <Form.Item name="street">
                            <Text type="secondary">By client street</Text>
                            <Input
                                placeholder="Enter Street"
                                value={localFilters.street}
                                onChange={(e) => handleChange('street', e.target.value)}
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    </Col>

                    {/* City Filter */}
                    <Col span={6}>
                        <Form.Item name="city">
                            <Text type="secondary">By client city</Text>
                            <Input
                                placeholder="Enter City"
                                value={localFilters.city}
                                onChange={(e) => handleChange('city', e.target.value)}
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    </Col>

                    {/* State Filter */}
                    <Col span={4}>
                        <Form.Item name="state">
                            <Text type="secondary">By client state</Text>
                            <Input
                                placeholder="Enter State"
                                value={localFilters.stateFilter}
                                onChange={(e) => handleChange('stateFilter', e.target.value)}
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    </Col>

                    {/* ZIP Filter */}
                    <Col span={4}>
                        <Form.Item name="zip">
                            <Text type="secondary">By client zip code</Text>
                            <Input
                                placeholder="Enter ZIP"
                                value={localFilters.zip}
                                onChange={(e) => handleChange('zip', e.target.value)}
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>

        </Row>
    );
};

export default FilterPanel;

