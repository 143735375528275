import DriverInformation from "../Drivers/driverInformation"
import { Space, } from 'antd';
import MyCollapse from "../../../components/Collapse";
import RowSetNewPassword from "./rowSetNewPassword";
import RowEditUser from "./rowEditUser";

const TableRow = (props) => {
    const { record } = props
    return (
        <Space wrap size={'large'} align="top">
            {record.role === 3 && (
                <MyCollapse child={<DriverInformation userID={record.id} />} title="Driver Information" />
            )}

            <MyCollapse child={<EditUser record={record} />} title="Edit User" />

            <MyCollapse child={<ManageUser userID={record.id} />} title="Manage User" collapsed/>

        </Space>
    )
}

export default TableRow



const ManageUser = (props) => {
    const { userID } = props
    return (
        <RowSetNewPassword userID={userID} />
    )
}

const EditUser = (props) => {
    const { record } = props
    return (
        <RowEditUser record={record} />
    )
}
