import React, {createContext, useContext, useState, useEffect, useCallback} from 'react';
import {AuthContext} from '../AuthContext';
import {App, Typography} from 'antd';
import {useNavigate} from "react-router-dom";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({children}) => {
    const navigate = useNavigate();
    const {socket, userRole} = useContext(AuthContext);
    const {notification, modal} = App.useApp();
    const [notifications, setNotifications] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [audioEnabled, setAudioEnabled] = useState(false);

    const playAudio = useCallback(() => {
        if (audioEnabled) {
            const audio = new Audio('/beep.mp3');
            audio.volume = 0.5;
            audio.play().catch(e => console.error("Failed to play audio:", e));
        }
    }, [audioEnabled]);

    const addNotification = useCallback((messageText) => {
        setNotifications((prevNotifications) => [...prevNotifications, messageText]);
        notification.info({
            message: 'Notification',
            description: messageText,
        });
    }, [notification]);

    const handlePing = useCallback((messageData) => {
        const newMessage = `[Ping] Received at ${messageData.time}`;
        addNotification(newMessage);
        socket.emit('pong', {status: 'Received', time: new Date().toISOString()});
        playAudio();
    }, [socket, playAudio, addNotification]);

    const handleNewEvent = useCallback((event) => {
        const newMessage = event.f1 ? `[DB Event]: ${event.f1}` : `[DB Event]: ${JSON.stringify(event, null, 2)}`;
        if (newMessage.includes('PICKED_UP_WITH_ISSUES')) {
            setModalMessage(newMessage);
            setModalVisible(true);
            // modal.warning({title: 'Issue Detected', content: newMessage});
            modal.warning({
                title: 'Issue Detected',
                content: (
                    <>
                        <Typography.Paragraph>
                            We need your attention!
                        </Typography.Paragraph>

                        {/*<p>{newMessage}</p>*/}
                    </>
                ),
                okText: 'Go to Pay Attention page',
                onOk() {
                    setModalVisible(false);
                    navigate('/pay-attention-page');
                },
                okButtonProps: {
                    type: 'primary',
                },
            });
        } else {
            addNotification(newMessage);
        }
        playAudio();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playAudio, addNotification, modal]);

    useEffect(() => {
        if (!socket || (userRole !== 1 && userRole !== 2)) {
            return;
        }

        socket.on('ping', handlePing);
        socket.on('new-event', handleNewEvent);


        return () => {
            socket.off('ping', handlePing);
            socket.off('new-event', handleNewEvent);
        };
    }, [socket, handlePing, handleNewEvent, userRole]);

    return (
        <SocketContext.Provider
            value={{notifications, modalVisible, modalMessage, setModalVisible, audioEnabled, setAudioEnabled}}>
            {children}
        </SocketContext.Provider>
    );
};
