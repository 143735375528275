export const orderStatuses = {
    NOT_ASSIGNED: 'NOT_ASSIGNED',
    CREATED: 'CREATED',
    PICKED_UP: 'PICKED_UP',
    PICKED_UP_WITH_ISSUES: 'PICKED_UP_WITH_ISSUES',
    CONFIRMED: 'CONFIRMED',
    CANCELED: 'CANCELED',
    HANDED_OVER: 'HANDED_OVER_TO_ACCESSION',
    DROPPED_DOWN: 'DROPPED_DOWN',
    PUSHED_TO_AIR_BILL: 'PUSHED_TO_AIR_BILL',
    RECEIVED_WITH_ISSUE: 'RECEIVED_WITH_ISSUE',
}

export const orderStatusesForDriver = {
    NOT_ASSIGNED: 'NOT_ASSIGNED',
    CREATED: 'CREATED',
    PICKED_UP: 'PICKED_UP',
    PICKED_UP_WITH_ISSUES: 'PICKED_UP_WITH_ISSUES',
    CONFIRMED: 'CONFIRMED',
    CANCELED: 'CANCELED',
    HANDED_OVER: 'HANDED_OVER_TO_ACCESSION',
    DROPPED_DOWN: 'DROPPED_DOWN',
    PUSHED_TO_AIR_BILL: 'PUSHED_TO_AIR_BILL'
}

export const orderTypesForDriver = {
    SCHEDULED: 'SCEDULED',
    ONCALLSTAT: 'ONCALL',
    ONCALLROUTINE: 'ONCALL'
}
