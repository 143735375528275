import React, {useRef, useEffect} from 'react';
import './WeeklyScheduleTimeLine.css';

const ScrollSyncContainer = ({children}) => {
    const scrollContainerRef = useRef(null);
    const mirrorScrollContainerRef1 = useRef(null);
    const mirrorScrollContainerRef2 = useRef(null);

    const isDown = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    const syncScroll = (e, source) => {
        const syncElements = [scrollContainerRef, mirrorScrollContainerRef1, mirrorScrollContainerRef2];
        syncElements.forEach((ref) => {
            if (ref.current && ref !== source) {
                ref.current.scrollLeft = e.target.scrollLeft;
            }
        });
    };

    useEffect(() => {
        const topRef = mirrorScrollContainerRef1.current;
        const middleRef = scrollContainerRef.current;
        const bottomRef = mirrorScrollContainerRef2.current;

        if (topRef && middleRef && bottomRef) {
            const handleTopScroll = (e) => syncScroll(e, mirrorScrollContainerRef1);
            const handleMiddleScroll = (e) => syncScroll(e, scrollContainerRef);
            const handleBottomScroll = (e) => syncScroll(e, mirrorScrollContainerRef2);

            topRef.addEventListener('scroll', handleTopScroll);
            middleRef.addEventListener('scroll', handleMiddleScroll);
            bottomRef.addEventListener('scroll', handleBottomScroll);

            // Set the width of the custom scrollbars
            const setScrollbarWidth = () => {
                const contentWidth = middleRef.scrollWidth;
                topRef.firstChild.style.width = `${contentWidth}px`;
                bottomRef.firstChild.style.width = `${contentWidth}px`;
            };

            // Set the width initially and on window resize
            setScrollbarWidth();
            window.addEventListener('resize', setScrollbarWidth);

            return () => {
                topRef.removeEventListener('scroll', handleTopScroll);
                middleRef.removeEventListener('scroll', handleMiddleScroll);
                bottomRef.removeEventListener('scroll', handleBottomScroll);
                window.removeEventListener('resize', setScrollbarWidth);
            };
        }
    }, []);

    const mouseDownHandler = (e) => {
        isDown.current = true;
        startX.current = e.pageX - scrollContainerRef.current.offsetLeft;
        scrollLeft.current = scrollContainerRef.current.scrollLeft;
    };

    const mouseLeaveHandler = () => {
        isDown.current = false;
        document.body.style.userSelect = 'auto'; // Re-enable text selection
    };

    const mouseUpHandler = () => {
        isDown.current = false;
        document.body.style.userSelect = 'auto'; // Re-enable text selection
    };

    const mouseMoveHandler = (e) => {
        if (!isDown.current) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX.current) * 1.5; //scroll-fast
        scrollContainerRef.current.scrollLeft = scrollLeft.current - walk;

        // Disable text selection
        document.body.style.userSelect = 'none';
    };

    return (
        <>
            <div className="custom-scrollbar-container">
                <div className="custom-scrollbar" ref={mirrorScrollContainerRef1}>
                    <div style={{height: '20px'}}/>
                </div>
            </div>

            <div
                className="weekly-schedule"
                ref={scrollContainerRef}
                onMouseDown={mouseDownHandler}
                onMouseLeave={mouseLeaveHandler}
                onMouseUp={mouseUpHandler}
                onMouseMove={mouseMoveHandler}
                style={{overflowX: 'auto', whiteSpace: 'nowrap', scrollbarWidth: 'none', msOverflowStyle: 'none'}}
            >
                {children}
            </div>

            <div className="custom-scrollbar-container">
                <div className="custom-scrollbar" ref={mirrorScrollContainerRef2}>
                    <div style={{height: '20px'}}/>
                </div>
            </div>
        </>
    );
};

export default ScrollSyncContainer;
