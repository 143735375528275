import React from 'react';
import api from "../../../restAPI";
import {Button, message} from 'antd';

const UpdateClientCoordinatesButton = ({clientId, token}) => {
    const handleClick = async () => {
        try {
            const response = await api.post('/clients/update-client-coordinates/', {clientId}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.success) {
                message.success('Client coordinates updated successfully');
            } else {
                message.error(response.data.error || 'Failed to update client coordinates');
            }
        } catch (error) {
            message.error('Error updating client coordinates');
        }
    };

    return (
        <Button type="primary" onClick={handleClick}>
            Update Coordinates for client {clientId}
        </Button>
    );
};

export default UpdateClientCoordinatesButton;
