import React, {useState, useEffect, useContext, useCallback} from 'react';
import {
    Row,
    Col,
    Typography,
    Descriptions,
    Spin,
    DatePicker,
    TimePicker,
    Select,
    Button,
    Form,
    Input,
    Divider,
    Tag,
    notification
} from 'antd';
import {ArrowDownOutlined, ClockCircleOutlined, InfoCircleOutlined, UnorderedListOutlined} from "@ant-design/icons"
import api from '../../../restAPI';
import {AuthContext} from '../../../components/AuthContext';
import moment from 'moment';

const {Title} = Typography;
const {Option} = Select;
const {TextArea} = Input;

const CreatePickUp = ({client, closeModal}) => {
    const {token} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [isStatType, setIsStatType] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [routes, setRoutes] = useState([]);
    const {id, name, city, street, state, zip} = client;

    const onFinish = async (values) => {
        try {
            setIsLoading(true);
            const {date, time, type, ...rest} = values;
            const dateTime = moment(date.format('YYYY-MM-DD') + 'T' + time.format('HH:mm:ss')).toISOString();
            const order = {
                clientId: id,
                notes: rest.notes,
                orderType: type,
                date: dateTime,
                time: time.format('HH:mm:ss'),
                ...rest,
            };
            const endpoint = type === 'S' ? '/orders/create-on-call-stat/' : '/orders/create-on-call-routine/';
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            await api.post(endpoint, order, config);

            notification.success({
                message: 'Success',
                description: 'The order was successfully created!',
            });

            setIsLoading(false);
            form.resetFields();
            closeModal();
        } catch (error) {
            setIsLoading(false);

            notification.error({
                message: 'Error',
                description: 'There was an error while creating the order!',
            });

            console.error(error);
        }
    };

    const handleTypeChange = (value) => {
        setIsStatType(value === 'S');
    };

    const fetchRoutes = useCallback(async () => {
        try {
            const config = {
                headers: {Authorization: `Bearer ${token}`},
                params: {limit: 2000}
            };
            const response = await api.get('/routes/', config);
            if (Array.isArray(response.data.routes)) {
                setRoutes(response.data.routes);
            } else {
                console.error('Unexpected API response structure:', response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }, [token]);


    const filterOptionForRoutes = (input, option) => {
        const routeData = routes.find(route => route.id === option.value);
        if (!routeData) return false;
        const routeCode = String(routeData.code).toLowerCase();
        const routeDriverId = String(routeData.driver_id).toLowerCase();
        return (
            routeCode.includes(input.toLowerCase()) || routeDriverId.includes(input.toLowerCase())
        );
    };

    useEffect(() => {
        fetchRoutes();
    }, [fetchRoutes]);

    useEffect(() => {
        const fetchSchedule = async () => {
            setIsLoading(true);
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                await api.get(`/client-schedule/?clientID=${client.id}`, config);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSchedule();
    }, [token, client, setIsLoading]);

    return (
        <Row justify="center" align="middle">
            <Col xl={22} lg={22} md={22} sm={22} xs={22}>
                <Spin spinning={isLoading} tip="Loading..." size="large">
                    <Row justify="center" align="middle">
                        <Col span={24} style={{textAlign: 'center'}}>
                            <Title>{name}</Title>
                        </Col>
                        <Col span={24} style={{textAlign: 'center'}}>
                            <Descriptions>
                                <Descriptions.Item label="Client ID">{id}</Descriptions.Item>
                                <Descriptions.Item label="Address">
                                    {street} / {city} / {state} / {zip}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>

                    <Divider plain
                             style={{color: 'rgba(0, 0, 0, 0.4)', marginTop: '-10px'}}><ClockCircleOutlined/> Choose
                        date & time and order type</Divider>
                    <Form form={form} onFinish={onFinish}>
                        <Row justify="center" align="middle" gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Date" name="date"
                                           rules={[{required: true, message: 'Please select a date!'}]}>
                                    <DatePicker format="MM-DD-YYYY"/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Time" name="time"
                                           rules={[{required: true, message: 'Please select a time!'}]}>
                                    <TimePicker format="HH:mm A"/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Order type" name="type"
                                           rules={[{required: true, message: 'Please select a type!'}]}>
                                    <Select placeholder="Select type" onChange={handleTypeChange}>
                                        <Option value="R">Routine</Option>
                                        <Option value="S">Stat</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        {isStatType && (
                            <>
                                <Divider plain style={{
                                    color: 'rgba(0, 0, 0, 0.4)',
                                    marginTop: '-10px'
                                }}><InfoCircleOutlined/> Add more information</Divider>
                                <Row justify="center" align="middle" gutter={16}>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Patient Last Name"
                                            name="patientLastName"
                                            rules={[
                                                {required: true, message: 'Fill in the fields!'},
                                                {pattern: /^[A-Za-z]+$/, message: 'Please enter letters only!'},
                                            ]}
                                        >
                                            <Input
                                                onChange={(e) => {
                                                    const updatedValue = e.target.value.replace(/[^A-Za-z]/g, '');
                                                    e.target.value = updatedValue;
                                                    form.setFieldsValue({patientLastName: updatedValue});
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Patient First Name"
                                            name="patientFirstName"
                                            rules={[
                                                {required: true, message: 'Fill in the fields!'},
                                                {pattern: /^[A-Za-z]+$/, message: 'Please enter letters only!'},
                                            ]}
                                        >
                                            <Input
                                                onChange={(e) => {
                                                    const updatedValue = e.target.value.replace(/[^A-Za-z]/g, '');
                                                    e.target.value = updatedValue;
                                                    form.setFieldsValue({patientFirstName: updatedValue});
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Patient DOB" name="patientDOB"
                                                   rules={[{required: true, message: 'Fill in the fields!'}]}>
                                            <DatePicker format="MM-DD-YYYY" style={{width: '100%'}}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify="center" align="middle" gutter={16}>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Contact Name"
                                            name="contactName"
                                            rules={[
                                                {required: true, message: 'Fill in the fields!'},
                                                {pattern: /^[A-Za-z]+$/, message: 'Please enter letters only!'},
                                            ]}
                                        >
                                            <Input
                                                onChange={(e) => {
                                                    const updatedValue = e.target.value.replace(/[^A-Za-z]/g, '');
                                                    e.target.value = updatedValue;
                                                    form.setFieldsValue({contactName: updatedValue});
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Contact Phone"
                                            name="contactPhone"
                                            rules={[
                                                {required: true, message: 'Fill in the fields!'},
                                                {pattern: /^[0-9]+$/, message: 'Please enter numbers only!'},
                                            ]}
                                        >
                                            <Input
                                                onChange={(e) => {
                                                    let updatedValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                                    form.setFieldsValue({contactPhone: updatedValue});
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Results By" name="resultsBy"
                                                   rules={[{required: true, message: 'Fill in the fields!'}]}>
                                            <Select placeholder="Select">
                                                <Option value="Whithin 4 hrs">Within 4 hours</Option>
                                                <Option value="Following Morning">Following morning</Option>
                                                <Option value="End of the Day">End of the day</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify="center" align="middle" gutter={16}>
                                    <Col>
                                        <Form.Item label="Select STAT Tests" name="statTest"
                                                   rules={[{required: true, message: 'Please select a type!'}]}>
                                            <Select mode="multiple" placeholder="Select" style={{width: '400px'}}>
                                                <Option value="102">(102) BETA HCG</Option>
                                                <Option value="88">(88) BILIRUBIN, TOTAL</Option>
                                                <Option value="P3">(P3) CBC WITH DIFFERENTIAL</Option>
                                                <Option value="P39">(P39) PROTHROMBIN TIME (PT)</Option>
                                                <Option value="P6">(P6) CMP (COMPREHENSIVE METABOLIC PANEL)</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}

                        <Divider plain
                                 style={{color: 'rgba(0, 0, 0, 0.4)', marginTop: '-10px'}}><ArrowDownOutlined/> Add
                            route</Divider>
                        <Row justify="center" align="middle">
                            <Form.Item
                                name="assign"
                                rules={[{required: true, message: 'Please add route!'}]}
                            >
                                <Select
                                    showSearch
                                    style={{width: "700px",}}
                                    onChange={(value) => setSelectedRoute(value)}
                                    value={selectedRoute}
                                    className="option"
                                    filterOption={filterOptionForRoutes}
                                >
                                    {routes.map((route) => (
                                        <Option key={route.id} value={route.id}>
        <span>
            <Tag color="magenta"><b>{route.code}</b></Tag>
            <Tag color="purple">{route.name}</Tag>
            <Tag color="cyan">{route.region}</Tag>    
            <Tag color="blue">{route.driver_id}</Tag> 
        </span>
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Row>

                        <Divider plain
                                 style={{color: 'rgba(0, 0, 0, 0.4)', marginTop: '-10px'}}><UnorderedListOutlined/> Add
                            notes</Divider>
                        <Row justify="center" align="middle">
                            <Col span={24}>
                                <Form.Item label="Notes" name="notes">
                                    <TextArea autoSize={{minRows: 2, maxRows: 4}}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify="center" align="middle">
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Create
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Col>
        </Row>
    );
};

export default CreatePickUp;
