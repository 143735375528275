import React from "react";
import {Row, Col, Typography} from 'antd';
import DriversList from "../../../components/ZelenchukSerhii/NewDriversList";

const Dashboard = () => {
    return (
        <Row justify="center">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Typography.Title level={4} style={{marginTop: 20}}>
                    Dashboard
                </Typography.Title>
            </Col>
            <Col>
                <DriversList/>
            </Col>
        </Row>
    );
}

export default Dashboard;
