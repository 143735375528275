import React, {useState, useContext, useEffect, useCallback, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {Row, Col, Typography, notification, Spin, Pagination} from 'antd';
import api from '../../../restAPI';
import {AuthContext} from '../../../components/AuthContext';
import DetailsModal from './DetailsModal';
import buildQueryParams from '../../../services/utilities/buildQueryParams';
import dayjs from 'dayjs';
import {OrderTable} from './OrderTable';
import FilterPanel from './FilterPanel';
import ReceivingModal from "./ReceivingModal";
import WarningsModal from "./WarningsModal";

const {Title} = Typography;

const CustomPagination = ({pagination, setPagination, totalItems}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 25}}>
            {totalItems > 0 && (
                <>
                    <Row justify="center" align="middle">
                        <Col style={{display: 'flex', alignItems: 'center'}}>
                            <Pagination
                                responsive={true}
                                current={Math.floor(pagination.offset / pagination.limit) + 1}
                                total={totalItems}
                                pageSize={pagination.limit}
                                showSizeChanger={true}
                                pageSizeOptions={['10', '20', '50', '100', '500']}
                                onChange={(page, pageSize) => {
                                    setPagination(prevState => ({
                                        ...prevState,
                                        limit: pageSize,
                                        offset: (page - 1) * pageSize,
                                    }));
                                }}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

const ReceiverDashboard = () => {
    const location = useLocation();
    const {token} = useContext(AuthContext);
    const [data, setData] = useState([]);

    const [selectedRowData, setSelectedRowData] = useState(null);
    const [visibleModal, setVisibleModal] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [routes, setRoutes] = useState([]);
    const [selectKey, setSelectKey] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [status, setStatus] = useState(null);
    const [filters, setFilters] = useState({
        clientIdSearch: "",
        clientNameSearch: "",
        statusFilter: "HANDED_OVER_TO_ACCESSION",
        typeFilter: "",
        selectedDate: dayjs().format('MM-DD-YYYY'),
        street: "",
        city: "",
        stateFilter: "",
        zip: "",
        driverIdSearch: "",
        routeCodeSearch: ""
    });

    const [pagination, setPagination] = useState({
        limit: 10,
        offset: 0,
    });
    const [totalItems, setTotalItems] = useState(0);

    const initialRender = useRef(true);

    const showModal = (modalType) => {
        setVisibleModal(modalType);
    };

    const closeModal = () => {
        setVisibleModal(null);
    };

    const fetchData = useCallback(async (currentFilters) => {
        setIsLoading(true);

        // Set default filters if none are provided
        const defaultFilters = {
            statusFilter: "HANDED_OVER_TO_ACCESSION",
            selectedDate: dayjs().format('MM-DD-YYYY'),
        };

        // Combine current filters with default filters
        const appliedFilters = {
            ...defaultFilters,
            ...currentFilters, // URL filters will override defaults if provided
        };

        // Exclude selectedDate if dateDisabled is true
        if (appliedFilters.dateDisabled === 'true') {
            delete appliedFilters.selectedDate;
        }

        const config = {headers: {Authorization: `Bearer ${token}`}};
        const params = {
            limit: pagination.limit,
            offset: pagination.offset,
            ...appliedFilters,
        };

        const url = `/orders/handed-over-orders/?${buildQueryParams(params)}`;

        try {
            const response = await api.get(url, config);
            setData(response.data.items);
            setTotalItems(parseInt(response.data.count.count, 10));  // Convert count to integer
        } catch (error) {
            notification.error({message: 'Failed to fetch orders.'});
            console.error(error);
        }

        setIsLoading(false);
    }, [token, pagination.limit, pagination.offset]);


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const urlFilters = {};

        searchParams.forEach((value, key) => {
            urlFilters[key] = value;
        });

        let filtersToApply = urlFilters;

        if (initialRender.current) {
            if (Object.keys(urlFilters).length === 0) {
                // If no URL filters, use default filters
                filtersToApply = {
                    statusFilter: 'HANDED_OVER_TO_ACCESSION',
                    selectedDate: dayjs().format('MM-DD-YYYY'),
                };
                setFilters(filtersToApply);
            } else {
                setFilters((prev) => ({
                    ...prev,
                    ...urlFilters,
                }));
            }
            initialRender.current = false;
        } else {
            setFilters((prev) => ({
                ...prev,
                ...urlFilters,
            }));
        }

        fetchData(filtersToApply);
    }, [location.search, fetchData]);


    useEffect(() => {
        const fetchRoutes = async () => {
            try {
                const config = {headers: {Authorization: `Bearer ${token}`}};
                const response = await api.get('/routes/', config);
                setRoutes(response.data.routes);
            } catch (error) {
                console.error(error);
            }
        };

        fetchRoutes();
    }, [token]);

    const handleRowHover = (record) => {
        setHoveredRow(record);
    };

    const handleAcceptClick = (record) => {
        setSelectedRowData(record);
        setSelectKey(prevKey => prevKey + 1);
        showModal('acceptOrder');
        setStatus(record.status);
    };

    const handleDetailsClick = (record) => {
        setSelectedRowData(record);
        setSelectKey(prevKey => prevKey + 1);
        showModal('detailsOrder');
        setStatus(record.status);
    };

    const handleWarningClick = (record) => {
        setSelectedRowData(record);
        setSelectKey(prevKey => prevKey + 1);
        showModal('warningOrder');
        setStatus(record.status);
    };

    const filterOptionForRoutes = (input, option) => {
        const routeData = routes.find(route => route.id === option.value);
        if (!routeData) return false;
        const routeCode = String(routeData.code).toLowerCase();
        const routeDriverId = String(routeData.driver_id).toLowerCase();
        return (
            routeCode.includes(input.toLowerCase()) || routeDriverId.includes(input.toLowerCase())
        );
    };

    return (
        <Spin
            spinning={isLoading}
            size="large"
            tip="Loading..."
            style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
        >
            <Row justify="center" align="middle">
                <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                    <Row justify="center">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Title level={2} className="mainLabel">
                                Receiver Dashboard
                            </Title>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <FilterPanel
                                onFilter={setFilters}
                                filters={filters}
                                token={token}
                            />
                            <>
                                <CustomPagination
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    totalItems={totalItems}
                                />
                                <Row justify="center" align="middle" style={{marginTop: 10}}>
                                    <Col style={{display: 'flex', alignItems: 'center'}}>
                                        <Typography.Paragraph type="secondary">
                                            Total Items: {totalItems}
                                        </Typography.Paragraph>
                                    </Col>
                                </Row>

                                <OrderTable
                                    data={data}
                                    handleWarningClick={handleWarningClick}
                                    handleAcceptClick={handleAcceptClick}
                                    handleDetailsClick={handleDetailsClick}
                                    handleRowHover={handleRowHover}
                                    hoveredRow={hoveredRow}
                                />

                                <CustomPagination
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    totalItems={totalItems}
                                />
                                <Row justify="center" align="middle" style={{marginTop: 10}}>
                                    <Col style={{display: 'flex', alignItems: 'center'}}>
                                        <Typography.Paragraph type="secondary">
                                            Total Items: {totalItems}
                                        </Typography.Paragraph>
                                    </Col>
                                </Row>
                            </>
                        </Col>
                    </Row>
                </Col>

                <ReceivingModal
                    setData={setData}
                    token={token}
                    isModalVisible={visibleModal === 'acceptOrder'}
                    selectedRowData={selectedRowData}
                    handleModalClose={closeModal}
                    selectedRoute={selectedRowData}
                    routes={routes}
                    selectKey={selectKey}
                    filterOptionForRoutes={filterOptionForRoutes}
                    isSubmitting={isSubmitting}
                    status={status}
                />

                <DetailsModal
                    token={token}
                    isModalVisible={visibleModal === 'detailsOrder'}
                    selectedRowData={selectedRowData}
                    handleModalClose={closeModal}
                    selectedRoute={selectedRowData}
                    routes={routes}
                    selectKey={selectKey}
                    filterOptionForRoutes={filterOptionForRoutes}
                    isSubmitting={isSubmitting}
                    status={status}
                />

                <WarningsModal
                    setData={setData}
                    token={token}
                    handleWarningClick={handleWarningClick}
                    isModalVisible={visibleModal === 'warningOrder'}
                    selectedRowData={selectedRowData}
                    handleModalClose={closeModal}
                    selectedRoute={selectedRowData}
                    routes={routes}
                    selectKey={selectKey}
                    filterOptionForRoutes={filterOptionForRoutes}
                    isSubmitting={isSubmitting}
                    status={status}
                />
            </Row>
        </Spin>
    );
};

export default ReceiverDashboard;
