import {useState,useContext} from 'react'
import { Descriptions, Button, App } from 'antd'
import EditableLabel from '../../../components/EditableLabel'
import { EditRole } from './userRole'
import Loading from '../../../components/Loading'
import axioss from '../../../restAPI';
import { AuthContext } from "../../../components/AuthContext";

const RowEditUser = (props) => {
    const { record } = props
    console.log(record)
    const {email, first_name, last_name, role, phone} = record
    const userID = record.id

    const [newEmail, setNewEmail] = useState(email)
    const [newFirstName, setNewFirstName] = useState(first_name)
    const [newLastName, setNewLastName] = useState(last_name)
    const [newRole, setNewRole] = useState(role)
    const [newPhone, setNewPhone] = useState(phone)

    const [loading, setLoading] = useState(false)
    const {message} = App.useApp(); 
    const { authConfig } = useContext(AuthContext);

    const [rKey, setRKey] = useState("rKey")


    const descriptionsItems = [
        {
            key: 'newEmail',
            label: 'Email',
            children: <EditableLabel value={newEmail} setValue={setNewEmail}/>,
        },
        {
            key: 'newPhone',
            label: 'Phone',
            children: <EditableLabel value={newPhone} setValue={setNewPhone}/>,
        },
        {
            key: 'newFirstName',
            label: 'First Name',
            children: <EditableLabel value={newFirstName} setValue={setNewFirstName}/>,
        },
        {
            key: 'newLastName',
            label: 'Last Name',
            children: <EditableLabel value={newLastName} setValue={setNewLastName}/>,
        },
        {
            key: 'newRole',
            label: 'Role',
            children: <EditRole role={newRole} setRole={setNewRole}/>,
        },
    ]

    const submit = async () => {
        const params = {
            userID,
            newEmail,
            newFirstName,
            newLastName,
            newRole,
            newPhone
        }
        try {
            setLoading(true);
            const response = await axioss.put(`/users/update-user/${userID}`, params, authConfig);
            console.log(response);
            message.success("Updated Successfully")
            setRKey(Date.now())
        }
        catch (err) {
            console.log(err);
            message.error("Error while updating the password")
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <Loading loading={loading}>
             <Descriptions bordered items={descriptionsItems} size='small' column={1} key={rKey}/>

             <br />
             <Button type='primary' size='small' onClick={submit}>Submit</Button>
        </Loading>
    )
}

export default RowEditUser

