import React, {useState, useEffect, useContext, useCallback} from 'react';
import {
    Card,
    Row,
    Col,
    DatePicker,
    Typography,
    Button,
    Modal,
    Spin,
    Radio,
    Divider,
    Form,
    Select,
    Tag,
    Input,
    Switch,
    Tooltip,
    Alert
} from 'antd';
import {ClockCircleOutlined, CalendarOutlined, ArrowDownOutlined, UnorderedListOutlined} from '@ant-design/icons';
import api from '../../../../../restAPI';
import {AuthContext} from '../../../../../components/AuthContext';
import {getSelectedDaysText} from '../../SelectedDays';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const {Text} = Typography;
const {Option} = Select;
const {TextArea} = Input;

const CreateSchedule = ({onSubmit, clientID, day = null, refreshScheduleBoard}) => {
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [startDate, setStartDate] = useState(dayjs());
    const [driverId, setDriverId] = useState(null);
    const [addedNotes, setAddedNotes] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [routes, setRoutes] = useState([]);
    const {token} = useContext(AuthContext);
    const [isActive, setIsActive] = useState(true);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [selectedRouteDrivers, setSelectedRouteDrivers] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);

    const [daysOfWeek, setDaysOfWeek] = useState([
        {day: 'Monday', checked: false, abbreviation: 'Mon'},
        {day: 'Tuesday', checked: false, abbreviation: 'Tue'},
        {day: 'Wednesday', checked: false, abbreviation: 'Wed'},
        {day: 'Thursday', checked: false, abbreviation: 'Thu'},
        {day: 'Friday', checked: false, abbreviation: 'Fri'},
        {day: 'Saturday', checked: false, abbreviation: 'Sat'},
        {day: 'Sunday', checked: false, abbreviation: 'Sun'},
    ]);


    useEffect(() => {
        if (day) {
            const updatedDaysOfWeek = daysOfWeek.map((dayObj) => ({
                ...dayObj,
                checked: dayObj.day === day,
            }));
            setDaysOfWeek(updatedDaysOfWeek);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [day]);

    const handleCardClick = (index) => {
        const updatedDaysOfWeek = [...daysOfWeek];
        updatedDaysOfWeek[index].checked = !updatedDaysOfWeek[index].checked;
        setDaysOfWeek(updatedDaysOfWeek);
    };

    const selectedDaysText = getSelectedDaysText(
        daysOfWeek.filter((day) => day.checked).map((day) => day.day),
        daysOfWeek
    );

    const handleStartTimeChange = (time, timeString) => {
        setStartTime(timeString);
    };

    const handleEndTimeChange = (time, timeString) => {
        setEndTime(timeString);
    };

    const handleStartDateChange = (date, dateString) => {
        setStartDate(date);
    };

    const handleBusinessDaysClick = () => {
        const updatedDaysOfWeek = daysOfWeek.map((day) => ({
            ...day,
            checked: day.day !== 'Saturday' && day.day !== 'Sunday',
        }));
        setDaysOfWeek(updatedDaysOfWeek);
    };

    const handleWholeWeekClick = () => {
        const updatedDaysOfWeek = daysOfWeek.map((day) => ({
            ...day,
            checked: true,
        }));
        setDaysOfWeek(updatedDaysOfWeek);
    };

    const handleManuallyClick = () => {
        const updatedDaysOfWeek = daysOfWeek.map((day) => ({
            ...day,
            checked: false,
        }));
        setDaysOfWeek(updatedDaysOfWeek);
    };

    const switchInfo = (
        <div style={{textAlign: 'center'}}>
            <Text style={{color: 'white'}}>Please note that the changes will take effect on the following day</Text>
        </div>
    );

    const handleFormSubmit = async () => {
        const selectedInfo = {
            days: daysOfWeek.filter((day) => day.checked).map((day) => day.day),
            open: startTime,
            close: endTime,
            startDate: startDate,
            driverId: selectedDriver || null,
            routeId: selectedRoute,
            clientId: parseInt(clientID),
            notes: addedNotes,
            contactPerson: "Test",
            contactPhone: 74648464895,
            boxInHouse: "yes",
            boxLocation: "NYC",
            isActive: isActive,
            inEffectAt: startDate ? startDate.toISOString() : null
        };
        setIsLoading(true);

        if (selectedInfo.days.length > 0 && startTime && endTime && selectedRoute && selectedInfo.driverId && startDate) {
            try {
                await api.post('/client-schedule/', selectedInfo, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                onSubmit(selectedInfo);
                refreshScheduleBoard();  // Refresh the schedule board after saving
                Modal.success({
                    content: 'Schedule has been successfully added!',
                    onOk: () => setModalVisible(false), // Close the modal on success
                });
            } catch (error) {
                console.error(error);
                Modal.error({
                    title: 'Error',
                    content: 'Something went wrong!',
                });
            }
        } else {
            Modal.error({
                title: 'Error',
                content: 'Please ensure you have filled out all required fields.',
            });
        }
        setIsLoading(false);
    };

    const handleModalOk = () => {
        setModalVisible(false);
        setSelectedRoute(null);
        setSelectedDriver(null);
        setStartTime(null);
        setEndTime(null);
        setAddedNotes('');
        setSelectedRouteDrivers([]);
        setIsActive(false);
    };

    const handleRouteChange = (value) => {
        setSelectedRoute(value);
        setSelectedDriver(null);
        const selectedRouteObj = routes.find(route => route.id === value);
        if (selectedRouteObj && selectedRouteObj.drivers) {
            setSelectedRouteDrivers(selectedRouteObj.drivers);
        } else {
            setSelectedRouteDrivers([]);
        }
    };

    const handleDriverChange = (value) => {
        setSelectedDriver(value);
    };

    const filterOptionForRoutes = (input, option) => {
        const routeData = routes.find(route => route.id === option.value);
        if (!routeData) return false;
        const routeCode = String(routeData.code).toLowerCase();
        const routeDriverId = String(routeData.driver_id).toLowerCase();
        return (
            routeCode.includes(input.toLowerCase()) || routeDriverId.includes(input.toLowerCase())
        );
    };

    const fetchRoutes = useCallback(async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const response = await api.get('/routes/?limit=1000', config);

            if (Array.isArray(response.data.routes)) {
                setRoutes(response.data.routes);
                if (response.data.routes[0]) {
                    setDriverId(response.data.routes[0].driver_id);
                }
            } else {
                console.error("Expected routes data to be an array but received:", response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    useEffect(() => {
        fetchRoutes();
    }, [fetchRoutes]);

    const disabledPastDates = (current) => {
        return current && current < new Date().setHours(0, 0, 0, 0);
    };

    return (
        <Spin spinning={isLoading} tip="Loading..." size="large">

            <Typography.Paragraph type={'secondary'} align={'center'}>
                Our system will automatically check this schedule each
                day at 00:00 AM and perform the orders for each driver assigned to
                this schedule.
            </Typography.Paragraph>

            <Divider plain style={{color: 'rgba(0, 0, 0, 0.4)', margin: 0}}>
                <CalendarOutlined/>
                &nbsp;
                Choose days
            </Divider>

            <Row justify="center" align="middle" gutter={[16, 16]}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                    <Row justify="center" align="middle" gutter={[16, 16]} className='radioWeek'>
                        <Radio.Group defaultValue="a" buttonStyle="solid" size="small">
                            <Radio.Button value="a" onClick={handleManuallyClick}>Manually</Radio.Button>
                            <Radio.Button value="b" onClick={handleBusinessDaysClick}>Business Days</Radio.Button>
                            <Radio.Button value="c" onClick={handleWholeWeekClick}>Whole Week</Radio.Button>
                        </Radio.Group>
                    </Row>


                    <Row justify="center" gutter={16} style={{marginTop: 20}}>
                        {daysOfWeek.map((day, index) => (
                            <Col key={index}>
                                <Card
                                    hoverable
                                    onClick={() => handleCardClick(index)}
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: day.checked ? '#204E57' : 'white',
                                        color: day.checked ? 'white' : 'black',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '5px',
                                    }}
                                >
                                    {day.abbreviation}
                                </Card>
                            </Col>
                        ))}
                    </Row>


                    <Divider plain style={{color: 'rgba(0, 0, 0, 0.4)'}}><ClockCircleOutlined/>Choose time</Divider>
                    <Row justify="center" style={{marginTop: '20px'}}>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="scheduleTime">
                            <Text>From: </Text>
                            <DatePicker
                                onChange={handleStartTimeChange}
                                picker="time"
                                format="hh:mm A"
                                style={{marginRight: '20px'}}
                            />
                            <Text>To: </Text>
                            <DatePicker
                                onChange={handleEndTimeChange}
                                picker="time"
                                format="hh:mm A"
                                style={{marginLeft: '8px'}}
                            />
                        </Col>
                    </Row>

                    <Divider plain style={{color: 'rgba(0, 0, 0, 0.4)'}}>
                        <ArrowDownOutlined/> Add route and driver
                    </Divider>

                    <Col lg={24}>
                        <Row justify="center" align="middle" gutter={[24, 0]}>
                            <Col lg={24}>
                                <Form.Item
                                    label={'Route'}
                                    name="assign"
                                    rules={[{required: true, message: 'Please add route!'}]}
                                >
                                    <Select
                                        showSearch
                                        onChange={handleRouteChange}
                                        value={selectedRoute}
                                        className="option"
                                        filterOption={filterOptionForRoutes}
                                    >
                                        {(routes || []).map((route) => (
                                            <Option key={route.id} value={route.id}>
                                                <span>
                                                  <Tag color="magenta"><b>{route.code}</b></Tag>
                                                  <Tag color="purple">{route.name}</Tag>
                                                  <Tag color="cyan">{route.region}</Tag>
                                                  <Tag color="blue">Primary Driver ID: {route.driver_id}</Tag>
                                                </span>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col lg={24}>
                                <Form.Item
                                    label={'Driver'}
                                    name="driver"
                                    rules={[{required: true, message: 'Please select a driver!'}]}
                                >
                                    <Select
                                        key={selectedRoute}
                                        showSearch
                                        placeholder="Select a driver"
                                        disabled={!selectedRoute}
                                        value={selectedDriver}
                                        onChange={handleDriverChange}
                                    >
                                        {selectedRouteDrivers.map((driver) => (
                                            <Option key={driver.driver_id} value={driver.driver_id}>
                                        <span>
                                            <Tag color="blue">{driver.driver_first_name} {driver.driver_last_name}</Tag>
                                               <Tag color="magenta">Driver ID: {driver.driver_id}</Tag>
                                        </span>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                    <Row justify="center" align="middle">

                    </Row>

                    <Divider plain style={{color: 'rgba(0, 0, 0, 0.4)'}}><UnorderedListOutlined/> Add notes</Divider>

                    <Row justify="center" align="middle">
                        <Form.Item>
                            <TextArea
                                value={addedNotes}
                                onChange={(e) => setAddedNotes(e.target.value)}
                                placeholder="Here you can add notes"
                                style={{width: "700px",}}
                                autoSize={{
                                    minRows: 2,
                                    maxRows: 5,
                                }}
                            />
                        </Form.Item>
                    </Row>

                    {startTime && endTime && daysOfWeek.some((day) => day.checked) && selectedRoute && (
                        <Row justify="center">
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Card>

                                    <Col lg={24}>
                                        <Alert
                                            style={{marginBottom: '20px'}}
                                            message={
                                                <>
                                                    You have
                                                    chosen: <b>{selectedDaysText}</b> from <b>{startTime}</b> to{' '}
                                                    <b>{endTime}</b> and {selectedRoute && (
                                                    <b>{routes.find((route) => route.id === selectedRoute)?.code}</b>
                                                )} route. The driver's ID is <b>{driverId}</b>.
                                                    <br/>
                                                    This schedule will start working
                                                    on <b>{startDate ? startDate.format('MM/DD/YYYY') : 'the selected date'}</b>.
                                                </>
                                            }
                                        />
                                    </Col>

                                    <Col xl={24} lg={24} md={24} sm={24} xs={24} className="scheduleCard">

                                        <Row justify="end" align="middle">
                                            <Col>
                                                <Text style={{marginRight: '8px'}}>Active:</Text>
                                            </Col>

                                            <Col>
                                                <Tooltip
                                                    title={switchInfo}
                                                    color='red'
                                                    placement="topRight"
                                                    open={isTooltipVisible}
                                                >
                                                    <Switch
                                                        disabled={true}
                                                        checkedChildren="ON"
                                                        unCheckedChildren="OFF"
                                                        checked={isActive}
                                                        onChange={(checked) => setIsActive(checked)}
                                                        style={{marginRight: '20px'}}
                                                        onMouseEnter={() => setIsTooltipVisible(true)}
                                                        onMouseLeave={() => setIsTooltipVisible(false)}
                                                    />
                                                </Tooltip>
                                            </Col>

                                            <Col>
                                                <Text style={{marginRight: '8px'}}>Schedule start date</Text>
                                            </Col>

                                            <Col>
                                                <DatePicker
                                                    defaultValue={startDate}
                                                    disabledDate={disabledPastDates}
                                                    onChange={handleStartDateChange}
                                                    format="YYYY-MM-DD"
                                                    style={{marginRight: '20px'}}
                                                />
                                            </Col>

                                            <Col>
                                                <Spin spinning={isLoading} size="small">
                                                    <Button
                                                        type="primary"
                                                        onClick={handleFormSubmit}
                                                        disabled={isLoading}
                                                    >
                                                        Create
                                                    </Button>
                                                </Spin>
                                            </Col>
                                        </Row>

                                        <Modal
                                            title="Schedule Status"
                                            open={modalVisible}
                                            onOk={handleModalOk}
                                            onCancel={handleModalOk}
                                            destroyOnClose={true}
                                        />
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </Spin>
    );
};

export default CreateSchedule;
