import {useState, useEffect} from 'react';

const useLocationFetcher = () => {
    const [userLocation, setUserLocation] = useState(null);
    const [isFetchingLocation, setIsFetchingLocation] = useState(true);
    const [fetchTime, setFetchTime] = useState(null);
    const [locationError, setLocationError] = useState(false);

    useEffect(() => {
        const getUserLocation = () => {
            setIsFetchingLocation(true);
            const startTime = Date.now();

            const success = (position) => {
                setUserLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
                setIsFetchingLocation(false);
                setFetchTime(Date.now() - startTime);
            };

            const error = (err) => {
                console.error('Error fetching user location:', err);
                setLocationError(true);
                setIsFetchingLocation(false);
            };

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success, error);
            } else {
                console.error('Geolocation is not supported by this browser.');
                setLocationError(true);
                setIsFetchingLocation(false);
            }
        };

        getUserLocation();
    }, []);

    return {userLocation, isFetchingLocation, fetchTime, locationError};
};

export default useLocationFetcher;
