import React, {useState, useContext, useEffect} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {Menu, Row, Col, Button, Drawer} from "antd";
import jwt_decode from "jwt-decode";
import {AuthContext} from "./components/AuthContext";
import {
    SettingOutlined,
    LogoutOutlined,
    WarningFilled,
    DropboxOutlined,
    MenuOutlined,
} from "@ant-design/icons";

const ROLE_SUPER_USER = "Super User";
const ROLE_DISPATCHER = "Dispatcher";
const ROLE_DRIVER = "Driver";
const ROLE_RECEIVER = "Receiver";
const ROLE_DRIVER_CHARTER = "Driver Charter";
const ROLE_UNKNOWN = "Unknown Role";

const Navigation = () => {
    const location = useLocation();
    const [current, setCurrent] = useState(location.pathname);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const {token, handleLogout} = useContext(AuthContext);
    const userRole = token ? jwt_decode(token).userRole : null;
    const isAdmin = userRole === 1 || userRole === 2;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleClick = (e) => {
        setCurrent(e.key);
        if (drawerVisible) {
            setDrawerVisible(false);
        }
    };

    const correctRoleString = (userRole) => {
        if (userRole === 1) return ROLE_SUPER_USER;
        if (userRole === 2) return ROLE_DISPATCHER;
        if (userRole === 3) return ROLE_DRIVER;
        if (userRole === 4) return ROLE_RECEIVER;
        if (userRole === 5) return ROLE_DRIVER_CHARTER;
        return ROLE_UNKNOWN;
    };

    let items = [
        isAdmin && {
            label: (
                <div className="logo" style={{marginRight: "0px"}}>
                    LOGISTIC [{correctRoleString(userRole)}]
                </div>
            ),
            key: "/dashboard",
            link: "/dashboard",
        },
        userRole === 3 && {
            label: "Driver Schedule",
            key: "/driver-schedule",
            link: "/driver-schedule",
        },
        userRole === 4 && {
            label: "Receiver Dashboard",
            key: "/receiver/dashboard",
            link: "/receiver/dashboard",
        },
        {
            label: "'Pay Attention' Dashboard",
            key: "/pay-attention-page",
            icon: <WarningFilled style={{marginRight: 4}}/>,
            link: "/pay-attention-page",
        },
        {
            label: "All Stops",
            key: "/all-stops",
            link: "/all-stops",
        },
        {
            label: "Predict Schedule",
            key: "/predict-schedule",
            link: "/predict-schedule",
        },
        {
            label: "My Account",
            key: "/driver-account",
            link: "/driver-account",
            condition: userRole === 3,
        },
        {
            label: "Client's Schedule",
            key: "/client-schedule",
            link: "/client-schedule",
        },
        {
            label: "Routes Maintenance",
            key: "/routes-management",
            link: "/routes-management",
        },
        {
            label: "Receive",
            key: "/receiver",
            icon: <DropboxOutlined style={{marginRight: 4}}/>,
            link: "/receiver",
            condition: userRole === 4 || userRole === 1 || userRole === 2,
        },
        {
            label: (
                <div className="logo" style={{marginRight: "0px"}}>
                    LOGISTIC [{correctRoleString(userRole)}]
                </div>
            ),
            key: "/airbill",
            link: "/airbill",
            condition: userRole === 5,
        },
        {
            label: "Settings",
            key: "settings",
            icon: <SettingOutlined/>,
            children: [
                {
                    label: "Users",
                    key: "/management/users",
                    link: "/management/users",
                },
                {
                    label: "Routes Maintenance",
                    key: "/routes-maintenance",
                    link: "/routes-maintenance",
                },
                {
                    label: "Drivers Maintenance",
                    key: "/drivers-maintenance",
                    link: "/drivers-maintenance",
                },
            ],
        },
    ];

    if (!isAdmin) {
        items = items.filter(
            (item) =>
                item &&
                (item.key === "/home" ||
                    item.key === "logout" ||
                    item.key === "/driver-schedule" ||
                    item.key === "/receiver" ||
                    item.key === "/receiver/dashboard" ||
                    item.key === "/fedex" ||
                    item.key === "/airbill" ||
                    item.key === "/driver-account")
        );
    }

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const renderMenuItems = (items) => {
        return items.map((item) => {
            if (item.condition === false) return null;
            if (item.children) {
                return (
                    <Menu.SubMenu key={item.key} title={item.label} icon={item.icon}>
                        {item.children.map((child) => (
                            <Menu.Item key={child.key}>
                                <NavLink
                                    to={child.link}
                                    isActive={() => location.pathname === child.link}
                                    activeClassName="ant-menu-item-selected"
                                >
                                    {child.label}
                                </NavLink>
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                );
            } else {
                return (
                    <Menu.Item key={item.key}>
                        {item.icon}
                        <NavLink
                            to={item.link}
                            isActive={() => location.pathname === item.link}
                            activeClassName="ant-menu-item-selected"
                        >
                            {item.label}
                        </NavLink>
                    </Menu.Item>
                );
            }
        });
    };

    return (
        <Row justify="center" align="middle">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                {windowWidth >= 768 && (
                    <Row justify="center" align="middle">
                        <Col sm={24}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    overflow: "visible",
                                }}
                            >
                                <Menu
                                    mode="horizontal"
                                    selectedKeys={[current]}
                                    onClick={handleClick}
                                    theme="dark"
                                    style={{
                                        width: "100%",
                                        whiteSpace: "nowrap",
                                        background: "#2b575f",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div style={{display: "flex", flexGrow: 1}}>
                                        {renderMenuItems(items)}
                                    </div>
                                    <Menu.Item key="logout" style={{marginLeft: "auto"}}>
                                        <Button
                                            type="link"
                                            onClick={() => handleLogout()}
                                            icon={<LogoutOutlined/>}
                                            style={{color: "#fff"}}
                                        >
                                            Logout
                                        </Button>
                                    </Menu.Item>
                                </Menu>
                            </div>
                        </Col>
                    </Row>
                )}

                {windowWidth < 768 && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "#2b575f",
                                padding: "16px",
                                overflow: "visible",
                            }}
                        >
                            <div
                                style={{
                                    flexGrow: 1,
                                    textAlign: "center",
                                    color: "#fff",
                                    fontSize: "18px",
                                }}
                            >
                                LOGISTIC [{correctRoleString(userRole)}]
                            </div>

                            <Button
                                type="text"
                                onClick={toggleDrawer}
                                icon={<MenuOutlined/>}
                                style={{marginLeft: 16, backgroundColor: "#fff"}}
                            />
                        </div>

                        <Drawer
                            title="Menu"
                            placement="left"
                            onClose={toggleDrawer}
                            open={drawerVisible}
                            bodyStyle={{padding: 0, backgroundColor: "#001529"}}
                        >
                            <Menu
                                theme="dark"
                                onClick={handleClick}
                                selectedKeys={[current]}
                                mode="vertical"
                            >
                                {renderMenuItems(items)}
                                <Menu.Item
                                    key="logout-mobile"
                                    style={{position: "absolute", bottom: 0, width: "100%"}}
                                >
                                    <Button
                                        type="link"
                                        onClick={() => handleLogout()}
                                        icon={<LogoutOutlined/>}
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                            color: "#fff",
                                        }}
                                    >
                                        Logout
                                    </Button>
                                </Menu.Item>
                            </Menu>
                        </Drawer>
                    </>
                )}
            </Col>
        </Row>
    );
};

export default Navigation;
