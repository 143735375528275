import React, {useState} from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {Divider, Row, Col, Button, Modal} from 'antd';

dayjs.extend(customParseFormat);

const formatTime = (time) => {
    return dayjs(time, 'HH:mm').format('hh:mm A');
};


const ScheduleItem = ({day, item, editMode, generateColor, getDriverInitials, removeScheduleItemHandler}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleButtonClick = () => {
        document.body.style.overflow = 'hidden'; // Disable scrolling
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        document.body.style.overflow = 'auto'; // Enable scrolling
        setIsModalVisible(false);
    };

    const handleOnClick = (item, day, removeScheduleItemHandler) => {
        Modal.confirm({
            title: 'Are you sure you want to remove this schedule?',
            content: 'This action cannot be undone.',
            okText: 'Yes, remove it',
            cancelText: 'No, keep it',
            okButtonProps: {
                style: {
                    backgroundColor: '#3ba4ae', // Change this to your desired color
                    borderColor: '#1890ff', // Change this to your desired color
                    color: '#fff' // Change this to your desired color
                }
            },
            onOk: () => {
                const filteredDays = item.days.filter(d => d.day === day);
                if (filteredDays.length > 0) {
                    removeScheduleItemHandler(filteredDays[0].client_schedule_day_id);
                } else {
                    console.error(`No schedule found for day: ${day}`);
                }
            }
        });
    }

    const dayItem = item.days.find(d => d.day === day);

    return (<div key={item.id} className="schedule-item" style={{borderColor: generateColor(item.id), marginLeft: 40}}>

       <span className="small-text" style={{color: generateColor(item.id)}}>
        {`${formatTime(item.open)} - ${formatTime(item.close)}`}
        </span>

            <p>Box Location: {item.box_location}</p>
            {/*<p>Box Location: {item.id}</p>*/}

            {/*<pre>*/}
            {/*    {JSON.stringify(item, null, 2)}*/}
            {/*</pre>*/}

            {/*<pre>*/}
            {/*    {JSON.stringify(item.days.find(d => d.day === day).driver, null, 2)}*/}
            {/*</pre>*/}

            {/*<pre>*/}
            {/*    {JSON.stringify(item)}*/}
            {/*</pre>*/}


            {editMode && (
                <>
                    <Divider dashed={true}/>
                    <Row gutter={[0, 0]}>


                        <Col sm={14}>
                            <div className="driver-info small-text">
                                <div className="driver-initials">
                                    {dayItem && dayItem.driver
                                        ? getDriverInitials(`${dayItem?.driver?.first_name} ${dayItem?.driver?.last_name}`)
                                        : 'No Driver'}
                                </div>

                                {/*<Button size="small" type={'text'} onClick={() => {*/}
                                {/*    message.info('Need to show the list with drivers to select one.');*/}
                                {/*}}>*/}
                                {/*    Edit Driver*/}
                                {/*</Button>*/}

                                <Button size="small" type={'text'} onClick={handleButtonClick}>
                                    {`${dayItem?.driver?.first_name}`}
                                </Button>
                            </div>
                        </Col>

                        <Col sm={10}>
                            <div className="action-buttons">

                                <Button size="small" icon={<EditOutlined/>} type={'text'}>
                                    Edit
                                </Button>

                                <Button
                                    size="small"
                                    icon={<DeleteOutlined/>}
                                    type={'text'}
                                    onClick={() => handleOnClick(item, day, removeScheduleItemHandler)}
                                >
                                    Remove
                                </Button>
                            </div>
                        </Col>

                    </Row>

                    <Modal
                        title="Driver Information"
                        open={isModalVisible}
                        onCancel={handleModalClose}
                        style={{overflow: 'hidden'}}
                        maskClosable={false}
                        keyboard={false}
                        footer={null}
                    >
                        <p>First Name: {dayItem?.driver?.first_name}</p>
                        <p>Last Name: {dayItem?.driver?.last_name}</p>
                        <p>Last Name: {dayItem?.driver?.phone}</p>
                        <p>State: {dayItem?.driver?.state}</p>
                        <p>Order type: {dayItem?.driver?.pick_up_type}</p>

                        {/*<pre>*/}
                        {/*    {JSON.stringify(dayItem, null, 2)}*/}
                        {/*</pre>*/}
                    </Modal>
                </>
            )}
        </div>
    )
};

export default ScheduleItem;
