import api from "../../../restAPI";
import React, {useState, useEffect} from 'react';
import {Upload, Image, Skeleton, Modal, Typography} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

const {Paragraph} = Typography;

const showModal = (title, content) => {
    Modal.info({
        title: title,
        content: content,
        centered: true,
        onOk: () => {
        },
    });
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const UploadComponent = ({token, currentOrderId, displayOnly = false}) => {
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [noPhotos, setNoPhotos] = useState(false); // State to track if there are no photos

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const apiURL = `/api/v2/pickup-file-uploader/list-of-files/`;
                const response = await api.get(`${apiURL}`, {
                    headers: {Authorization: `Bearer ${token}`},
                    params: {order_id: currentOrderId},
                });

                const files = response.data.files.map((file) => ({
                    uid: file.newFileName,
                    name: file.originalFileName,
                    status: 'done',
                    url: file.url,
                    metadata: {
                        name: file.newFileName,
                        type: '',
                        size: 0,
                        lastModified: '',
                    },
                }));

                setFileList(files);
                setNoPhotos(files.length === 0); // Check if no photos are returned
                setInitialLoading(false);
            } catch (error) {
                showModal('Error', 'Failed to fetch files');
                setInitialLoading(false);
            }
        };
        fetchFiles();
    }, [currentOrderId, token]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    return (
        <>
            {initialLoading ? (
                <Skeleton active/>
            ) : noPhotos ? ( // Check if there are no photos to display the message
                <Paragraph>No photos available for this order.</Paragraph>
            ) : (
                <Upload
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={{showRemoveIcon: !displayOnly}}
                    fileList={fileList}
                    onPreview={handlePreview}
                    onRemove={displayOnly ? undefined : () => {
                    }} // Disable removal in display-only mode
                >
                    {displayOnly ? null : (
                        <div>
                            <PlusOutlined/>
                            <div style={{marginTop: 8}}>Upload photo</div>
                        </div>
                    )}
                </Upload>
            )}
            {previewImage && (
                <Image
                    wrapperStyle={{display: 'none'}}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </>
    );
};

export default UploadComponent;
