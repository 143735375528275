import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Table, Input, notification, Spin, Button, DatePicker } from 'antd';
import { EditOutlined, SaveOutlined, SearchOutlined, FilePdfOutlined } from '@ant-design/icons';
import api from '../../../restAPI';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { AuthContext } from '../../../components/AuthContext';

const getColumnSearchProps = (dataIndex, handleSearch, handleReset) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
});

const FedexArchive = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editingKey, setEditingKey] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  const [editingData, setEditingData] = useState(null);
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(1);
  const [selectedDate, setSelectedDate] = useState();

  const isEditing = record => record.key.toString() === editingKey;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setFilteredData(data.filter(item => item[dataIndex].toString().includes(selectedKeys[0])));
  };

  const handleReset = clearFilters => {
    clearFilters();
    setFilteredData(data);
  };

  const handleInputChange = (e, field) => {
    setEditingData({
      ...editingData,
      [field]: e.target.value,
    });
  };

  const columns = [
    {
      title: `№ (${totalRows})`,
      align: "center",
      width: "90px",
      dataIndex: "key",
      key: "key",
      render: (text, record, index) => index + 1
    },
    {
      title: 'Account',
      dataIndex: 'client_id',
      key: 'client_id',
      align: 'center',
      width: "300px",
      render: (text, record) => isEditing(record) ?
        (<Input value={editingData.client_id} onChange={e => handleInputChange(e, 'client_id')} />)
        : text,
      ...getColumnSearchProps('client_id', handleSearch, handleReset),
    },
    {
      title: 'Tracking Number',
      dataIndex: 'tracking_number',
      key: 'tracking_number',
      align: 'center',
      width: "300px",
      render: (text, record) => isEditing(record) ?
        (<Input value={editingData.tracking_number} onChange={e => handleInputChange(e, 'tracking_number')} />)
        : text,
      ...getColumnSearchProps('tracking_number', handleSearch, handleReset),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      width: "250px",
      render: (text, record) => isEditing(record) ?
        (<Input value={editingData.quantity} onChange={e => handleInputChange(e, 'quantity')} />)
        : text
    },
    {
      title: 'Comments',
      dataIndex: 'notes',
      key: 'notes',
      align: 'center',
      render: (text, record) => isEditing(record) ?
        (<Input value={editingData.notes} onChange={e => handleInputChange(e, 'notes')} />)
        : text
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      width: '100px',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <SaveOutlined onClick={() => save(record.key)} style={{ fontSize: "18px", color: 'blue' }} />
          </span>
        ) : (
          editingKey === null ? <EditOutlined onClick={() => edit(record.key)} style={{ fontSize: "18px", color: 'red' }} /> : null
        );
      },
    },
  ];

  const edit = (key) => {
    if (editingKey === null) {
      const currentRow = data.find(row => row.key.toString() === key.toString());
      setEditingKey(key.toString());
      setEditingRow(currentRow);
      setEditingData(currentRow);
    }
  };

  const save = useCallback(async (key) => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await api.put('/fedex-orders/update-fedex-orders/', editingData, config);
  
      if (response.status === 200) {
        setData((oldData) => {
          const index = oldData.findIndex((item) => key === item.key);
          const newData = [...oldData];
          newData.splice(index, 1, { ...editingData, key });
          return newData;
        });
        setFilteredData((oldFilteredData) => {
          const index = oldFilteredData.findIndex((item) => key === item.key);
          const newFilteredData = [...oldFilteredData];
          newFilteredData.splice(index, 1, { ...editingData, key });
          return newFilteredData;
        });
        const rowIndex = key + 1;
        notification.success({ message: `Row ${rowIndex} successfully updated!` });
      } else {
        const rowIndex = key + 1;
        notification.error({ message: `Failed to update row ${rowIndex}!` });
      }
    } catch (error) {
      notification.error({ message: 'Failed to update data!' });
    } finally {
      setIsLoading(false);
      setEditingKey(null);
      setEditingRow(null);
    }
  }, [editingData, token]);

  const fetchDataByDate = useCallback(async (date) => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await api.get(`/fedex-orders/get-fedex-orders/${date.format('YYYY-MM-DD')}`, config);
      if (response.status === 200) {
        const itemsWithKeys = response.data.items.map((item, index) => ({
          ...item,
          key: index
        }));
        setData(itemsWithKeys);
        setFilteredData(itemsWithKeys);
        setTotalRows(itemsWithKeys.length);
      } else {
        notification.error({ message: 'Failed to fetch orders.' });
      }
    } catch (error) {
      notification.error({ message: 'Failed to fetch orders.' });
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await api.get('/fedex-orders/get-all-fedex-orders/', config);
      if (response.status === 200) {
        const itemsWithKeys = response.data.items.map((item, index) => ({
          ...item,
          key: index
        }));
        setData(itemsWithKeys);
        setFilteredData(itemsWithKeys);
        setTotalRows(itemsWithKeys.length);
      } else {
        notification.error({ message: 'Failed to fetch orders.' });
      }
    } catch (error) {
      notification.error({ message: 'Failed to fetch orders.' });
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Escape' && editingRow) {
      const newData = data.map((row) => (row.key === editingRow.key ? editingRow : row));
      setData(newData);
      setEditingKey(null);
      setEditingRow(null);
    }
    if (event.key === 'Enter' && editingRow) {
      save(editingRow.key);
    }
  }, [editingRow, data, save]);

  const openPDF = async () => {
    const input = document.getElementById("pdfTable");
    const canvas = await html2canvas(input, {scale:3});
    const imgData = canvas.toDataURL("image/png");

    // Dynamically set orientation
    const canvasProps = canvas;
    const orientation = canvasProps.width > canvasProps.height ? "l" : "p";
    const pdf = new jsPDF(orientation, "mm", "a4"); 

    const imgProps = pdf.getImageProperties(imgData); 
    const pdfWidth = pdf.internal.pageSize.getWidth();

    const marginLeft = 10;
    const marginRight = 10;
    const usableWidth = pdfWidth - marginLeft - marginRight;

    const newPdfHeight = (imgProps.height * usableWidth) / imgProps.width;

    pdf.addImage(imgData, 'PNG', marginLeft, 20, usableWidth, newPdfHeight); 
    window.open(URL.createObjectURL(pdf.output('blob')));
};
  
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  useEffect(() => {
    if (selectedDate) {
      fetchDataByDate(selectedDate);
    } else {
      fetchData();
    }
  }, [selectedDate, fetchDataByDate, fetchData]);

  return (
    <Spin spinning={isLoading}>
      <Row justify="center">
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Row justify="center">
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <DatePicker
                onChange={(date) => {
                  setSelectedDate(date);
                  if (date === null) {
                    fetchData();
                  }
                }}
                value={selectedDate}
                style={{ marginBottom: 16 }}
              />
              {filteredData.length > 0 && (
                  <Button type="primary" onClick={openPDF} danger style={{ marginLeft: "16px" }}>
                    <FilePdfOutlined style={{ margin: '0px 6px 0px -6px'}}/>PDF
                  </Button>
              )}
              <div id="pdfTable">
                <Table id="pdfTable"  dataSource={filteredData} columns={columns} size='small' rowKey="id" pagination={false} scroll={{ y: 550 }} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default FedexArchive;
