import React, {useEffect, useState} from 'react';
import {Table, Space, message, Typography, Image} from 'antd';
import {CloudDownloadOutlined} from '@ant-design/icons';
import api from '../../../restAPI';

const {Text} = Typography;

const PhotoDisplayingComponent = ({orderId, token}) => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await api.get('/api/v2/pickup-file-uploader/list-of-files/', {
                    params: {order_id: orderId},
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log('API response:', response.data); // Log the entire response for debugging
                setFiles(response.data.files || []); // Ensure to set an array
                setLoading(false);
            } catch (error) {
                message.error('Failed to load files');
                setLoading(false);
            }
        };

        fetchFiles();
    }, [orderId, token]);

    const columns = [
        {
            title: 'Original Filename',
            dataIndex: 'originalFileName',
            key: 'originalFileName',
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'New Filename',
            dataIndex: 'newFileName',
            key: 'newFileName',
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: 'Preview',
            key: 'preview',
            render: (_, record) => (
                <Space size="middle">
                    <Image
                        width={100}
                        src={record.url}
                        alt={record.originalFileName}
                    />
                </Space>
            ),
        },
        {
            title: 'Download',
            key: 'download',
            render: (_, record) => (
                <Space size="middle">
                    <a href={record.url} target="_blank" rel="noopener noreferrer">
                        <CloudDownloadOutlined/> Download
                    </a>
                </Space>
            ),
        },
    ];

    return (
        <>
            <Image.PreviewGroup
                preview={{
                    onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
            >
                <Table
                    columns={columns}
                    dataSource={files}
                    loading={loading}
                    rowKey="newFileName"
                    pagination={false}
                />
            </Image.PreviewGroup>
        </>
    );
};

export default PhotoDisplayingComponent;
