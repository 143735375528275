import React, {useState} from "react";
import {Row, Col, Spin, Tag} from "antd";
import DriverOnMyWayTab from "./DriverOnMyWayTab";
import DriverReportTab from "./DriverReportTab";
import DriverArchiveTab from "./DriverArchiveTab";
// import DriverAirbillTab from "./DriverAirbillTab";

const MySchedule = () => {
    const [isLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("1");

    // eslint-disable-next-line
    const [itemState, setItemState] = useState(null);

    const handleStatusChange = (status) => {
        setItemState(status);
    };

    const tabList = [
        {key: "1", label: "On my way"},
        {key: "2", label: "Report"},
        {key: "3", label: "Archive"},
        // { key: "4", label: "Airbill", condition: itemState !== "NY" }
    ];

    return (
        <div>
            <Spin spinning={isLoading} tip="Loading..." size="large">
                <Row justify="center">
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                <div
                                    style={{
                                        margin: "20px auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        textAlign: "center",
                                    }}
                                >
                                    {tabList.map(
                                        (tab) =>
                                            (tab.condition === undefined || tab.condition) && (
                                                <Tag
                                                    key={tab.key}
                                                    color={activeTab === tab.key ? "#108ee9" : "#f50"}
                                                    onClick={() => setActiveTab(tab.key)}
                                                    style={{
                                                        fontSize: "14px",
                                                        padding: "22px 0",
                                                        cursor: "pointer",
                                                        flex: "0 1 calc(33.33% - 8px)",
                                                        margin: "4px",
                                                        textAlign: "center",
                                                        borderRadius: "0px",
                                                        transition: "padding 0.3s ease",
                                                    }}
                                                >
                                                    {tab.label}
                                                </Tag>
                                            )
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24} style={{padding: '0 12px'}}>
                        <Row>
                            <Col span={24}>
                                {activeTab === "1" && <DriverOnMyWayTab onStatusChange={handleStatusChange}/>}
                                {activeTab === "2" && <DriverReportTab/>}
                                {activeTab === "3" && <DriverArchiveTab/>}
                                {/*{activeTab === "4" && <DriverAirbillTab />}*/}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default MySchedule;
