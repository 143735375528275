import React, {useEffect, useState} from "react";
import {GoogleMap, LoadScriptNext, Marker} from '@react-google-maps/api';
import Modal from 'react-modal';
import {Button} from 'antd';

export function OpenMap({mapCenter}) {
    const [markerPosition, setMarkerPosition] = useState(null);
    const mapStyles = {
        height: "calc(100% - 50px)", // Subtract space for the button
        width: "100%"
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setMarkerPosition(mapCenter);
        }, 10);
        return () => clearTimeout(timeoutId);
    }, [mapCenter]);

    return (
        <LoadScriptNext googleMapsApiKey='AIzaSyCeF9pve4e1BMOP9f6gA3p1MpEbiHlGY3I'>
            <GoogleMap mapContainerStyle={mapStyles} zoom={15} center={mapCenter}>
                {markerPosition && <Marker position={markerPosition} options={{ tooltip: false }} />} {/* Disable tooltip */}
            </GoogleMap>
        </LoadScriptNext>
    );
}

export function OpenMapModal({isOpen, closeModal, mapCenter}) {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    zIndex: 1000,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dim background
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90vw', // Adjust the modal width
                    height: '60vh', // Adjust the modal height
                    padding: 0, // Remove default padding
                    overflow: 'hidden' // Hide any overflow
                }
            }}
        >
            <div style={{width: '100%', height: '100%'}}>
                <OpenMap mapCenter={mapCenter} />
                <Button onClick={closeModal} style={{position: 'absolute', bottom: '10px', left: '10px', zIndex: 1}}>
                    Close Map
                </Button>
            </div>
        </Modal>
    );
}