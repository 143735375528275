import React, {useEffect, useState, useCallback, useContext} from "react";
import {Row, Col, Badge, Card, Spin, Typography, Avatar} from 'antd';
import {UserOutlined, HomeOutlined} from '@ant-design/icons';
import api from "../../../restAPI";
import jwt_decode from "jwt-decode";
import {AuthContext} from "../../../components/AuthContext";

const {Title, Text} = Typography;

const DriverArchiveTab = () => {
    const {token} = useContext(AuthContext);
    const driverID = token ? jwt_decode(token).driverId : null;
    const [confirmedOrders, setConfirmedOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getConfirmedOrders = useCallback(async () => {
        try {
            setIsLoading(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const response = await api.get(`/orders/todays-driver-orders/${driverID}/confirmed/`, config);
            setConfirmedOrders(response.data.items);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }, [token, driverID]);

    useEffect(() => {
        getConfirmedOrders();
    }, [getConfirmedOrders]);

    return (
        <Spin spinning={isLoading} tip="Loading orders..." size='large'>
            <Row gutter={[16, 16]} style={{maxHeight: '70vh', overflowY: 'auto', padding: '16px'}}>
                {confirmedOrders.map((item) => (
                    <Col xs={24} key={item.id}>
                        <Card
                            bordered={false}
                            hoverable
                            style={{borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'}}
                            bodyStyle={{padding: '16px'}}
                            title={
                                <div style={{display: 'flex'}}>
                                    <Avatar icon={<UserOutlined/>}/>
                                    <Title level={5} style={{margin: '0 0 0 8px'}}>
                                        {item.clientDetails?.name || "Unnamed Client"}
                                    </Title>
                                </div>
                            }
                        >
                            <div style={{marginBottom: '12px'}}>
                                <Text strong>
                                    <HomeOutlined/> {item.clientDetails?.street}, {item.clientDetails?.zip}
                                </Text>
                            </div>

                            <div style={{marginTop: '16px'}}>
                                <Badge
                                    count={item.status}
                                    style={{borderRadius: '12px',}}
                                />
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Spin>
    );
};

export default DriverArchiveTab;
