import React, {useState, useEffect} from 'react';
import { Row, Col, Spin, Tag } from 'antd';
import FedexReceive from './FedexReceive';
import FedexArchive from './FedexArchive';
import fedexLogo from "../../../img/Fedex-logo.png"

const Fedex = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); 
    }, 2000);
  }, []);

  return (
    <Spin spinning={isLoading} tip="Loading..." size='large'>
      <Row justify="center">
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
            <img src={fedexLogo} alt="FedEx Logo" style={{ height: '70px', marginTop: '20px' }} />
            <div style={{ margin: '0px auto 20px auto' }}>
              <Tag 
                color={activeTab === '1' ? '#108ee9' : '#f50'} 
                onClick={() => setActiveTab('1')}
                style={{ fontSize: '14px', padding: '6px 12px', cursor: 'pointer' }}
              >
                Receive
              </Tag>
              <Tag 
                color={activeTab === '2' ? '#108ee9' : '#f50'} 
                onClick={() => setActiveTab('2')}
                style={{ fontSize: '14px', padding: '6px 12px', cursor: 'pointer' }}
              >
                Archive
              </Tag>
            </div>
    
            {activeTab === "1" && <FedexReceive />}
            {activeTab === "2" && <FedexArchive />}
        </Col>
      </Row>
    </Spin>
  );
}

export default Fedex;
