import React from 'react';
import styled from 'styled-components';
import {Card, Timeline, Typography, Button} from 'antd';
import {ClockCircleOutlined, PlusOutlined} from '@ant-design/icons';
import ScheduleItem from './ScheduleItem';
import dayjs from 'dayjs';

const StyledCard = styled(Card)`
    position: relative;

    &.today-card {
        /* Add any specific styles for today-card if needed */
    }
`;

const Title = styled(Typography.Title)`
    left: 0;
`;

const CenteredButton = styled(Button)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.4);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 50%;
`;

const DayCard = ({
                     day,
                     schedules,
                     editMode,
                     generateColor,
                     isCurrentTimeRange,
                     getDriverInitials,
                     removeScheduleItemHandler,
                     handleSettingClick
                 }) => {
    const isToday = dayjs().format('dddd') === day;

    return (
        <StyledCard key={day} className={`day-card ${isToday ? 'today-card' : ''}`}>
            <Title level={4} align="left">
                {day}
            </Title>

            {schedules.length === 0 ? (
                <CenteredButton
                    type="dashed"
                    shape="circle"
                    icon={<PlusOutlined/>}
                    onClick={() => handleSettingClick(day)}
                />
            ) : (
                <Timeline mode="left">
                    {schedules.map((item, index) => (
                        <Timeline.Item
                            key={index}
                            className="timeline-time"
                            dot={
                                <>
                                    <ClockCircleOutlined/>
                                    &nbsp;{dayjs(item.open, 'HH:mm').format('hh:mm A')}
                                </>
                            }
                            color={isCurrentTimeRange(item.open, item.close) ? '#ddd' : '#2b575f'}
                        >
                            <ScheduleItem
                                day={day}
                                item={item}
                                editMode={editMode}
                                generateColor={generateColor}
                                getDriverInitials={getDriverInitials}
                                removeScheduleItemHandler={removeScheduleItemHandler}
                            />
                        </Timeline.Item>
                    ))}
                </Timeline>
            )}
        </StyledCard>
    );
};

export default DayCard;
