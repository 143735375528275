import React, {useEffect} from 'react';
import './MyModalComponent.css';
import NewUploaderComponent from '../../../components/ZelenchukSerhii/NewUploaderComponent';
import {CheckOutlined} from '@ant-design/icons';
import {Modal, Row, Col, Button, Typography, Result, Input, Divider, Checkbox} from 'antd';

const {Title} = Typography;
const {TextArea} = Input;

const MyModalComponent = ({
                              successModalIsOpen,
                              closeSuccessModal,
                              isSubmitting,
                              uploadStatus,
                              handleSubmit,
                              resetAndClose,
                              setIcePackInsideBox,
                              setBoxIsEmpty,
                              icePackInsideBox,
                              boxIsEmpty,
                              customNotes,
                              setCustomNotes,
                              error,
                              token,
                              currentOrderData,
                              userLocation
                          }) => {

    useEffect(() => {
        if (successModalIsOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [successModalIsOpen]);

    const handleBoxIsEmptyChange = (e) => {
        const isEmpty = e.target.checked;
        setBoxIsEmpty(isEmpty);
        if (isEmpty) {
            setIcePackInsideBox(false);
        }
    };

    return (
        <Modal
            destroyOnClose
            open={successModalIsOpen}
            onCancel={closeSuccessModal}
            footer={null}
            className="custom-modal"
            closable={false}
            maskClosable={false}
        >
            {uploadStatus === null && (
                <>
                    <Title level={4} align={'center'}>PickUp Report</Title>

                    <Row gutter={[16, 16]}>

                        <Col sm={24} className="custom-checkbox">
                            <Checkbox
                                style={{fontSize: '16px', marginBottom: 0, userSelect: 'none'}}
                                checked={boxIsEmpty}
                                onChange={handleBoxIsEmptyChange}
                            >
                                Box is empty (no specimens)
                            </Checkbox>
                        </Col>

                        {!boxIsEmpty && (
                            <Col sm={24} className="custom-checkbox">
                                <Checkbox
                                    style={{fontSize: '16px', marginBottom: 0, userSelect: 'none'}}
                                    checked={icePackInsideBox}
                                    onChange={(e) => setIcePackInsideBox(e.target.checked)}
                                >
                                    The ice pack inside the box
                                </Checkbox>
                            </Col>
                        )}

                        <Divider orientation="center" style={{fontSize: 12, margin: 0}} dashed={true}>
                            Custom notes for Dispatcher or Receiver, and photos
                        </Divider>

                        <Col span={24}>
                            <TextArea
                                placeholder="Add your notes here"
                                onChange={e => setCustomNotes(e.target.value)}
                                value={customNotes}
                                className="textarea"
                            />

                            <NewUploaderComponent
                                token={token}
                                currentOrderId={currentOrderData?.id}
                                latitude={userLocation?.latitude}
                                longitude={userLocation?.longitude}
                            />
                        </Col>

                        <Col span={12}>
                            <Button
                                icon={<CheckOutlined/>}
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                                type="primary"
                                className="submit-button"
                            >
                                {currentOrderData?.type === 'SCHEDULED'
                                    ? "Picked Up"
                                    : boxIsEmpty
                                        ? "Notify about Issue"
                                        : "Picked Up"
                                }
                            </Button>
                        </Col>

                        <Col span={12}>
                            <Button
                                onClick={closeSuccessModal}
                                disabled={isSubmitting}
                                className="close-button"
                            >
                                Close
                            </Button>
                        </Col>

                        {error && (
                            <Col span={24}>
                                <Typography.Text type="danger" style={{textAlign: 'center'}}>
                                    {error}
                                </Typography.Text>
                            </Col>
                        )}
                    </Row>
                </>
            )}

            {uploadStatus === 'success' && (
                <Result
                    status="success"
                    title="Successfully uploaded the image!"
                    extra={
                        <Button key="okay" onClick={resetAndClose} className="okay-button">
                            Okay
                        </Button>
                    }
                />
            )}

            {uploadStatus === 'error' && (
                <Result
                    status="error"
                    title="Failed to upload the image!"
                    extra={
                        <Button key="okay" onClick={resetAndClose} className="okay-button">
                            Okay
                        </Button>
                    }
                />
            )}
        </Modal>
    );
};

export default MyModalComponent;
