import React, { useState, useContext, useCallback, useEffect } from "react";
import { Row, Col, Typography, Spin, notification, Button } from 'antd';
import api from "../../../restAPI";
import { AuthContext } from "../../../components/AuthContext";
import AirbillTable from "./AirbillTable";
import AirbillModal from "./AirbillModal";

const { Title } = Typography;

const Airbill = () => {
  const [data, setData] = useState([]);
  const { token} = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [notes, setNotes] = useState("");

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await api.get(`/orders/orders-dropped-down/`, config);
      setData(response.data.items);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [token]);


  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected) => {
      let newSelectedRowKeys;
      if (selected) {
        newSelectedRowKeys = [...selectedRowKeys, record.key];
      } else {
        newSelectedRowKeys = selectedRowKeys.filter(key => key !== record.key);
      }
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };
  
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (trackingNumber === "") {
      return;
    }

    setIsModalVisible(false);
    setIsLoading(true);
    const ordersData = selectedRows.map(row => ({
      id: row.id, 
      routeDetails: row.routeDetails,
      clientDetails: row.clientDetails,
      driverDetails: row.driverDetails,
      status: row.status,
      type: row.type,
    }));
  
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      await api.post(`/airbill/create-airbills/`, {orders: ordersData, tracking_number: trackingNumber, notes: notes}, config);
      notification.success({ message: 'Success', description: 'Data saved successfully!' });
      fetchData();
    } catch (error) {
      console.error(error);
      notification.error({ message: 'Error', description: 'An error occurred while saving the data.' });
    } finally {
      setIsLoading(false);
    }
  
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setTrackingNumber("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (event) => {
    setTrackingNumber(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <Spin spinning={isLoading} tip="Loading..." size='large'>
        <Row justify="center">
          <Col xl={23} lg={23} md={23} sm={23} xs={23}>
            <Row justify="center">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Title level={2} className="mainLabel">Create Airbill</Title>
              </Col>
            </Row>

            <Row justify="center" align="middle" style={{marginBottom:'20px'}}>
              <Button type="primary" style={{width:"100px"}} onClick={showModal} disabled={selectedRowKeys.length === 0}>Next</Button>
            </Row>

            <Row justify="center">
              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <AirbillTable dataSource={data} rowSelection={rowSelection} />
              </Col>
            </Row>
          </Col>
        </Row>

        <AirbillModal 
          isVisible={isModalVisible} 
          handleOk={handleOk} 
          handleCancel={handleCancel} 
          trackingNumber={trackingNumber} 
          handleInputChange={handleInputChange}
          notes={notes}
          handleNotesChange={(e) => setNotes(e.target.value)}
        />
      </Spin>
    </div>
  );
}

export default Airbill;


// "20Empire"
// "15139137799"