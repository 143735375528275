import { Select, Tag, Space, Button,  } from "antd";
import {useState} from 'react';

// const [role, setRole] = useState('Select role');

const UserRoleSelect = (props) => {
    const {role, setRole} = props
    return (
        <Select
            defaultValue={role}
            style={{
                width: '100%',
            }}
            onChange={(value) => { setRole(value); }}
            options={[
                {
                    value: 1,
                    label: 'Super User',
                },
                {
                    value: 2,
                    label: 'Dispatcher',
                },
                {
                    value: 3,
                    label: 'Driver',
                },
                {
                    value: 4,
                    label: 'Receiver',
                },
                {
                    value: 5,
                    label: 'Driver Charter',
                },
            ]}
        />
    )
}


const UserRoleTag= (props) =>(
    <>
        {props.role===1&&(
            <Tag color="red">Super User</Tag>
        )}
        {props.role===2&&(
            <Tag color="green">Dispatcher</Tag>
        )}
        {props.role===3&&(
            <Tag color="cyan">Driver</Tag>
        )}
        {props.role===4&&(
            <Tag>Receiver</Tag>
        )}
        {props.role===5&&(
            <Tag>Driver Charter</Tag>
        )}
    </>
)

const EditRole = (props) => {
    const { role, setRole } = props;
    const [editing, setEditing] = useState(false);
    const [originalRole, setOriginalRole] = useState(role);

    const handleEdit = () => {
        setEditing(true);
        setOriginalRole(role);
    };

    const handleCancel = () => {
        setEditing(false);
        setRole(originalRole);
    };

    return (
        <>
            {editing ? (
                <Space>
                    <UserRoleSelect role={role} setRole={setRole}/>
                    <Button type='link' onClick={handleCancel}>Cancel</Button>
                </Space>
            ) : (
                <Space>
                    <UserRoleTag role={role}/>
                    <Button type='link' onClick={handleEdit} size="small">Edit</Button>
                </Space>
            )}
        </>
    );
};

export { UserRoleSelect, UserRoleTag, EditRole}
