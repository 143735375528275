import React, {useContext} from 'react';
import {Row, Col, Typography} from 'antd';
import {AuthContext} from '../../../components/AuthContext';
import ScheduleList from "./ScheduleList";


const ClientSchedule = () => {
    const {token} = useContext(AuthContext);

    return (
        <Row justify="center" align="middle">
            <Col xl={22} lg={22} md={22} sm={22} xs={22}>
                <Row justify={'center'} align={'middle'}>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                        <Typography.Title level={2} className="mainLabel">
                            Client Schedule Management
                        </Typography.Title>
                        <Typography.Paragraph type="secondary">
                            Each 00:00 AM is a new day and the system will generate a new list of stops depending on the
                            client's schedule.
                        </Typography.Paragraph>

                        <ScheduleList token={token}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};


export default ClientSchedule;
