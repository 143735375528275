import { Input, Space, Button } from 'antd';
import { useState } from 'react';

const EditableLabel = (props) => {
    const { value, setValue } = props;
    const [editing, setEditing] = useState(false);
    const [originalValue, setOriginalValue] = useState(value);
    
    const handleEdit = () => {
        setEditing(true);
        setOriginalValue(value);
    };

    const handleCancel = () => {
        setEditing(false);
        setValue(originalValue);
    };

    return (
        <>
            {editing ? (
                <Space>
                    <Input value={value} onChange={(e) => setValue(e.target.value)} />
                    <Button type='link' onClick={handleCancel} size='small'>Cancel</Button>
                </Space>
            ) : (
                <Space>
                    {value}
                    <Button type='link' onClick={handleEdit} size='small'>Edit</Button>
                </Space>
            )}
        </>
    );
};

export default EditableLabel;
