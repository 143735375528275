import React, {useState, useContext, useEffect} from 'react';
import restAPI from '../../restAPI';
import {AuthContext} from '../AuthContext';
import {Modal, Typography, Button, Form, Space} from 'antd';
import {FieldTimeOutlined, CheckCircleOutlined} from '@ant-design/icons';
import jwt_decode from "jwt-decode";
import {useNavigate} from "react-router-dom";

const withShiftCheck = (WrappedComponent) => {
    return (props) => {
        const {userRole, user, token, handleLogout} = useContext(AuthContext);
        const [modalWithShiftFormOpen, setModalWithShiftFormOpen] = useState(false);
        // eslint-disable-next-line
        const [shiftExists, setShiftExists] = useState(false);
        const [shiftStarted, setShiftStarted] = useState(false);
        const [shiftData, setShiftData] = useState(null);
        const navigate = useNavigate();

        const isTokenValid = (token) => {
            if (!token) return false;

            try {
                const decodedToken = jwt_decode(token);
                const currentTime = Date.now() / 1000; // Current time in seconds
                return decodedToken.exp > currentTime;
            } catch (error) {
                return false;
            }
        };

        useEffect(() => {
            if (token) {
                if (!isTokenValid(token)) {
                    handleLogout();
                    navigate("/login");
                } else if (userRole !== 3) { // for everybody except drivers
                    checkShiftForToday(user?.id);
                }
            }
            // eslint-disable-next-line
        }, [user?.id, userRole, token]);

        const checkShiftForToday = async (dispatcherId) => {
            try {
                const response = await restAPI.get(`/shifts/check/?dispatcher_id=${dispatcherId}`);
                console.log('Shift check response:', response.data);
                if (response.data.exists) {
                    setShiftExists(true);
                } else {
                    setModalWithShiftFormOpen(true);
                }
            } catch (error) {
                console.error("Error checking shift for today:", error);
            }
        };

        const startShift = async () => {
            try {
                const response = await restAPI.post('/shifts/', {
                    dispatcher_id: user?.id,
                    shift_start: new Date().toISOString(),
                    status: 'active'
                });
                console.log('Shift started:', response.data);
                setShiftData(response.data);
                setShiftStarted(true);
                setTimeout(() => {
                    setModalWithShiftFormOpen(false);
                }, 1000); // Automatically close the modal after 1 seconds
            } catch (error) {
                console.error("Error starting shift:", error);
            }
        };

        return (
            <>
                <Modal open={modalWithShiftFormOpen} footer={null} closable={false}>
                    {!shiftStarted ? (
                        <>
                            <Typography.Title level={3} align={'center'}>
                                Hello, {user?.first_name} {user?.last_name}!
                            </Typography.Title>

                            <Typography.Paragraph align={'center'}>
                                It seems you haven't started your shift today.
                            </Typography.Paragraph>

                            <Form style={{marginTop: 20}}>
                                <Form.Item>
                                    <Space style={{width: '100%', justifyContent: 'center'}}>
                                        <Button
                                            icon={<FieldTimeOutlined/>}
                                            size={'large'}
                                            type="dashed"
                                            onClick={startShift}
                                        >
                                            Start Shift
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </>
                    ) : (
                        <>
                            <Space direction="vertical" align="center" style={{width: '100%'}}>
                                <CheckCircleOutlined style={{fontSize: '48px', color: 'green'}}/>
                                <Typography.Title level={3}>Shift Started Successfully!</Typography.Title>
                                <Typography.Text>
                                    Shift ID: {shiftData.id}
                                    <br/>
                                    Start Time: {new Date(shiftData.shift_start).toLocaleString()}
                                    <br/>
                                    Status: {shiftData.status}
                                </Typography.Text>
                            </Space>
                        </>
                    )}
                </Modal>
                <WrappedComponent {...props} />
            </>
        );
    };
};

export default withShiftCheck;
