import React from "react";
import { Modal, Input } from 'antd';

const {TextArea} = Input;

const AirbillModal = ({
  isVisible, 
  handleOk, 
  handleCancel, 
  trackingNumber, 
  handleInputChange, 
  notes,
  handleNotesChange
}) => {
  return (
    <Modal 
      title="Tracking Number" 
      open={isVisible} 
      onOk={handleOk} 
      onCancel={handleCancel} 
      okButtonProps={{ disabled: !trackingNumber }}
    >
      <Input 
        allowClear 
        placeholder="Enter tracking number" 
        value={trackingNumber} 
        onChange={handleInputChange} 
      />
      <TextArea 
        allowClear 
        placeholder="Enter your notes" 
        value={notes} 
        onChange={handleNotesChange} 
        style={{marginTop:'20px'}} 
        autoSize={{minRows: 3, maxRows: 5}}
      />
    </Modal>
  );
}

export default AirbillModal;
