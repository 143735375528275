import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider, App } from "antd";
import { AuthProvider } from "./components/AuthProvider";
import { SocketProvider } from "./components/ZelenchukSerhii/SocketContext";
import AppWithDriverLocation from "./AppWithDriverLocation";
import withShiftCheck from './components/ZelenchukSerhii/withShiftCheck';

const AppWithShiftCheck = withShiftCheck(AppWithDriverLocation);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter basename={"/"}>
            <AuthProvider>
                <ConfigProvider theme={{ token: { colorPrimary: "#204E57" } }}>
                    <App>
                        <SocketProvider>
                            <AppWithShiftCheck />
                        </SocketProvider>
                    </App>
                </ConfigProvider>
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
