import React from "react";
import {Table} from "antd";
import TableRow from "./row";
import {UserRoleTag} from "./userRole";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const UsersTable = ({dataSource = []}) => {
    // Get the user's local timezone
    const userTimeZone = dayjs.tz.guess();

    // Prepare dataSource with formatted last_login and other derived fields
    const preparedDataSource = dataSource.map((user) => ({
        ...user,
        key: user.id,
        name: `${user.first_name} ${user.last_name}`,
        last_login: user.last_login ? dayjs.utc(user.last_login).tz(userTimeZone).format('MMMM DD, YYYY hh:mm A') : "N/A",
    }));

    // Define columns for the table
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (email) => email || "N/A",
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (phone) => phone || "N/A",
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (role) => <UserRoleTag role={role}/>,
        },
        {
            title: 'Last login',
            dataIndex: 'last_login',
            key: 'last_login',
            render: (lastLogin) => lastLogin || "N/A",
        },
    ];

    return (
        <div className="scrolledBox">
            <Table
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => (
                        <div style={{margin: 0}}>
                            <TableRow record={record}/>
                        </div>
                    ),
                    rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
                dataSource={preparedDataSource}
            />
        </div>
    );
};

export default UsersTable;
