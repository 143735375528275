import React, {useEffect, useState, useCallback, useContext, useRef} from "react";
import {AuthContext} from "../../../components/AuthContext";
import api from "../../../restAPI";
import jwt_decode from "jwt-decode";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Row, Col, Card, Spin, Checkbox, Input, Button, Typography, Divider} from 'antd';


dayjs.extend(utc);
dayjs.extend(timezone);

const DriverReportTab = () => {
    const {token} = useContext(AuthContext);
    const driverID = token ? jwt_decode(token).driverId : null;
    const [pickedUpOrders, setPickedUpOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [submittedOrders, setSubmittedOrders] = useState([]);
    const [packageRecipient, setPackageRecipient] = useState('');
    const [selectedOrders, setSelectedOrders] = useState([]);
    const inputRef = useRef(null);


    const getPickedUpOrders = useCallback(async () => {
        try {
            setIsLoading(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const response = await api.get(`/orders/todays-driver-orders/${driverID}/picked-up/`, config);
            setPickedUpOrders(response.data.items);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }, [token, driverID]);

    const handleCardClick = (orderId) => {
        if (selectedOrders.includes(orderId)) {
            setSelectedOrders(prevSelectedOrders => prevSelectedOrders.filter(id => id !== orderId));
        } else {
            setSelectedOrders(prevSelectedOrders => [...prevSelectedOrders, orderId]);
        }
    };

    const handleSubmitReceiver = async () => {
        console.log('Selected orders:', selectedOrders);
        console.log('Recipient:', packageRecipient);
        const opts = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        const data = {
            driver_id: driverID,
            notes: '',
            handed_over_to: packageRecipient,
            orders_list: selectedOrders
        }
        try {
            await api.post(`/orders/drop-specimens-to-accession-dept/`, data, opts);
            setSubmittedOrders((prevSubmittedOrders) => [...prevSubmittedOrders, ...selectedOrders]);
            setSelectedOrders([]);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmitReceiver();
        }
    };

    const handleInputChange = (event) => {
        setPackageRecipient(event.target.value);
    };

    const handleCheckAll = (isChecked) => {
        if (isChecked) {
            const allIds = pickedUpOrders.map(item => item.id);
            setSelectedOrders(allIds);
        } else {
            setSelectedOrders([]);
        }
    };

    const handleCheckboxChange = (id, isChecked) => {
        if (isChecked) {
            setSelectedOrders(prevSelectedOrders => [...prevSelectedOrders, id]);
        } else {
            setSelectedOrders(prevSelectedOrders => prevSelectedOrders.filter(itemId => itemId !== id));
        }
    };

    const getClientAddress = (clientDetails) => {
        const {street, zip, city, state} = clientDetails;
        const addressParts = [street, city, state, zip].filter(part => part);
        return addressParts.join(', ');
    };

    useEffect(() => {
        getPickedUpOrders();
    }, [getPickedUpOrders]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    if (isLoading) {
        return (
            <Spin spinning={isLoading} tip="Loading..." size='large'/>
        );
    }

    if (pickedUpOrders.length === 0) {
        return (
            <Row justify="center" style={{marginTop: '20px'}}>
                <Col>
                    <Typography.Title level={4}>
                        No orders available for reporting.
                    </Typography.Title>
                    <Typography.Paragraph type="secondary">
                        Firstly, you need to pick up the orders from the clients, if you haven't done it yet.
                    </Typography.Paragraph>
                </Col>
            </Row>
        );
    }

    return (
        <Spin spinning={isLoading} tip="Loading..." size='large'>
            <Row justify="center" style={{marginBottom: '20px'}}>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>

                    <Typography.Paragraph type={'secondary'} style={{fontSize: '14px'}}>
                        Please enter the name of the person who received the packages in the LAB.
                    </Typography.Paragraph>

                    <Input
                        ref={inputRef}
                        size={'large'}
                        placeholder="Who received the packages in the LAB?"
                        value={packageRecipient}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                    />
                    <Checkbox
                        disabled={packageRecipient.length <= 1}
                        style={{
                            marginRight: '10px',
                            fontSize: '16px',
                            padding: '10px',
                            width: '100%'
                        }}
                        onChange={(e) => handleCheckAll(e.target.checked)}
                    >
                        Check all
                    </Checkbox>
                    <Button
                        style={{width: '100%', marginTop: '10px'}}
                        type="primary"
                        onClick={handleSubmitReceiver}
                        disabled={selectedOrders.length === 0 || packageRecipient.trim() === ''}
                    >
                        Save drop-off
                    </Button>
                </Col>

                <Divider/>
            </Row>

            <Row gutter={[16, 16]}>
                {pickedUpOrders.map((item) => (
                    <Col xxl={8} xl={12} lg={12} md={24} sm={24} xs={24} key={item.id}>
                        <Card
                            title={(
                                <div style={{display: 'flex', alignItems: 'left'}}>
                                    <Checkbox
                                        checked={selectedOrders.includes(item.id)}
                                        onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                                        style={{marginRight: '10px'}}
                                    />
                                    {getClientAddress(item.clientDetails)}
                                </div>
                            )}
                            bordered={false}
                            onClick={() => handleCardClick(item.id)}
                            style={(selectedOrders.includes(item.id) || submittedOrders.includes(item.id)) ? {
                                backgroundColor: '#f0f0f0',
                                cursor: 'pointer'
                            } : {cursor: 'pointer'}}
                        >
                            <div style={{textAlign: 'left'}}>
                                <Typography.Text type="secondary" style={{fontSize: '12px'}}>
                                    Client ID: {item.clientDetails?.id}
                                </Typography.Text> <br/>
                                <Typography.Text type="secondary" style={{fontSize: '12px'}}>
                                    Client name: {item.clientDetails?.name}
                                </Typography.Text><br/>
                                <Typography.Text type="secondary" style={{fontSize: '12px'}}>
                                    Picked up at:&nbsp;
                                    {item.picked_up_date ? dayjs(item.picked_up_date)
                                        .tz('America/New_York')
                                        .format('MMMM DD, YYYY hh:mm A') : 'N/A'}
                                </Typography.Text>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Spin>
    )
};

export default DriverReportTab;
