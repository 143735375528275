import React, {useState, useEffect, useContext} from 'react';
import api from '../../../restAPI';
import {AuthContext} from '../../../components/AuthContext';
import {Modal, Button, List, Row, Col, notification, Typography, Tag, Input, Spin} from 'antd';

const DetailsModal = ({detailRoute, closeDetailsModal}) => {
    const [loading, setLoading] = useState(false);
    const [connectedDrivers, setConnectedDrivers] = useState([]);
    const [availableDrivers, setAvailableDrivers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const {token} = useContext(AuthContext);

    useEffect(() => {
        if (detailRoute) {
            fetchDriversForRoute(detailRoute.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailRoute]);

    const fetchDriversForRoute = async (routeId) => {
        setLoading(true);  // Start loading
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            const response = await api.get(`/routes/drivers/${routeId}/`, config);

            if (response.data.status === 'Success') {
                const {allDrivers, connectedDrivers} = response.data.data;

                // Set the state with the fetched drivers
                setConnectedDrivers(connectedDrivers);
                setAvailableDrivers(
                    allDrivers.filter(driver => !connectedDrivers.some(connectedDriver => connectedDriver.id === driver.id))
                );
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message || 'An error occurred while fetching drivers.'
                });
            }
        } catch (error) {
            console.error('Fetch drivers error:', error);
            notification.error({
                message: 'Error',
                description: error.response?.data?.message || 'An error occurred while fetching drivers.'
            });
        } finally {
            setLoading(false);  // Stop loading
        }
    };


    const moveToConnected = (driver) => {
        setConnectedDrivers(prev => [...prev, driver]);
        setAvailableDrivers(prev => prev.filter(d => d.id !== driver.id));
        setSearchTerm(''); // Reset the search term
    };

    const moveToAvailable = (driver) => {
        setConnectedDrivers(prev => prev.filter(d => d.id !== driver.id));
        setAvailableDrivers(prev => [...prev, driver]);
        setSearchTerm(''); // Reset the search term
    };

    const handleSaveChanges = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            const response = await api.put(`/routes/update-drivers/${detailRoute.id}`, {
                connectedDriverIds: connectedDrivers.map(driver => driver.id)
            }, config);

            if (response.data.status === 'Success') {
                notification.success({
                    message: 'Success',
                    description: 'Drivers updated successfully for the route!'
                });

                // Fetch updated drivers and refresh UI
                fetchDriversForRoute(detailRoute.id);

                closeDetailsModal();
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message || 'An error occurred while updating drivers.'
                });
            }
        } catch (error) {
            console.error('Update drivers error:', error); // Add console logging for debugging
            notification.error({
                message: 'Error',
                description: error.response?.data?.message || 'An error occurred while updating drivers.'
            });
        }
    };

    const filterDrivers = (drivers) => {
        return drivers.filter(driver =>
            driver.id.toString().includes(searchTerm) ||
            driver.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            driver.last_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    return (
        <Modal
            title={`${detailRoute?.name} ${detailRoute?.code} ${detailRoute?.region}`}
            open={!!detailRoute}
            onCancel={closeDetailsModal}
            footer={[
                <Button key="save" onClick={handleSaveChanges}>Save Changes</Button>
            ]}
            width={800}
        >
            <Spin spinning={loading} size="large">
                <Row gutter={16}>
                    <Col span={24} style={{marginBottom: '16px'}}>
                        <Input
                            placeholder="Search by ID, first name, or last name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Col>
                    <Col span={12}>
                        <List
                            style={{height: '500px', overflow: 'auto'}}
                            header={<Typography.Title level={5}>All Drivers</Typography.Title>}
                            bordered
                            dataSource={filterDrivers(availableDrivers)}
                            renderItem={driver => (
                                <List.Item style={{display: 'flex', alignItems: 'center'}}>
                                    <Tag>ID: {driver.id}</Tag> - {driver.first_name} {driver.last_name}
                                    <Button style={{marginLeft: 'auto'}}
                                            onClick={() => moveToConnected(driver)}>→</Button>
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col span={12}>
                        <List
                            header={<Typography.Title level={5}>Connected Drivers</Typography.Title>}
                            bordered
                            dataSource={filterDrivers(connectedDrivers)}
                            renderItem={driver => (
                                <List.Item style={{display: 'flex', alignItems: 'center'}}>
                                    <Button style={{marginRight: 'auto'}}
                                            onClick={() => moveToAvailable(driver)}>←</Button>
                                    {driver.id} - {driver.first_name} {driver.last_name}
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );

};

export default DetailsModal;
