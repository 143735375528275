import { Descriptions } from 'antd';
import {React} from 'react'

const TableRow = (props) => {
    const { record } = props
    console.log(record)

    const items = [
        {
          key: '1',
          label: 'Code',
          children: record.code,
        },
        {
          key: '2',
          label: 'Pick Up',
          children: record.pick_up_type,
        },
        {
          key: '3',
          label: 'Role',
          children: record.role,
        },
    ]
    console.log(record)
    return (
        <Descriptions bordered items={items} column={1} style={{width:'400px'}} size='small'/>
    )
}

export default TableRow