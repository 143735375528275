function buildQueryParams(params) {
    const queryParams = [];
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== undefined) {
        queryParams.push(`${key}=${encodeURIComponent(params[key])}`);
      }
    }
    return queryParams.join('&');
  }

export default buildQueryParams

/*
usage example:

const params = {
    limit,
    offset,
    searchByWarehouseID,
    searchByItemName,
    limit,
    offset
};
const url = `/in-stock?${buildQueryParams(params)}`;
const response = await axioss.get(url, authConfig);

*/