export const getSelectedDaysText = (days, daysOfWeek) => {
  
    const selectedDays = [...days];
    const sortedDays = selectedDays.sort(
      (a, b) =>
        daysOfWeek.findIndex((day) => day.day === a) -
        daysOfWeek.findIndex((day) => day.day === b)
    );
  
    const mergedDays = [];
    let startDay = sortedDays[0];
    let endDay = sortedDays[0];
  
    for (let i = 1; i < sortedDays.length; i++) {
      const currentDay = sortedDays[i];
      const previousDay = sortedDays[i - 1];
  
      const currentDayIndex = daysOfWeek.findIndex((day) => day.day === currentDay);
      const previousDayIndex = daysOfWeek.findIndex((day) => day.day === previousDay);
  
      if (currentDayIndex - previousDayIndex === 1) {
        endDay = currentDay;
      } else {
        if (startDay === endDay) {
          mergedDays.push(startDay);
        } else {
          mergedDays.push(`${startDay}-${endDay}`);
        }
        startDay = currentDay;
        endDay = currentDay;
      }
    }
  
    if (startDay === endDay) {
      mergedDays.push(startDay);
    } else {
      mergedDays.push(`${startDay}-${endDay}`);
    }
  
    return mergedDays.join(', ');
  };
  