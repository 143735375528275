import {Collapse} from 'antd'
const MyCollapse = (props) => {
    const { child, title, collapsed } = props
    
    return (
        <Collapse bordered={false} defaultActiveKey={collapsed?0:1}
            items={[
                {
                    key: 1,
                    label: title,
                    children: <> {child}</>,
                },
            ]}
        />
    )
}

export default MyCollapse