import React, {useState, useContext, useEffect, useCallback} from 'react';
import api from '../../../restAPI';
import {NavLink} from "react-router-dom";
import {AuthContext} from '../../../components/AuthContext';
import DriverRoutesDetails from './DriverRoutesDetails';
import {
    Row,
    Col,
    Button,
    Spin,
    Typography,
    Modal,
    notification,
    Space,
    Tag,
    Input,
    Divider,
    List,
    Card,
    Form, Alert
} from 'antd';

const {Title, Paragraph} = Typography;
const {Search} = Input;

const DriverCard = ({driver, onDetails, onEdit, onDelete}) => (
    <>
        <Card style={{marginBottom: 16}}>
            <List.Item style={{
                padding: '16px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <List.Item.Meta
                    style={{textAlign: 'left'}}
                    title={<Title level={5}>
                        <NavLink to={`/driver-details/${driver.id}`}>
                            {driver.first_name.toUpperCase()} {driver.last_name.toUpperCase()}
                        </NavLink>
                    </Title>}
                    description={
                        <>
                            <Paragraph type="secondary">
                                <Tag bordered={false}>Driver ID: {driver.id}</Tag>
                            </Paragraph>

                            {driver.unique_routes_list && driver.unique_routes_list.split(',').length > 0 && (
                                <Paragraph type="secondary">
                                    <Tag>Unique Routes:</Tag>
                                    {driver.unique_routes_list.split(',').map((route, index) => (
                                        <Tag bordered={false} key={index}>{route}</Tag>
                                    ))}
                                </Paragraph>
                            )}
                        </>
                    }
                />
                <Space direction="horizontal">
                    <Button size="small" onClick={() => onDetails(driver)}
                            style={{color: 'green', border: '1px solid green'}}>
                        Manage Routes
                    </Button>
                    <Button size="small" onClick={() => onEdit(driver)}
                            style={{color: 'blue', border: '1px solid blue'}}>
                        Edit
                    </Button>
                    <Button size="small" onClick={() => onDelete(driver.id)}
                            style={{color: 'red', border: '1px solid red'}}>
                        Remove
                    </Button>
                </Space>
            </List.Item>
        </Card>
        <Divider/>
    </>
);

const EditDriverModal = ({open, onCancel, onOk, driver, onChange}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [phoneError, setPhoneError] = useState('');

    useEffect(() => {
        if (driver?.phone) {
            validatePhone(driver.phone);
        }
    }, [driver?.phone]);

    const validatePhone = (phone) => {
        if (phone[0] !== '1' || phone.length !== 11) {
            setPhoneError('Invalid phone number. Must start with "1" and be 11 digits long.');
        } else {
            setPhoneError('');
        }
    };

    const handlePhoneChange = (e) => {
        const phone = e.target.value;
        validatePhone(phone);
        onChange(e, 'phone');
    };

    const handleOk = async () => {
        if (phoneError) {
            notification.error({message: 'Please correct the errors in the form before submitting.'});
            return;
        }
        setIsLoading(true);
        try {
            await onOk();
            onCancel();  // Close the modal after successful submission
        } catch (error) {
            notification.error({message: 'Error updating driver.'});
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            title="Edit Driver"
            open={open}
            onCancel={onCancel}
            onOk={handleOk}
            okButtonProps={{disabled: !!phoneError}} // Disable OK button if there's an error
        >
            <Spin spinning={isLoading} tip="Updating...">
                <Form layout="vertical">
                    <Form.Item label="First Name">
                        <Input
                            value={driver?.first_name}
                            onChange={(e) => onChange(e, 'first_name')}
                            placeholder="First Name"
                            style={{marginBottom: 8}}
                        />
                    </Form.Item>
                    <Form.Item label="Last Name">
                        <Input
                            value={driver?.last_name}
                            onChange={(e) => onChange(e, 'last_name')}
                            placeholder="Last Name"
                            style={{marginBottom: 8}}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Phone"
                        validateStatus={phoneError ? 'error' : ''}
                        help={phoneError}
                    >
                        <Input
                            value={driver?.phone}
                            onChange={handlePhoneChange}
                            placeholder="Phone"
                            style={{marginBottom: 8}}
                        />

                        <Typography.Text type={'secondary'}>
                            Phone number must start with "1" and be 11 digits long.
                            This is used for login purposes and notifications.
                        </Typography.Text>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};


const DriversMaintenance = () => {
    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {token} = useContext(AuthContext);
    // eslint-disable-next-line no-unused-vars
    const [detailRoute, setDetailRoute] = useState(null);
    const [editRoute, setEditRoute] = useState(null);
    const [drivers, setDrivers] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [currentConnectedDrivers, setCurrentConnectedDrivers] = useState([]);
    const [searchParams, setSearchParams] = useState({id: '', first_name: '', last_name: '', route_code: ''});
    const [selectedDriver, setSelectedDriver] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [errorDetails, setErrorDetails] = useState(null);

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: searchParams
            };
            const response = await api.get('/driver/drivers-with-routes/', config);
            setDrivers(response.data.drivers);
            setFilteredData(response.data.drivers);
        } catch (error) {
            console.error("API call failed", error);
        } finally {
            setIsLoading(false);
        }
    }, [token, searchParams]);

    const handleDetails = (driverData) => {
        if (!driverData || !driverData.unique_routes_list) {
            console.error("Route or route.unique_routes_list is null or undefined:", driverData);
            return;
        }

        const connectedDriversForRoute = drivers.filter(driver => {
            if (!driver || !driver.unique_routes_list) {
                return false;
            }
            return driver.unique_routes_list.includes(driverData.unique_routes_list);
        });

        setCurrentConnectedDrivers(connectedDriversForRoute);
        setDetailRoute(driverData);
        setSelectedDriver(driverData);
    };

    const closeDetailsModal = () => {
        setDetailRoute(null);
        setSelectedDriver(null);
    };

    const closeEditModal = () => {
        setEditRoute(null);
    };

    const handleEditChange = (e, field) => {
        const newValue = {...editRoute, [field]: e.target.value};
        setEditRoute(newValue);
    };

    const handleEditSubmit = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const response = await api.put(`/driver/rest/${editRoute.id}/`, editRoute, config);
            if (response.status === 200) {
                notification.success({message: 'Driver updated successfully!'});
                fetchData();
            } else {
                throw new Error('Failed to update driver');
            }
        } catch (error) {
            notification.error({message: 'Error updating driver!'});
        }
        closeEditModal();
    };

    const handleDeleteClick = (driverId) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this driver?',
            content: 'This will remove the driver and all associated routes. This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                try {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    };
                    const response = await api.delete(`/driver/rest/${driverId}/`, config);
                    if (response.data.success) {
                        notification.success({message: 'Driver deleted successfully!'});
                        fetchData();
                    } else {
                        throw new Error('Failed to delete driver');
                    }
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error === 'ForeignKeyViolation') {
                        // eslint-disable-next-line no-unused-vars
                        const {detail, dependencies} = error.response.data;
                        Modal.error({
                            title: 'Cannot Delete Driver',
                            content: (
                                <div>
                                    <Typography.Paragraph>
                                        Unable to delete the driver due to existing dependencies. The driver is
                                        currently associated with the following items:
                                    </Typography.Paragraph>
                                    <Divider/>
                                    {dependencies.map(dep => (
                                        <div key={dep.id} style={{marginBottom: '10px'}}>
                                            <Typography.Text strong>ID:</Typography.Text> {dep.id}
                                            <br/>
                                            <Typography.Text strong>Table:</Typography.Text> {dep.table_name}
                                            {dep.route_code && (
                                                <>
                                                    <br/>
                                                    <Typography.Text strong>Route
                                                        Code:</Typography.Text> {dep.route_code}
                                                </>
                                            )}
                                        </div>
                                    ))}
                                    <Divider/>
                                    <Alert
                                        message={`Please remove these dependencies before attempting to delete the driver. Or cancel connection to the driver from the route and turn off the driver.`}
                                        type="info"/>
                                </div>
                            ),
                            width: 600,
                        });
                    } else {
                        notification.error({message: 'Error deleting driver!'});
                    }
                }
            }
        });
    };


    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSearch = (value, field) => {
        setSearchParams({...searchParams, [field]: value});
    };

    return (
        <Spin spinning={isLoading} tip="Loading..." size="large">
            <Row justify="center" align="middle">
                <Col xl={23} lg={23} md={23} sm={24} xs={24}>
                    <Title level={2} className="mainLabel">Drivers Maintenance</Title>

                    <Space direction="horizontal" style={{marginBottom: '16px'}}>

                        <Search placeholder="Search by Last Name" onSearch={(value) => handleSearch(value, 'last_name')}
                                enterButton/>
                        <Search placeholder="Search by First Name"
                                onSearch={(value) => handleSearch(value, 'first_name')} enterButton/>
                        <Search placeholder="Search by Route Code"
                                onSearch={(value) => handleSearch(value, 'route_code')} enterButton/>
                        <Search placeholder="Search by driver ID" onSearch={(value) => handleSearch(value, 'id')}
                                enterButton/>


                    </Space>

                    <Typography.Paragraph type={'secondary'}>
                        Count of drivers: {drivers.length}
                    </Typography.Paragraph>
                    <div style={{boxSizing: 'border-box', padding: '1px'}}>
                        <Spin spinning={isLoading}>
                            {(filteredData.length > 0 ? filteredData : data).map(driver => (
                                <DriverCard
                                    key={driver.id}
                                    driver={driver}
                                    onDetails={handleDetails}
                                    onEdit={() => setEditRoute(driver)}
                                    onDelete={handleDeleteClick}
                                />
                            ))}
                        </Spin>
                    </div>
                </Col>
            </Row>

            {selectedDriver !== null && (
                <DriverRoutesDetails
                    selectedDriver={selectedDriver}
                    closeDetailsModal={closeDetailsModal}
                />
            )}

            {editRoute && (
                <EditDriverModal
                    open={true}
                    onCancel={closeEditModal}
                    onOk={handleEditSubmit}
                    driver={editRoute}
                    onChange={handleEditChange}
                />
            )}

        </Spin>
    );
};

export default DriversMaintenance;
